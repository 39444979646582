import React from "react";
import { Button } from "react-bootstrap";
import Previous from "../../images/icons/Previous.png";
import Next from "../../images/icons/Next.png";
import uploadIcon from "../../images/icons/Upload.png";
import { FindDateWithTime, getMonthByNameFunc2 } from "../../utils/constants";

const CreateListing6 = (props) => {
  let data = props.data || {};
  return (
    <div className="details_background">
      <div className="row m-0 align-items-center back_button">
        <p className="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div className="row m-0 details_background_2 list-bg-2">
        <div className="col-lg-12 align-items-center justify-content-center p-0 pl-5 pr-5 listing-page-2">
          <div className="row m-0 mt-5 listing-tab-resp">
            <div className="col-lg-12 col-md-12 col-sm-12 px-1 py-2 listing-tab">
              <a href="#">Vehicle Details</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Ownership</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Rebate</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Bidding</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#" className="active">
                Review
              </a>
            </div>
          </div>

          <div className="row m-0 mt-5 listing-tab-web">
            <div className="col-lg-12 p-2 listing-btn-section d-flex align-items-center justify-content-center">
              <button className="startRadius listing-btn-section-cmn">
                Vehicle Details
              </button>
              <button className="listing-btn-section-cmn">Ownership</button>
              <button className="listing-btn-section-cmn">Rebate</button>
              <button className="listing-btn-section-cmn">Bidding</button>
              <button className="endRadius listing-btn-section-cmn listing-act-btn">
                Review
              </button>
            </div>
          </div>

          <div className="row m-lg-5 m-md-5 m-0 mt-4">
            <div className="col-lg-12">
              <h3 className="listing-head-1 listing-left-align">Review</h3>

              <div className="row m-0 bg-white listing-review-page-radius">
                <div className="col-lg-12 pl-5 pr-5 pt-4 listing-page-7">
                  <div className="row m-0">
                    <div className="col-lg-12 p-4">
                      <h1 className="text-center listing-head-8">
                        Vehicle Details
                      </h1>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">Vehicle No.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.vehicleNumber}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">Vehicle Make</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.vehicleMake}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Vehicle Model
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.vehicleModel}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Primary Color
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.primaryColor}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Manufacturing Year
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.manufacturingYear}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">Engine No.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.engineNumber}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">Chassis No.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.chassisNumber}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Maximum Power Output
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.maximumPower}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row m-0">
                    <div className="col-lg-12 p-4">
                      <h1 className="text-center listing-head-8">Ownership</h1>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        First Registration Date
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{getMonthByNameFunc2(data.firstRegistrationDate)}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Original Registration Date
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{getMonthByNameFunc2(data.originalRegistrationDate)}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Intended Deregistration Date
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{getMonthByNameFunc2(data.intentedDeregistrationDate)}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Open Market Value
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.openMarketValue}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Actual ARF Paid
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.actualArfPaid}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Vehicle to be Exported?
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">
                        {data.isVehicleExported ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Transfer Count
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.transferCount}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row m-0">
                    <div className="col-lg-12 p-4">
                      <h1 className="text-center listing-head-8">Rebate</h1>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        PARF Eligibility Expiry Date
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center"> - </p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        PARF Rebate Amount
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.parfRebateAmount}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        COE Expiry Date
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{getMonthByNameFunc2(data.coeExpiryDate)}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">COE Category</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.coeCategory}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        COE Period (Years)
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{data.coePeriodInYears}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">PQP Paid</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.pqpPaidAmount}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        COE Rebate Amount
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.coeRebateAmount}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Total Rebate Amount
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.totalRebateAmount}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row m-0">
                    <div className="col-lg-12 p-4">
                      <h1 className="text-center listing-head-8">Bidding</h1>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Release Bid on
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{FindDateWithTime(data.releaseBidDate)}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">Close Bid on</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">{FindDateWithTime(data.closeBidDate)}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Starting Bid Price
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.startingBidPrice}</p>
                    </div>
                  </div>

                  <div className="row m-0 mt-2">
                    <div className="col-lg-6 col-md-6 col-6 page-7-col pr-5">
                      <p className="input-head mr-5 text-right">
                        Expected Final Price
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6">
                      <p className="text-center">$ {data.expectedFinalPrice}</p>
                    </div>
                  </div>

                  <div className="row m-0 p-lg-4 p-md-4 pb-5 mt-5 listing-btn align-items-center justify-content-center">
                    <div className="col-lg-10 d-flex align-items-center justify-content-between">
                      <Button
                        className="prev-btn"
                        onClick={() => props.setListing(5)}
                      >
                        <img src={Previous} alt="" />
                        <p className="">Previous</p>
                      </Button>
                      <Button className="submit-btn">
                        <p
                          className="m-0"
                          onClick={() => {
                            props.submitApplication();
                          }}
                        >
                          {props.isEdit ? "Update" : "Submit"} Application
                        </p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing6;
