import React, { useState, useEffect } from "react";
import PartnerPageRouting from "../../../components/common/partnerPageRouting";
import Header from "../../../components/header";
import "../../../css/partnerView.css";
import meterIcon from "../../../images/icons/Meter.png";
import calendarIcon from "../../../images/icons/calendar.png";
import carkiLogo from "../../../images/icons/CARKI_LOGO.png";
import { FormControl, InputGroup, Spinner, Modal } from "react-bootstrap";
import {
  DeleteListingApi,
  GetAllListingApiBuy,
  GetAllListingApiSell,
} from "../../../utils/apiUrls";
import { toast } from "react-toastify";
import {
  CompareCurrentTime,
  FindBidCloseTime,
  FindDate,
  FindTimeAgo,
} from "../../../utils/constants";

const ManageOpenListing = (props) => {
  const [listingArrayData, setListingArray] = useState([]);
  const [showData, setshowData] = useState(false);
  const [listCount, setListCount] = useState(4);
  const [time, setTime] = useState(0);
  const [search, setSearch] = useState("");
  const [filterValue, setfilterValue] = useState("-");

  const [showBidDelete, setShowBidDelete] = useState(false);
  const [listingId, setlistingId] = useState("");
  const [bidSuccessFullyRegistered, setbidSuccessFullyRegistered] =
    useState(false);

  const [deleteType, setDeleteType] = useState("");
  const [bidPrice, setBidPrice] = useState(0);

  useEffect(() => {
    GetAllListingApiFunc(listCount);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);
      FindBidCloseTime();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  const GetAllListingApiFunc = (listCount) => {
    GetAllListingApiSell(listCount, 0, "open")
      .payload.then((res) => {
        setListingArray(res.data.listingArray || []);
        setshowData(true);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const showMoreFunc = () => {
    setshowData(false);
    setListCount(listCount + 4);
    GetAllListingApiFunc(listCount + 4);
  };

  const deleteListingFunction = () => {
    let obj = {
      listingId: listingId.id,
    };
    DeleteListingApi(obj)
      .payload.then(() => {
        setbidSuccessFullyRegistered(true);
        GetAllListingApiFunc(listCount);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  return (
    <React.Fragment>
      <Header />

      <div className="background_white partner_dashborad_page_background">
        <div className="row m-0">
          <div className="col-3 d-lg-block d-md-block d-none pl-5">
            <PartnerPageRouting activePage="open-listing" />
          </div>

          <div className="col-lg-9 col-md-9 col-12 pr-lg-5 pr-md-5 pr-0 pl-lg-2 pl-md-2 pl-0 partner-dashboard open-listing-background mt-lg-0 mt-md-0 mt-4">
            <div className="row m-0 align-items-center mb-lg-5 mb-4">
              <div className="col-lg-4 col-0"></div>
              <div className="col-lg-4 col-12 d-flex align-items-center justify-content-center">
                <h2
                  className="mb-0 my_open_listing cursor_pointer"
                  onClick={() => {
                    props.history.push("/manage-open-listing");
                  }}
                >
                  My Open Listings
                </h2>
                <i
                  style={{ color: "#FF6833", fontSize: "36px" }}
                  className="d-lg-block d-none fas fa-angle-right pl-4"
                ></i>
              </div>
              <div className="col-4 d-lg-block d-none">
                <h4
                  className="mb-0 my_closed_listing cursor_pointer"
                  onClick={() => {
                    props.history.push("/view-closed-listing");
                  }}
                >
                  My Closed Listings
                </h4>
              </div>
            </div>

            <div className="row m-0 details_background_2">
              <div className="col-lg-1 col-0 p-0"></div>
              <div className="col-lg-10 col-12">
                <div className="row m-0 orange_background p-lg-4 p-2 px-lg-4 px-3 align-items-center">
                  <div className="col-lg-7 col-12 d-lg-flex d-block align-items-center pl-0">
                    <p className="search_text mb-lg-0 mb-2">Search</p>
                    <InputGroup className="mb-0 ml-lg-3 ml-0" size="lg">
                      <FormControl
                        // placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-search"
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                  <div className="col-lg-5 col-12 pr-0 pl-lg-3 pl-0 d-flex align-items-center my-lg-0 my-4">
                    <p
                      className="search_text mb-0 mr-2"
                      style={{ whiteSpace: "pre" }}
                    >
                      Sort by:
                    </p>
                    <div className="most_recent_div w-100 d-flex align-items-center justify-content-between">
                      <i
                        className="fas fa-angle-down"
                        style={{
                          fontSize: "26px",
                          position: "absolute",
                          right: "12px",
                        }}
                      ></i>
                      <select
                        name="cars"
                        id="cars"
                        onChange={(e) => setfilterValue(e.target.value)}
                      >
                        <option value="-">Most recent</option>
                        <option value="">Least recent</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="bid_list_background row m-0 py-3">
                  {showData &&
                    !listingArrayData
                      .filter((v) => v.status === "open")
                      .filter((u) =>
                        search
                          ? (u.vehicleModel + " " + u.vehicleMake)
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) >= 0
                          : true
                      ).length && (
                      <p className="text-center font-weight-bold w-100 no_record_text">
                        No record found
                      </p>
                    )}
                  <div className="col-lg-2 col-0 d-none d-lg-block"></div>

                  {showData ? (
                    <div className="col-lg-8 col-12">
                      {(listingArrayData || [])
                        .filter((v) => v.status === "open")
                        .filter((u) =>
                          search
                            ? (u.vehicleModel + " " + u.vehicleMake)
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) >= 0
                            : true
                        )
                        .sort(
                          (a, b) =>
                            `${filterValue}` +
                            a.createdAt.localeCompare(b.createdAt)
                        )
                        .map((v, i) => {
                          return (
                            <div key={i} className="mb-4">
                              <div className="row m-0">
                                <div className="col-6 p-0 d-flex justify-content-start align-items-center">
                                  <p className="mb-0 pd-title-3 blue-text">
                                    In Progress &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-3 time_slot">
                                    <span>{FindTimeAgo(v.createdAt)}</span>
                                  </p>
                                </div>

                                {CompareCurrentTime(v.releaseBidDate)
                                  ? <div className="col-6 p-0 d-flex justify-content-end align-items-center">
                                    <p className="mb-0 pd-title-4 bid_close_text">
                                      Bidding closes in: &nbsp;&nbsp;
                                    </p>
                                    <p className="mb-0 pd-title-4 red_text_time">
                                      <span>
                                        {FindBidCloseTime(v.closeBidDate)}
                                      </span>
                                    </p>
                                  </div>
                                  : null}


                              </div>

                              {listingId && (
                                <Modal
                                  show={showBidDelete}
                                  className="raise_bid_modal"
                                  onHide={() => setShowBidDelete(false)}
                                  aria-labelledby="contained-modal-title-vcenter raise_bid_modal"
                                  centered
                                  backdrop="static"
                                  keyboard={false}
                                >
                                  <Modal.Body>
                                    <div className="row m-0 p-4">
                                      <div className="col-3 pl-0 pr-5">
                                        <div className="user_profile"></div>
                                      </div>
                                      <div className="col-8 pl-4">
                                        <div className="car_name">
                                          {listingId.vehicleMake}{" "}
                                          {listingId.vehicleModel}
                                        </div>
                                        <div className="category_name">
                                          CAT{" "}
                                          {listingId.coeCategory.split(" ")[0]}{" "}
                                          &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                          {listingId.parfEligibility
                                            ? "PARF Car"
                                            : "COE Car"}
                                        </div>
                                        <div className="car_details d-flex align-items-center ">
                                          <i class="fas fa-calendar-day"></i>{" "}
                                          <p className="mb-0 pl-2">
                                            Registration Date:
                                          </p>{" "}
                                          <p className="pl-2 mb-0 font-weight-bold">
                                            29/02/2011
                                          </p>
                                        </div>

                                        <div className="car_details d-flex align-items-center ">
                                          <i class="fas fa-user-alt"></i>{" "}
                                          <p className="mb-0 pl-2">Seats:</p>{" "}
                                          <p className="pl-2 mb-0 font-weight-bold">
                                            2
                                          </p>
                                        </div>

                                        <div className="car_details d-flex align-items-center ">
                                          <i class="fas fa-tachometer-alt"></i>{" "}
                                          <p className="mb-0 pl-2">
                                            Mileage (km):
                                          </p>{" "}
                                          <p className="pl-2 mb-0 font-weight-bold">
                                            {listingId.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-1 p-0 d-flex justify-content-end cross_icon">
                                        <i
                                          class="fas fa-times cursor_pointer"
                                          onClick={() =>
                                            setShowBidDelete(false)
                                          }
                                        ></i>
                                      </div>
                                    </div>

                                    <hr className="mt-0" />

                                    {!bidSuccessFullyRegistered ? (
                                      <>
                                        <div className="success_bid_raise_div row m-0">
                                          <div className="col-1 p-0"></div>
                                          <div className="col-10 p-0 mt-3">
                                            <div className="sucess_bid_msg_box text-center p-5 mb-2">
                                              <p
                                                className="heading text-center mb-3"
                                                style={{
                                                  color: "#404244",
                                                  lineHeight: "32px",
                                                }}
                                              >
                                                Are you sure you want to{" "}
                                                <span
                                                  style={{ color: "#D01E1E" }}
                                                >
                                                  delete
                                                </span>{" "}
                                                this listing?
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-1 p-0"></div>
                                        </div>

                                        <div className="cancel_raise_btn_div row m-0">
                                          <div className="col-2 p-0"></div>
                                          <div className="col-8 p-0 d-flex justify-content-between align-items-center">
                                            <button
                                              className="cancel_btn_delete"
                                              onClick={() =>
                                                setShowBidDelete(false)
                                              }
                                            >
                                              Go Back
                                            </button>
                                            <button
                                              className="raise_bid_delete"
                                              onClick={() => {
                                                deleteListingFunction();
                                              }}
                                            >
                                              Confirm
                                            </button>
                                          </div>
                                          <div className="col-2 p-0"></div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="success_bid_raise_div row m-0">
                                          <div className="col-1 p-0"></div>
                                          <div className="col-10 p-0">
                                            <p className="heading text-center mb-3">
                                              {deleteType === "bidDelete"
                                                ? "Bid"
                                                : "Listing"}{" "}
                                              Successfully Deleted.
                                            </p>

                                            <div className="sucess_bid_msg_box text-center py-2 d-flex flex-column justify-content-center align-items-center">
                                              <p className="font-weight-bold">
                                                You have deleted your listing
                                                for:
                                              </p>
                                              <p
                                                className="mb-0 font-weight-bolder"
                                                style={{ fontSize: "20px" }}
                                              >
                                                {listingId.vehicleMake}{" "}
                                                {listingId.vehicleModel}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-1 p-0"></div>
                                        </div>
                                      </>
                                    )}
                                  </Modal.Body>
                                </Modal>
                              )}

                              <div
                                className="row m-0 mt-1 blue-zone cursor_pointer"
                                onClick={() =>
                                  props.history.push(
                                    `/manage-open-listing/${v.id}`
                                  )
                                }
                              >
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2 align-items-center">
                                    <div className="col-4 p-0 pd-tab-inner car_logo_open_list">
                                      <img
                                        src={
                                          v.displayPhoto
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-lg-2 pl-0">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleModel} {v.vehicleMake}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-lg-2 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            {v.coeCategory} &nbsp;&nbsp; |
                                            &nbsp;&nbsp; PARF Car
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-lg-2 mt-1">
                                        <div className="col-7 pl-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {FindDate(v.coeExpiryDate)}
                                          </p>
                                        </div>
                                        <div className="col-5 pr-2 pl-1 d-flex justify-content-start cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {v.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 px-lg-3 px-1 tab-1-right">
                                  <div
                                    style={{ height: "100%" }}
                                    className="d-flex flex-column justify-content-center"
                                  >
                                    {/* <div className="row m-0 pt-lg-2 pt-0">
                                      <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 blue-text-small">
                                        <p className="mb-0 text-right">
                                          Latest Bid:
                                        </p>
                                      </div>
                                      <div className="col-4 p-0 blue-text-small">
                                        <p className="mb-0 text-right">
                                          $ {v.expectedFinalPrice}
                                        </p>
                                      </div>
                                    </div> */}
                                    <div className="row m-0 mt-1">
                                      <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 black-text mb-2">
                                        <p className="mb-0 text-right">
                                          Starting Bid:
                                        </p>
                                      </div>
                                      <div className="col-4 p-0 black-text">
                                        <p className="mb-0 text-right">
                                          ${" "}
                                          {v.startingBid
                                            ? v.startingBid.bidPrice
                                            : v.startingBidPrice}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row m-0 mt-2 pt-1 justify-content-end">
                                      <div className="d-flex justify-content-end p-0 mr-2">
                                        <button
                                          className="delete"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setShowBidDelete(true);
                                            setlistingId(v);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                      <div className="d-flex justify-content-end p-0">
                                        <button
                                          className="edit-bid"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            props.history.push({
                                              pathname:
                                                "/partner-create-listing",
                                              state: v,
                                            });
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {(listingArrayData || [])
                        .filter((u) =>
                          search
                            ? (u.vehicleModel + " " + u.vehicleMake)
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) >= 0
                            : true
                        )
                        .filter((v) => v.status === "open").length > 3 && (
                          <div className="row m-0 mt-4 show_more_text">
                            <div
                              className="col-12 p-0 d-flex align-items-center justify-content-center cursor_pointer"
                              onClick={() => showMoreFunc()}
                            >
                              Show More{" "}
                              <i
                                className="fas fa-angle-down pl-2"
                                style={{ fontSize: "18px" }}
                              ></i>
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="col-lg-8 col-12 d-flex pt-4 justify-content-center">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  )}

                  <div className="col-lg-2 col-0 d-none d-lg-block"></div>
                </div>
              </div>
              <div className="col-lg-1 col-0 p-0"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageOpenListing;
