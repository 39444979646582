import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import car from "../../images/img/image.png";

import priceTag from "../../images/icons/price-tag.png";
import viewing from "../../images/icons/viewing.png";
import drive from "../../images/icons/drive_2.png";

import "../../css/buy.css";

const n = 9;

const Buy = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid buypage">
        <div className="row buyRow d-flex align-items-center justify-content-center">
          <div className="col-2 p-0"></div>
          <div className="col-2 buycol">
            <h1 className="mb-0">Used Car</h1>
          </div>
          <div className="col-2 buycol">
            <a href="">
              <h5 className="ml-5 mb-0">Explore Vehicles</h5>
            </a>
          </div>
          <div className="col-2 buycol">
            <a href="">
              <h5 className="mr-5 mb-0">Popular Vehicles</h5>
            </a>
          </div>
          <div className="col-sm searchbar">
            <div className="input-group md-form form-sm form-1 pl-0">
              <div className="input-group-prepend">
                <span className="input-group-text bdr" id="basic-text1">
                  <i className="fas fa-search" aria-hidden="true"></i>
                </span>
              </div>
              <input
                className="form-control bdr my-0 py-1"
                type="text"
                placeholder="Search Vehicles"
                aria-label="Search"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 pt-4" style={{ backgroundColor: "#fff" }}>
        <div className="col-1"></div>
        <div className="p-0 col-7">
          <div className="container">
            <div className="row">
              <div className="col sort-section text-center d-flex align-items-center justify-content-between">
                <p>Sort By</p>

                <div className="dropdown">
                  <button
                    className="btn btn-secondary btn btn-1 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Date added
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </div>

                <input type="button" className="btn btn2" value="Reg. Date" />
                <input type="button" className="btn btn3" value="Price" />
                <input type="button" className="btn btn4" value="Depreciation" />
                <input type="button" className="btn btn5" value="Mileage" />
              </div>
            </div>
            <div className="row m-0 mt-4 buy-page-cards d-flex justify-content-between">
              {[...Array(n)].map((v, i) => {
                return (
                  <div className="col-6 mb-5">
                    <div key={i} className="carousel-cards border-0">
                      <img
                        className=" card-img-top"
                        src={car}
                        alt="Cars image"
                      />
                      <div className="card-body">
                        <h5 className="card-title">Audi R8 3.0 Coupe</h5>
                        <div className="card-links d-flex w-100 justify-content-between flex-nowrap">
                          <p className="card-higlight text-uppercase text-white font-weight-bold bg-default-a card-deep-round px-2 py-1 card-blue mr-2">
                            2 Seater
                          </p>
                          <p className="card-higlight text-uppercase text-white font-weight-bold bg-default-b card-deep-round px-4 py-1 card-blue mr-2">
                            Cat B
                          </p>
                          <p className="card-higlight text-uppercase text-white font-weight-bold bg-default-c card-deep-round px-4 py-1 card-blue">
                            Parf B
                          </p>
                        </div>
                        <div className="card-amounts d-flex justify-content-between">
                          <div className="time">
                            <i className="fas fa-calendar mr-2 text-carki-dand"></i>{" "}
                            <span>29/02/2021</span>
                          </div>
                          <div className="price text-success price-big-font">
                            $298,200
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="time">
                            <i className="fas fa-user mr-2 text-carki-dand"></i>{" "}
                            <span>2</span>
                          </div>
                          <div className="price ">
                            <i className="fa fa-dashboard mr-2 text-carki-dand"></i>{" "}
                            <span>$29,125</span>{" "}
                          </div>
                          <div className="price text-dark">$20,200/yr</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="col-3 pl-3 pr-0">
          <div className="container banner2cont d-flex flex-column justify-content-between">
            <div className="row">
              <div className="col">
                <h5 className="catHead">Browse by Category</h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="active">All Categories</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">Almost New</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">COE Renewed</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">First Owner</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">Low Mileage</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">OPC</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">PARF</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">Recommended</h5>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a href="">
                  <h5 className="inactive">Scrap/COE Renewable</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 p-0"></div>
      </div>

      <div className="container-fluid banner3cont">
        <div className="row buy-row-1">
          <div className="col-xl">
            <h2>
              Find Your Dream Car,
              <br />
              Let Carki Handle The Rest
            </h2>
          </div>
        </div>
        <div className="row m-0 buy-row-2 mt-4">
          <div className="col-3"></div>
          <div className="col-2 d-flex flex-column align-items-center circle-div">
            <h5>Step 1</h5>
            <div className="icon-div">
              <img src={priceTag} alt="" />
            </div>
            <p>
              Make an offer for
              <br />
              the car you like
            </p>
          </div>
          <div className="col-2 d-flex flex-column align-items-center circle-div">
            <h5>Step 2</h5>
            <div className="icon-div">
            <img src={viewing} alt="" />
            </div>
            <p>
              Confirm viewing
              <br />
              and evaluation
            </p>
          </div>
          <div className="col-2 d-flex flex-column align-items-center circle-div">
            <h5>Step 3</h5>
            <div className="icon-div">
            <img src={drive} alt="" />
            </div>
            <p>
              Complete your
              <br />
              purchase and drive!
            </p>
          </div>
          <div className="col-3"></div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Buy;
