import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Previous from "../../images/icons/Previous.png";
import Next from "../../images/icons/Next.png";
import uploadIcon from "../../images/icons/Upload.png";

const CreateListing5 = (props) => {
  let findTodayDate = new Date();
  let date;
  if (findTodayDate.getHours() > 6) {
    findTodayDate.setDate(findTodayDate.getDate() + 1);
    date = `${findTodayDate.getFullYear()}-${(
      "0" +
      (findTodayDate.getMonth() + 1)
    ).slice(-2)}-${("0" + findTodayDate.getDate()).slice(-2)}`;
  } else {
    date = `${findTodayDate.getFullYear()}-${(
      "0" +
      (findTodayDate.getMonth() + 1)
    ).slice(-2)}-${("0" + findTodayDate.getDate()).slice(-2)}`;
  }

  return (
    <div className="details_background">
      <div className="row m-0 align-items-center back_button">
        <p className="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div className="row m-0 details_background_2 list-bg-2">
        <div className="col-lg-12 align-items-center justify-content-center p-0 pl-5 pr-5 listing-page-2">
          <div className="row m-0 mt-5 listing-tab-resp">
            <div className="col-lg-12 col-md-12 col-sm-12 px-1 py-2 listing-tab">
              <a href="#">Vehicle Details</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Ownership</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Rebate</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#" className="active">
                Bidding
              </a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Review</a>
            </div>
          </div>

          <div className="row m-0 mt-5 listing-tab-web">
            <div className="col-lg-12 p-2 listing-btn-section d-flex align-items-center justify-content-center">
              <button className="startRadius listing-btn-section-cmn">
                Vehicle Details
              </button>
              <button className="listing-btn-section-cmn">Ownership</button>
              <button className="listing-btn-section-cmn">Rebate</button>
              <button className="listing-btn-section-cmn listing-act-btn">
                Bidding
              </button>
              <button className="endRadius listing-btn-section-cmn">
                Review
              </button>
            </div>
          </div>

          <div className="row m-0 mt-4">
            <div className="col-lg-12">
              <h3 className="listing-head-1 listing-left-align">Bidding</h3>
              <hr />
            </div>
          </div>

          <div className="row m-0 mt-3">
            <div className="col-lg-6">
              <p className="input-head">Release Bid on:</p>

              <div className="row mt-4 flex-column listing-inputs">
                <div className="col-lg-12 mb-4 d-flex align-items-center listing-page-6">
                  <p className="input-head mr-5">Date:</p>
                  <input
                    type="date"
                    placeholder="DD/MM/YYYY"
                    min={date}
                    name="releaseBidDate"
                    value={
                      props.inputData.releaseBidDate &&
                      props.inputData.releaseBidDate.split("T")[0]
                    }
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div>
                {/* <div className="col-lg-12 d-flex align-items-center listing-page-6">
                  <p className="input-head mr-5">Time:</p>
                  <input
                    type="time"
                    name="releaseBidDateTime"
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <p className="input-head">Close Bid on:</p>
              <div className="row mt-4 flex-column listing-inputs">
                <div className="col-lg-12 mb-4 d-flex align-items-center listing-page-6">
                  <p className="input-head mr-5">Date:</p>
                  <input
                    type="date"
                    placeholder="DD/MM/YYYY"
                    name="closeBidDate"
                    min={
                      props.inputData.releaseBidDate &&
                      props.inputData.releaseBidDate.split("T")[0]
                    }
                    value={
                      props.inputData.closeBidDate &&
                      props.inputData.closeBidDate.split("T")[0]
                    }
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div>
                {/* <div className="col-lg-12 d-flex align-items-center listing-page-6">
                  <p className="input-head mr-5">Time:</p>
                  <input
                    type="time"
                    placeholder="DD/MM/YYYY"
                    name="closeBidDateTime"
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div> */}
              </div>
            </div>
          </div>

          {/* <div className="row m-0 mt-4 listing-inputs">
                        <div className="col-lg-6 d-flex align-items-center">
                            <p className="input-head mr-5">Date:</p>
                            <input type="date" placeholder="DD/MM/YYYY" />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <p className="input-head mr-5">Date:</p>
                            <input type="date" placeholder="DD/MM/YYYY" />
                        </div>
                    </div>

                    <div className="row m-0 mt-4 listing-inputs">
                        <div className="col-lg-6 d-flex align-items-center">
                            <p className="input-head mr-5">Time:</p>
                            <input type="time" placeholder="DD/MM/YYYY" />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <p className="input-head mr-5">Time:</p>
                            <input type="time" placeholder="DD/MM/YYYY" />
                        </div>
                    </div>  */}

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head mr-5">Starting Bid Price</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="startingBidPrice"
                value={props.inputData.startingBidPrice}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4">
              <p className="input-head mr-5">Expected Final Price</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="expectedFinalPrice"
                value={props.inputData.expectedFinalPrice}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>
          <div className="row m-0 mt-5 listing-inputs listing-inputs-5">
            <p className="input-head pl-3 upload-head-show">Display Picture</p>
            <div className="col-lg-4 col-12 listing-page-6-upload">
              <p className="input-head upload-head-hide">Display Picture</p>
              <div
                className="image"
                style={{
                  backgroundImage:
                    props.inputData &&
                    typeof props.inputData.displayPhoto !== "object"
                      ? `url(${props.inputData.displayPhoto})`
                      : ` url(${props.profileImage})`,
                  backgroundPosition: `center`,
                  backgroundSize: "cover",
                }}
              ></div>
              <label className="change_pro_btn d-flex align-items-center">
                <div className="upload-btn mt-2">
                  <img src={uploadIcon} alt="" />
                  <p>Upload Picture</p>
                </div>
                <input
                  type="file"
                  className="change_pro_btn"
                  id="myfile"
                  name="profilePhoto"
                  onChange={(e) => {
                    props.setProfileImgeSrc(URL.createObjectURL(e.target.files[0]));
                    props.setProfilePicture(e.target.files[0]);
                  }}
                />
              </label>
            </div>
          </div>

          <div className="row m-0 p-lg-4 p-md-4 pb-5 mt-5 listing-btn align-items-center justify-content-center">
            <div className="col-lg-6 d-flex align-items-center justify-content-between">
              <Button className="prev-btn" onClick={() => props.setListing(4)}>
                <img src={Previous} alt="" />
                <p className="">Previous</p>
              </Button>
              <Button className="next-btn" onClick={() => props.setListing(6)}>
                <p className="">Next</p>
                <img src={Next} alt="" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing5;
