import React, { useState } from "react";
import { Button, FormControl, InputGroup, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CarBackground from "../../components/common/carBackground";
import TabsComponent from "../../components/common/Tab";
import "../../css/login_register.css";
import { MemberRegisterApi, PartnerRegisterApi } from "../../utils/apiUrls";

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setconfirmPwd] = useState("");
  const [fullName, setfullName] = useState("");
  const [mobile, setmobile] = useState("");
  const [userType, setUserType] = useState("Partner");

  const [bussinesName, setBusinessName] = useState("");
  const [businessRegisterNo, setBusinessRegisterNo] = useState("");
  const [businessType, setBusinessType] = useState("Dealer");

  const [pointOfContactName, SetPointOfContactName] = useState("");
  const [pointOfContactEmail, SetpointOfContactEmail] = useState("");
  const [pointOfContactPhoneNumber, setPointOfContactPhoneNumber] =
    useState("");

  const submitForm = () => {
    if (password !== confirmPwd) {
      alert("password & confirm password should be same.");
    } else {
      const obj = {
        name: fullName,
        password,
        email,
        mobile,
        business: {
          name: bussinesName,
          uen: businessRegisterNo
        },
      };
      if (userType === "Partner") {
        PartnerRegisterApi(obj)
          .payload.then((res) => {
            props.history.push({
              pathname: "/login",
              state:
                "Your account has been successfully registered! Try signing in to Carki.",
            });
          })
          .catch((e) => {
            toast.error(e.data.message);
          });
      } else {
        delete obj.business;
        MemberRegisterApi(obj)
          .payload.then((res) => {
            props.history.push({
              pathname: "/login",
              state:
                "Your account has been successfully registered! Try signing in to Carki.",
            });
          })
          .catch((e) => {
            toast.error(e.data.message);
          });
      }
    }
  };

  return (
    <div className="row m-0">
      <div className="col-12 col-lg-7 p-0">
        <CarBackground />
      </div>
      <div className="col-12 col-lg-5 p-0 dark-background">
        <div className="row m-0">
          <div className="col-lg-2 col-1 p-0"></div>
          <div className="col-lg-8 col-10 p-0">
            <div className="row m-0 row_1">
              <div className="col-6 p-0 d-flex justify-content-center">
                <Button
                  variant="primary"
                  onClick={() => props.history.push("/login")}
                  className="btns prmy_btn"
                >
                  Log in
                </Button>
              </div>
              <div className="col-6 p-0 d-flex justify-content-center">
                <Button
                  variant="outline-primary"
                  onClick={() => props.history.push("/register")}
                  className="btns outline_btn"
                >
                  Create account
                </Button>
              </div>
            </div>

            <div className="row m-0 row_3">
              <div className="col-12 p-0">
                <p className="mb-0 heading_label">
                  <span>
                    <Link className="forgot_pwd_link" to="/login">
                      Log in /{" "}
                    </Link>
                  </span>
                  Create account
                </p>
              </div>
            </div>

            <div className="row m-0 row_4">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Full Name</label>
                <InputGroup className="mb-3">
                  <FormControl
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => setfullName(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="row m-0 row_4">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Email</label>
                <InputGroup className="mb-3">
                  <FormControl
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="row m-0 row_4 pt-1">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Password</label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="password"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="row m-0 row_4 pt-1">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Confirm Password</label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="password"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => setconfirmPwd(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="row m-0 row_4 pt-1">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Phone Number</label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">+65</InputGroup.Text>
                  <FormControl
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>

            {userType === "Partner" && (
              <>
                <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Business Name</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Business UEN No.</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => setBusinessRegisterNo(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                {/* <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Business POC name</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => SetPointOfContactName(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Business POC Email</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => SetpointOfContactEmail(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Business POC Phone Number</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) =>
                          setPointOfContactPhoneNumber(e.target.value)
                        }
                      />
                    </InputGroup>
                  </div>
                </div> */}

                {/* <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Business Type</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        as="select"
                        value={businessType}
                        onChange={(e) => {
                          setBusinessType(e.target.value);
                        }}
                      >
                        <option value="Dealer">Dealer</option>
                      </FormControl>
                    </InputGroup>
                  </div>
                </div> */}
              </>
            )}

            {/* <div className="row m-0 row_4 pt-1">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">User Type</label>
                <TabsComponent
                  userType={userType}
                  setUserType={(type) => setUserType(type)}
                />
              </div>
            </div> */}

            <div className="row m-0 row_6 pb-5">
              <div className="col-12 p-0 d-flex justify-content-center mt-5">
                <Button
                  onClick={() => submitForm()}
                  variant="outline-primary"
                  type="select"
                  className="btns login_btn"
                >
                  Create Account
                </Button>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-1 p-0"></div>
        </div>
      </div>
    </div>
  );
};

export default Register;
