import React, { useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import logo from "../images/img/Carki_Logo_New.png";
import profileIcon from "../images/icons/user.png";
import { LoginOutApi } from "../utils/apiUrls";

const header = () => {
  const [showList, setshowList] = useState(false);
  const [activeMenu, setactiveMenu] = useState("menu");
  const { pathname } = useLocation();

  let history = useHistory();

  const splitLocation = pathname.split("/");

  var accessToken = localStorage.getItem("accessToken");
  var retrievedObject = localStorage.getItem("loggedInUserDetails");
  const getLocalStorage = JSON.parse(retrievedObject);

  const logOutFunc = () => {
    LoginOutApi().payload.then(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loggedInUserDetails");
      history.push("/");
    });
  };

  return (
    //  < !--NavBaar & NavHeading-- >
    <header>
      <div id="navbar">
        <div id="logo">
          <Link to={`${accessToken ? "/partner-dashboard" : "/"}`}>
            {" "}
            <img src={logo} alt="" />
          </Link>
        </div>
        <div id="links">
          {/* <Link to="#">Buy</Link> */}
          <Link
            to="/sell"
            className={splitLocation[1] === "sell" ? "active" : ""}
            className="mr-5"
          >
            Sell
          </Link>
          <Link
            to="/financing"
            className={splitLocation[1] === "financing" ? "active" : ""}
          >
            Financing
          </Link>
          {/* <Link
            to="/workshops"
            className={splitLocation[1] === "workshops" ? "active" : ""}
          >
            Workshops
          </Link> */}
          {/* <Link
            to="/insurance"
            className={splitLocation[1] === "insurance" ? "active" : ""}
          >
            Insurance
          </Link> */}
          {/* <a href="#">Help</a> */}
        </div>
        <div className="dashed_line"></div>

        {accessToken ? (
          <div id="userCred">
            <div className="bell_icon">
              <i class="fas fa-bell"></i>
            </div>
            <span>|</span>
            <Link to="/partner-dashboard">
              <div className="user_profile">
                <div className="profile_icon">
                  <img
                    src={
                      getLocalStorage && getLocalStorage.profilePhoto
                        ? getLocalStorage.profilePhoto
                        : profileIcon
                    }
                    alt=""
                  />
                </div>
                <p className="mb-0 user_name">
                  {getLocalStorage ? getLocalStorage.name : ""}
                </p>
              </div>
            </Link>
            <a href="#" onClick={() => logOutFunc()}>
              Logout
            </a>
          </div>
        ) : (
          <div id="userCred">
            <Link to="/login">Log In</Link>
            <span>|</span>
            <Link to="/register" className="signup_btn">
              Sign Up
            </Link>
            {/* <i class="fas fa-bars hamburger_menu"></i> */}
          </div>
        )}

        <div className="mobile_nav_bar">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="hamburger_menu">
            <i class="fas fa-bars" onClick={() => setshowList(true)}></i>
          </div>

          {showList && (
            <div className="menus">
              <div className="d-flex justify-content-between align-items-center px-4 pt-3">
                <Link to="/">
                  {" "}
                  <img width="90" height="32" src={logo} alt="" />
                </Link>
                <i class="fas fa-times" onClick={() => setshowList(false)}></i>
              </div>

              <div className="menu_links_bk  mt-4 ">
                {accessToken && (
                  <div className="row m-0 mx-3">
                    <div className="col-1"></div>
                    <div
                      className={`col-4 p-0 d-flex justify-content-center align-items-center text-center p-0 tabs ${
                        activeMenu === "menu" && "active"
                      }`}
                      onClick={() => setactiveMenu("menu")}
                    >
                      Menu
                    </div>
                    <div className="col-2 p-0"></div>
                    <div
                      className={`col-4 p-0 d-flex justify-content-center align-items-center text-center p-0 tabs ${
                        activeMenu === "CRM" && "active"
                      }`}
                      onClick={() => setactiveMenu("CRM")}
                    >
                      CRM Menu
                    </div>
                    <div className="col-1"></div>
                  </div>
                )}

                {activeMenu === "menu" && (
                  <div className="menu_links">
                    <h4 className="text-center py-2">Menu</h4>
                    <div className="all_links">
                      {/* <div>
                        {" "}
                        <Link to="#">Buy</Link>
                      </div> */}
                      <div>
                        {" "}
                        <Link
                          to="/sell"
                          className={
                            splitLocation[1] === "sell" ? "active" : ""
                          }
                        >
                          Sell
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/financing"
                          className={
                            splitLocation[1] === "financing" ? "active" : ""
                          }
                        >
                          Financing
                        </Link>
                      </div>
                      {/* <div>
                        {" "}
                        <Link
                          to="/workshops"
                          className={
                            splitLocation[1] === "workshops" ? "active" : ""
                          }
                        >
                          Workshops
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/insurance"
                          className={
                            splitLocation[1] === "insurance" ? "active" : ""
                          }
                        >
                          Insurance
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <a href="#">Help</a>
                      </div> */}
                      {accessToken ? (
                        <>
                          {" "}
                          <div>
                            {" "}
                            <Link to="#" onClick={() => logOutFunc()}>
                              Logout
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            {" "}
                            <Link
                              to="/login"
                              className={
                                splitLocation[1] === "login" ? "active" : ""
                              }
                            >
                              Login
                            </Link>
                          </div>
                          <div>
                            {" "}
                            <Link
                              to="/register"
                              className={
                                splitLocation[1] === "register" ? "active" : ""
                              }
                            >
                              Signup
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {activeMenu === "CRM" && (
                  <div className="menu_links">
                    <h4 className="text-center py-2">CRM Menu</h4>
                    <div className="all_links">
                      <div>
                        {" "}
                        <Link
                          to="/partner-dashboard"
                          className={
                            splitLocation[1] === "partner-dashboard"
                              ? "active"
                              : ""
                          }
                        >
                          Dashboard
                        </Link>
                      </div>
                      <div
                        className="py-0"
                        style={{ paddingLeft: "32px", paddingRight: "32px" }}
                      >
                        <hr className="m-0 p-0" />
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="#"
                          className={
                            splitLocation[1] === "sell"
                              ? "active font-weight-bold"
                              : "font-weight-bold"
                          }
                        >
                          Open Bids
                        </Link>
                      </div>
                      <div
                        className="py-0"
                        style={{ paddingLeft: "32px", paddingRight: "32px" }}
                      >
                        <hr className="m-0 p-0" />
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="#"
                          className={
                            splitLocation[1] === "financing"
                              ? "active font-weight-bold"
                              : "font-weight-bold"
                          }
                        >
                          My Listings (Sell Used Cars)
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/partner-create-listing"
                          className={
                            splitLocation[1] === "partner-create-listing"
                              ? "active"
                              : ""
                          }
                        >
                          Create New Listing
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/manage-open-listing"
                          className={
                            splitLocation[1] === "manage-open-listing"
                              ? "active"
                              : ""
                          }
                        >
                          Manage Open Listings
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/view-closed-listing"
                          className={
                            splitLocation[1] === "manage-open-listing"
                              ? "active"
                              : ""
                          }
                        >
                          View Closed Listings
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="#"
                          className={
                            splitLocation[1] === "login" ? "active" : ""
                          }
                        >
                          My Bids (Buy Used Cars)
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/manage-open-bids"
                          className={
                            splitLocation[1] === "register" ? "active" : ""
                          }
                        >
                          Manage Open Bids
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/view-closed-bid"
                          className={
                            splitLocation[1] === "register" ? "active" : ""
                          }
                        >
                          View Bid History
                        </Link>
                      </div>
                      <div>
                        {" "}
                        <Link
                          to="/my-profile/view"
                          className={
                            splitLocation[1] === "my-profile" ? "active" : ""
                          }
                        >
                          My Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default header;
