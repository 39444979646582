import React, { useState, useEffect } from "react";
import PartnerPageRouting from "../../../components/common/partnerPageRouting";
import Header from "../../../components/header";
import "../../../css/partnerView.css";
import meterIcon from "../../../images/icons/Meter.png";
import calendarIcon from "../../../images/icons/calendar.png";
import carkiLogo from "../../../images/icons/CARKI_LOGO.png";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  AddNewBidApi,
  DeleteBidApi,
  DeleteListingApi,
  GetAllListingApiBuy,
  GetAllListingApiSell,
  GetListingBid,
  GetPartnerDashboardDetails,
  UpdateBidApi,
} from "../../../utils/apiUrls";
import { FindBidCloseTime, FindDate } from "../../../utils/constants";
import { CompareCurrentTime, FindTimeAgo } from "./../../../utils/constants";

let date = new Date();

const PartnerDashboard = (props) => {
  const loggedInUserDetails = JSON.parse(
    localStorage.getItem("loggedInUserDetails")
  );

  const [showBid, setShowBid] = useState(false);

  const [showBidDelete, setShowBidDelete] = useState(false);

  const [listingId, setlistingId] = useState("");
  const [bidSuccessFullyRegistered, setbidSuccessFullyRegistered] =
    useState(false);

  const [deleteType, setDeleteType] = useState("");
  const [bidPrice, setBidPrice] = useState(0);

  const [dashboardData, setDashboardData] = useState({});

  const [bidsOtherDelears, setBidsOtherDelears] = useState([]);
  const [myBidsUsedCar, setMyBidsUsedCar] = useState([]);
  const [myListingUsedCar, setMyListingUsedCar] = useState([]);

  const [bidsOtherDelearsCounts, setbidsOtherDelearsCounts] = useState([]);
  const [myBidsUsedCarCounts, setmyBidsUsedCarCounts] = useState([]);
  const [myListingUsedCarCounts, setmyListingUsedCarCounts] = useState([]);

  const [listCount1, setListCount1] = useState(8);
  const [listCount2, setListCount2] = useState(2);
  const [listCount3, setListCount3] = useState(2);

  const [time, setTime] = useState(0);

  useEffect(() => {
    GetListingBidFunc(listCount1);
    GetAllListingApiBuyFunc(listCount2);
    GetAllListingApiSellFunc(listCount3);
    GetPartnerDashboardDetailsFunc();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);
      FindBidCloseTime();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  const GetPartnerDashboardDetailsFunc = () => {
    GetPartnerDashboardDetails(date.toISOString()).payload.then((res) => {
      setDashboardData(res.data);
    });
  };

  const GetListingBidFunc = (listCount) => {
    GetListingBid(listCount).payload.then((res) => {
      setBidsOtherDelears(res.data.listingArray);
      setbidsOtherDelearsCounts(res.data.count);
    });
  };

  const GetAllListingApiBuyFunc = (listCount) => {
    GetAllListingApiBuy(listCount).payload.then((res) => {
      setMyBidsUsedCar(res.data.listingArray);
      setmyBidsUsedCarCounts(res.data.count);
    });
  };

  const GetAllListingApiSellFunc = (listCount) => {
    GetAllListingApiSell(listCount).payload.then((res) => {
      setMyListingUsedCar(res.data.listingArray);
      setmyListingUsedCarCounts(res.data.count);
    });
  };

  const raiseBidFunction = () => {
    let obj = {
      bidPrice,
      listingId: listingId.id,
    };
    if (listingId.lastBid) {
      obj.bidId = listingId.lastBid.id;
      UpdateBidApi(obj)
        .payload.then(() => {
          setbidSuccessFullyRegistered(true);
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    } else {
      AddNewBidApi(obj)
        .payload.then(() => {
          setbidSuccessFullyRegistered(true);
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    }
  };

  const deleteBidFunction = () => {
    let obj = {
      bidId: listingId.lastBid.id,
    };
    DeleteBidApi(obj)
      .payload.then(() => {
        setbidSuccessFullyRegistered(true);
        GetAllListingApiBuyFunc(listCount2);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const deleteListingFunction = () => {
    let obj = {
      listingId: listingId.id,
    };
    DeleteListingApi(obj)
      .payload.then(() => {
        setbidSuccessFullyRegistered(true);
        GetAllListingApiSellFunc(listCount3);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const showMoreFunc3 = () => {
    setListCount3(listCount3 + 2);
    GetAllListingApiSellFunc(listCount3 + 2);
  };

  const showMoreFunc2 = () => {
    setListCount2(listCount2 + 2);
    GetAllListingApiBuyFunc(listCount2 + 2);
  };

  const showMoreFunc1 = () => {
    setListCount1(listCount1 + 2);
    GetListingBidFunc(listCount1 + 2);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="background_white partner_dashborad_page_background">
        <div className="row m-0">
          <div className="col-3 d-lg-block d-md-block d-none pl-5">
            <PartnerPageRouting activePage="dashboard" />
          </div>

          <div className="col-lg-9 col-md-9 col-12 pr-lg-5 pr-md-5 pr-0 pl-lg-2 pl-md-2 pl-0 partner-dashboard mt-lg-0 mt-md-0 mt-4">
            <div className="col-lg-12 p-0 pd-section-1">
              <p className="pd-title">
                This Month:{" "}
                <span>
                  {date.toLocaleString("default", { month: "long" })}{" "}
                  {date.getFullYear()}
                </span>
              </p>
              <div className="row m-0 top-strip">
                <div className="col-lg-6 p-2 top-left">
                  <div className="row m-0">
                    <div className="col-lg-12">
                      <div className="row m-0">
                        <div className="col-4 mr-3 text-center d-flex flex-column align-items-center justify-content-center box-1">
                          <p className="mb-0">Total Sales</p>
                          <p className="mb-0">
                            <span>$ {dashboardData.totalSalesAmount}</span>
                          </p>
                        </div>
                        <div className="col-3 mr-3 text-center d-flex flex-column align-items-center justify-content-center box-2">
                          <p className="mb-0">No. of Sales</p>
                          <p className="mb-0">
                            <span>{dashboardData.soldListingsCount}</span>
                          </p>
                        </div>
                        <div className="col-3 mr-3 text-center d-flex flex-column align-items-center justify-content-center box-3">
                          <p className="mb-0">Avg. No. Sales</p>
                          <p className="mb-0">
                            <span>{dashboardData.averageSales}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-2 top-right">
                  <div className="row m-0">
                    <div className="col-lg-12">
                      <div className="row m-0 d-flex align-items-center justify-content-end">
                        <div className="col-lg-8 box-4">
                          <div className="row m-0 mt-1">
                            <div className="col-12 text-center p-0">
                              <p className="mb-0 sm-text">
                                <span>No. of Vehicles</span>
                              </p>
                            </div>
                          </div>
                          <div className="row m-0 mt-1">
                            <div className="col-4 text-center inner-box-1 p-0">
                              <p className="mb-0">For Sale</p>
                              <p className="mb-0">
                                <span>{dashboardData.openListingsCount}</span>
                              </p>
                            </div>
                            <div className="col-4 text-center inner-box-2 p-0">
                              <p className="mb-0">Pending</p>
                              <p className="mb-0">
                                <span>
                                  {dashboardData.pendingListingsCount}
                                </span>
                              </p>
                            </div>
                            <div className="col-4 text-center inner-box-3 p-0">
                              <p className="mb-0">Sold</p>
                              <p className="mb-0">
                                <span>{dashboardData.closedListingsCount}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 mt-1 d-flex justify-content-between">
              <div className="col-lg-6 col-md-6 col-12 p-0 pr-lg-3 pr-md-3">
                <div className="details_background_2 pd-section-2 pt-4">
                  <div className="row m-0">
                    <div className="col-6 d-flex">
                      <p className="mb-0 pd-title-1">Latest Updates &nbsp;</p>
                      <p className="mb-0 pd-title-1">
                        <span>(5)</span>
                      </p>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                      {/* <button className="btn-1">View All Updates</button> */}
                    </div>
                  </div>

                  <div className="row m-0 p-3">
                    <div className="col-12 white-section-1 pt-2">
                      <div className="row">
                        <div className="col-6 d-flex">
                          <p className="mb-0 pd-title-2">
                            My Bids (Buy Used Cars) &nbsp;
                          </p>
                          <p className="mb-0 pd-title-2">
                            <span>({myBidsUsedCar.length})</span>
                          </p>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-end">
                          {/* <button className="btn-2">Create A Bid (Buy)</button> */}
                        </div>
                      </div>

                      {listingId && (
                        <Modal
                          show={showBidDelete}
                          className="raise_bid_modal"
                          onHide={() => setShowBidDelete(false)}
                          aria-labelledby="contained-modal-title-vcenter raise_bid_modal"
                          centered
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Body>
                            <div className="row m-0 p-4">
                              <div className="col-3 pl-0 pr-5">
                                <div className="user_profile"></div>
                              </div>
                              <div className="col-8 pl-4">
                                <div className="car_name">
                                  {listingId.vehicleMake}{" "}
                                  {listingId.vehicleModel}
                                </div>
                                <div className="category_name">
                                  CAT {listingId.coeCategory.split(" ")[0]}{" "}
                                  &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                  {listingId.parfEligibility
                                    ? "PARF Car"
                                    : "COE Car"}
                                </div>
                                <div className="car_details d-flex align-items-center ">
                                  <i class="fas fa-calendar-day"></i>{" "}
                                  <p className="mb-0 pl-2">
                                    Registration Date:
                                  </p>{" "}
                                  <p className="pl-2 mb-0 font-weight-bold">
                                    29/02/2011
                                  </p>
                                </div>

                                <div className="car_details d-flex align-items-center ">
                                  <i class="fas fa-user-alt"></i>{" "}
                                  <p className="mb-0 pl-2">Seats:</p>{" "}
                                  <p className="pl-2 mb-0 font-weight-bold">
                                    2
                                  </p>
                                </div>

                                <div className="car_details d-flex align-items-center ">
                                  <i class="fas fa-tachometer-alt"></i>{" "}
                                  <p className="mb-0 pl-2">Mileage (km):</p>{" "}
                                  <p className="pl-2 mb-0 font-weight-bold">
                                    {listingId.maximumPower}
                                  </p>
                                </div>
                              </div>
                              <div className="col-1 p-0 d-flex justify-content-end cross_icon">
                                <i
                                  class="fas fa-times cursor_pointer"
                                  onClick={() => setShowBidDelete(false)}
                                ></i>
                              </div>
                            </div>

                            <hr className="mt-0" />

                            {!bidSuccessFullyRegistered ? (
                              <>
                                <div className="success_bid_raise_div row m-0">
                                  <div className="col-1 p-0"></div>
                                  <div className="col-10 p-0 mt-3">
                                    <div className="sucess_bid_msg_box text-center p-5 mb-2">
                                      <p
                                        className="heading text-center mb-3"
                                        style={{
                                          color: "#404244",
                                          lineHeight: "32px",
                                        }}
                                      >
                                        Are you sure you want to{" "}
                                        <span style={{ color: "#D01E1E" }}>
                                          delete
                                        </span>{" "}
                                        this listing?
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-1 p-0"></div>
                                </div>

                                <div className="cancel_raise_btn_div row m-0">
                                  <div className="col-2 p-0"></div>
                                  <div className="col-8 p-0 d-flex justify-content-between align-items-center">
                                    <button
                                      className="cancel_btn_delete"
                                      onClick={() => setShowBidDelete(false)}
                                    >
                                      Go Back
                                    </button>
                                    <button
                                      className="raise_bid_delete"
                                      onClick={() => {
                                        if (deleteType === "bidDelete") {
                                          deleteBidFunction();
                                        } else {
                                          deleteListingFunction();
                                        }
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                  <div className="col-2 p-0"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="success_bid_raise_div row m-0">
                                  <div className="col-1 p-0"></div>
                                  <div className="col-10 p-0">
                                    <p className="heading text-center mb-3">
                                      {deleteType === "bidDelete"
                                        ? "Bid"
                                        : "Listing"}{" "}
                                      Successfully Deleted.
                                    </p>

                                    <div className="sucess_bid_msg_box text-center py-2 d-flex flex-column justify-content-center align-items-center">
                                      <p className="font-weight-bold">
                                        You have deleted your listing for:
                                      </p>
                                      <p
                                        className="mb-0 font-weight-bolder"
                                        style={{ fontSize: "20px" }}
                                      >
                                        {listingId.vehicleMake}{" "}
                                        {listingId.vehicleModel}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-1 p-0"></div>
                                </div>
                              </>
                            )}
                          </Modal.Body>
                        </Modal>
                      )}

                      <hr />

                      {!myBidsUsedCar.length && (
                        <p className="text-center font-weight-bold">
                          No record found
                        </p>
                      )}

                      {myBidsUsedCar
                        .filter((v) => v.status !== "closed")
                        // .filter(
                        //   (v) =>
                        //     v.partnerId !== loggedInUserDetails.id
                        // )
                        .map((v) => {
                          return (
                            <div className="mb-3">
                              <div className="row m-0">
                                <div className="col-6 p-0 d-flex justify-content-start">
                                  <p className="mb-0 pd-title-3 blue-text">
                                    In Progress &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-3">
                                    <span>{FindTimeAgo(v.createdAt)}</span>
                                  </p>
                                </div>

                                {CompareCurrentTime(v.releaseBidDate)
                                  ? <div className="col-6 p-0 d-flex justify-content-end">
                                    <p className="mb-0 pd-title-4">
                                      Bidding closes in: &nbsp;&nbsp;
                                    </p>
                                    <p className="mb-0 pd-title-4">
                                      <span>
                                        {FindBidCloseTime(v.closeBidDate)}
                                      </span>
                                    </p>
                                  </div>
                                  : null}
                              </div>

                              <div
                                className="row m-0 mt-1 blue-zone cursor_pointer"
                                onClick={() =>
                                  props.history.push(
                                    `/manage-open-bids/open-existing-bid/${v.id}`
                                  )
                                }
                              >
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2">
                                    <div className="col-4 p-0 pd-tab-inner">
                                      <img src={v.displayPhoto} alt="" />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleMake +
                                              " " +
                                              v.vehicleModel}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            CAT {v.coeCategory.split(" ")[0]}{" "}
                                            &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                            {v.parfEligibility
                                              ? "PARF Car"
                                              : "COE Car"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col-7 p-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {FindDate(v.coeExpiryDate)}
                                          </p>
                                        </div>
                                        <div className="col-5 p-0 d-flex justify-content-end cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {v.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 pt-2 px-1 tab-1-right">
                                  <div className="row m-0 mt-1 mb-3">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 red-text">
                                      <p className="mb-0 text-right">
                                        Your last Bid:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 red-text">
                                      <p className="mb-0 text-right">
                                        $ {v.lastBid.bidPrice}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row m-0 mt-1 mb-1">
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button
                                        className="delete"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setDeleteType("bidDelete");
                                          setShowBidDelete(true);
                                          setlistingId(v);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button
                                        className="raise-bidd"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setShowBid(true);
                                          setlistingId(v);
                                        }}
                                      >
                                        Edit Bid
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {myBidsUsedCar
                        .filter((v) => v.status === "closed")
                        // .filter((v) => v.partnerId === loggedInUserDetails.id)
                        .map((v) => {
                          return (
                            <div className="mb-3">
                              <div className="row m-0 mt-3">
                                <div className="col-6 p-0 d-flex justify-content-start ">
                                  <p className="mb-0 pd-title-3 green-text">
                                    Auction Complete &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-3">
                                    <span>{FindTimeAgo(v.createdAt)}</span>
                                  </p>
                                </div>
                                <div className="col-6 p-0 d-flex justify-content-end">
                                  <p className="mb-0 pd-title-4 green-text">
                                    Bidding closed
                                  </p>
                                </div>
                              </div>

                              <div
                                className="row m-0 mt-1 green-zone cursor_pointer"
                                onClick={() =>
                                  props.history.push(
                                    `/manage-open-bids/${v.id}`
                                  )
                                }
                              >
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2">
                                    <div className="col-4 p-0 pd-tab-inner">
                                      <img src={v.displayPhoto} alt="" />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleModel} {v.vehicleMake}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            CAT {v.coeCategory.split(" ")[0]}{" "}
                                            &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                            {v.parfEligibility
                                              ? "PARF Car"
                                              : "COE Car"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col-7 p-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {FindDate(v.coeExpiryDate)}
                                          </p>
                                        </div>
                                        <div className="col-5 p-0 d-flex justify-content-end cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {v.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 pt-2 px-1 tab-1-right">
                                  <div className="row m-0">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 red-text">
                                      <p className="mb-0 text-right green-text">
                                        You successfully
                                        <br />
                                        bought this car for:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 red-text">
                                      <p className="mb-0 text-right green-text">
                                        {v.finalSoldPrice
                                          ? v.finalSoldPrice
                                          : v.lastBid
                                          ? v.lastBid.bidPrice
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row m-0 mt-1">
                                    <div className="col-6 d-flex justify-content-end p-0"></div>
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button className="green-details">
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {/* {myBidsUsedCar
                        .filter((v) => v.status !== "closed")
                        .filter(
                          (v) =>
                            v.partnerId === loggedInUserDetails.id
                        )
                        .map((v) => {
                          return (
                            <div className="mb-3">
                              <div className="row m-0 mt-3">
                                <div className="col-6 p-0 d-flex justify-content-start ">
                                  <p className="mb-0 pd-title-3 blue-text">
                                    Winning &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-3">
                                    <span>6 mins ago</span>
                                  </p>
                                </div>
                                <div className="col-6 p-0 d-flex justify-content-end">
                                  <p className="mb-0 pd-title-4">
                                    Bidding closes in: &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-4">
                                    <span>
                                      {FindBidCloseTime(v.closeBidDate)}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row m-0 mt-1 blue-zone">
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2">
                                    <div className="col-4 p-0 pd-tab-inner">
                                      <img src={v.displayPhoto && v.displayPhoto !== "www.example.com" ? v.displayPhoto : carkiLogo} alt="" />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleModel} {v.vehicleMake}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            CAT {v.coeCategory.split(" ")[0]}{" "}
                                            &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                            {v.parfEligibility
                                              ? "PARF Car"
                                              : "COE Car"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col-7 p-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">{FindDate(v.coeExpiryDate)}</p>
                                        </div>
                                        <div className="col-5 p-0 d-flex justify-content-end cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">{v.maximumPower}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 pt-2 px-1 tab-1-right">
                                  <div className="row m-0">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 red-text">
                                      <p className="mb-0 text-right blue-text">
                                        Latest Bid:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 red-text">
                                      <p className="mb-0 text-right blue-text">
                                        {" "}
                                       $ 1000
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row m-0 mt-1">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 black-text">
                                      <p className="mb-0 text-right">
                                        Starting Bid:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 black-text">
                                      <p className="mb-0 text-right">
                                        {" "}
                                        $ 2000
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row m-0 mt-1">
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button className="delete">Delete</button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button className="edit-bid">
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })} */}

                      {myBidsUsedCar.length > 2 && (
                        <div className="row m-0 mt-3 mb-4">
                          <div className="col-12">
                            <p
                              className="mb-0 text-center show-all cursor_pointer"
                              onClick={() => showMoreFunc2()}
                            >
                              {" "}
                              Show All{" "}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row m-0 p-3">
                    <div className="col-12 white-section-1 pt-2">
                      <div className="row">
                        <div className="col-7 d-flex">
                          <p className="mb-0 pd-title-2">
                            My Listings (Sell Used Cars) &nbsp;
                          </p>
                          <p className="mb-0 pd-title-2">
                            <span>({myListingUsedCar.length})</span>
                          </p>
                        </div>
                        <div className="col-5 d-flex align-items-center justify-content-end">
                          <button
                            className="btn-2"
                            onClick={() =>
                              props.history.push("/partner-create-listing")
                            }
                          >
                            Create A Listing (Sell)
                          </button>
                        </div>
                      </div>

                      <hr />

                      {!myListingUsedCar.length && (
                        <p className="text-center font-weight-bold">
                          No record found
                        </p>
                      )}

                      {myListingUsedCar
                        .filter((v) => v.status === "open")
                        .map((v, i) => {
                          return (
                            <div className="mb-3">
                              <div className="row m-0" key={i}>
                                <div className="col-6 p-0 d-flex justify-content-start">
                                  <p className="mb-0 pd-title-3 blue-text">
                                    In Progress &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-3">
                                    <span>{FindTimeAgo(v.createdAt)}</span>
                                  </p>
                                </div>
                                {CompareCurrentTime(v.releaseBidDate)
                                  ? <div className="col-6 p-0 d-flex justify-content-end">
                                    <p className="mb-0 pd-title-4">
                                      Bidding closes in: &nbsp;&nbsp;
                                    </p>
                                    <p className="mb-0 pd-title-4">
                                      <span>
                                        {FindBidCloseTime(v.closeBidDate)}
                                      </span>
                                    </p>
                                  </div>
                                  : null}
                              </div>

                              <div
                                className="row m-0 mt-1 blue-zone cursor_pointer"
                                onClick={() =>
                                  props.history.push(
                                    `/manage-open-listing/${v.id}`
                                  )
                                }
                              >
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2">
                                    <div className="col-4 p-0 pd-tab-inner">
                                      <img src={v.displayPhoto} alt="" />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleMake +
                                              " " +
                                              v.vehicleModel}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            CAT {v.coeCategory.split(" ")[0]}{" "}
                                            &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                            {v.parfEligibility
                                              ? "PARF Car"
                                              : "COE Car"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col-7 p-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {FindDate(v.coeExpiryDate)}
                                          </p>
                                        </div>
                                        <div className="col-5 p-0 d-flex justify-content-end cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {v.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 pt-2 px-1 tab-1-right">
                                  <div className="row m-0 mt-1 mb-3">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 red-text">
                                      <p className="mb-0 text-right">
                                        Starting Bid:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 red-text">
                                      <p className="mb-0 text-right">
                                        ${" "}
                                        {v.startingBid
                                          ? v.startingBid.bidPrice
                                          : v.startingBidPrice}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div className="row m-0 mt-1">
                                  <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 black-text">
                                    <p className="mb-0 text-right">
                                      Starting Bid:
                                    </p>
                                  </div>
                                  <div className="col-4 p-0 black-text">
                                    <p className="mb-0 text-right">
                                      {" "}
                                      $ {v.startingBidPrice}
                                    </p>
                                  </div>
                                </div> */}
                                  <div className="row m-0 mt-1">
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button
                                        className="delete"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setDeleteType("listingDelete");
                                          setShowBidDelete(true);
                                          setlistingId(v);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button
                                        className="edit-bid"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          props.history.push({
                                            pathname: "/partner-create-listing",
                                            state: v,
                                          });
                                        }}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {myListingUsedCar
                        .filter((v) => v.status === "closed")
                        // .filter((v) => v.partnerId === loggedInUserDetails.id)
                        .map((v) => {
                          return (
                            <div className="mb-3">
                              <div className="row m-0 mt-3">
                                <div className="col-6 p-0 d-flex justify-content-start ">
                                  <p className="mb-0 pd-title-3 green-text">
                                    Auction Complete &nbsp;&nbsp;
                                  </p>
                                  <p className="mb-0 pd-title-3">
                                    <span>{FindTimeAgo(v.createdAt)}</span>
                                  </p>
                                </div>
                                <div className="col-6 p-0 d-flex justify-content-end">
                                  <p className="mb-0 pd-title-4 green-text">
                                    Bidding closed
                                  </p>
                                </div>
                              </div>

                              <div
                                className="row m-0 mt-1 green-zone cursor_pointer"
                                onClick={() =>
                                  props.history.push(
                                    `/view-closed-listing/${v.id}`
                                  )
                                }
                              >
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2">
                                    <div className="col-4 p-0 pd-tab-inner">
                                      <img src={v.displayPhoto} alt="" />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleModel} {v.vehicleMake}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            CAT B &nbsp;&nbsp; | &nbsp;&nbsp;
                                            PARF Car
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col-7 p-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {FindDate(v.coeExpiryDate)}
                                          </p>
                                        </div>
                                        <div className="col-5 p-0 d-flex justify-content-end cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {v.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 pt-2 px-1 tab-1-right">
                                  <div className="row m-0">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 red-text">
                                      <p className="mb-0 text-right green-text">
                                        You successfully
                                        <br />
                                        bought this car for:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 red-text">
                                      <p className="mb-0 text-right green-text">
                                        {v.finalSoldPrice
                                          ? v.finalSoldPrice
                                          : v.lastBid
                                          ? v.lastBid.bidPrice
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row m-0 mt-1">
                                    <div className="col-6 d-flex justify-content-end p-0"></div>
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button className="green-details">
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {myListingUsedCarCounts > 2 && (
                        <div className="row m-0 mt-3 mb-4">
                          <div className="col-12">
                            <p
                              className="mb-0 text-center show-all cursor_pointer"
                              onClick={() => showMoreFunc3()}
                            >
                              {" "}
                              Show All{" "}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 p-0">
                <div className="details_background_2 pd-section-2 pt-4">
                  <div className="row m-0">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <p className="mb-0 pd-title-1">Bid For Used Cars</p>
                    </div>
                  </div>

                  {listingId && (
                    <Modal
                      show={showBid}
                      className="raise_bid_modal"
                      onHide={() => setShowBid(false)}
                      aria-labelledby="contained-modal-title-vcenter raise_bid_modal"
                      centered
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>
                        <div className="row m-0 p-4">
                          <div className="col-3 pl-0 pr-5">
                            <div className="user_profile"></div>
                          </div>
                          <div className="col-8 pl-4">
                            <div className="car_name">
                              {listingId.vehicleMake} {listingId.vehicleModel}
                            </div>
                            <div className="category_name">
                              {" "}
                              CAT {listingId.coeCategory.split(" ")[0]}{" "}
                              &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                              {listingId.parfEligibility
                                ? "PARF Car"
                                : "COE Car"}
                            </div>
                            <div className="car_details d-flex align-items-center ">
                              <i class="fas fa-calendar-day"></i>{" "}
                              <p className="mb-0 pl-2">Registration Date:</p>{" "}
                              <p className="pl-2 mb-0 font-weight-bold">
                                29/02/2011
                              </p>
                            </div>

                            <div className="car_details d-flex align-items-center ">
                              <i class="fas fa-user-alt"></i>{" "}
                              <p className="mb-0 pl-2">Seats:</p>{" "}
                              <p className="pl-2 mb-0 font-weight-bold">2</p>
                            </div>

                            <div className="car_details d-flex align-items-center ">
                              <i class="fas fa-tachometer-alt"></i>{" "}
                              <p className="mb-0 pl-2">Mileage (km):</p>{" "}
                              <p className="pl-2 mb-0 font-weight-bold">
                                {listingId.maximumPower}
                              </p>
                            </div>
                          </div>
                          <div className="col-1 p-0 d-flex justify-content-end cross_icon">
                            <i
                              class="fas fa-times cursor_pointer"
                              onClick={() => setShowBid(false)}
                            ></i>
                          </div>
                        </div>

                        <hr className="mt-0" />

                        {!bidSuccessFullyRegistered ? (
                          <>
                            <div className="row m-0">
                              <div className="col-2 p-0"></div>
                              <div className="col-8 p-0 d-flex flex-column align-items-center">
                                {listingId.lastBid && (
                                  <div className="opening_bid_price">
                                    Your last Bid: &nbsp; ${" "}
                                    {listingId.lastBid.bidPrice}
                                  </div>
                                )}
                                <div className="opening_bid_price">
                                  Opening Bid: &nbsp; ${" "}
                                  {listingId.startingBidPrice}
                                </div>
                                <div className="new_price_div p-3 d-flex align-items-center justify-content-between">
                                  <p className="mb-0 new_price_text">
                                    New Price:
                                  </p>
                                  <div className=" d-flex align-items-center">
                                    <span>$</span>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      placeholder="220,000"
                                      value={bidPrice}
                                      onChange={(e) =>
                                        setBidPrice(Number(e.target.value))
                                      }
                                    />
                                    <div className="arrow_div_up_down d-flex flex-column pl-3">
                                      <i
                                        class="fas fa-chevron-up"
                                        onClick={() =>
                                          setBidPrice(bidPrice + 1)
                                        }
                                      ></i>
                                      <i
                                        class="fas fa-chevron-down"
                                        onClick={() => {
                                          if (bidPrice > 0) {
                                            setBidPrice(bidPrice - 1);
                                          }
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 p-0"></div>
                            </div>

                            <div className="cancel_raise_btn_div row m-0">
                              <div className="col-2 p-0"></div>
                              <div className="col-8 p-0 d-flex justify-content-between align-items-center">
                                <button
                                  className="cancel_btn"
                                  onClick={() => setShowBid(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="raise_bid"
                                  onClick={() => {
                                    raiseBidFunction();
                                  }}
                                >
                                  Bid
                                </button>
                              </div>
                              <div className="col-2 p-0"></div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="success_bid_raise_div row m-0">
                              <div className="col-1 p-0"></div>
                              <div className="col-10 p-0">
                                <p className="heading text-center mb-3">
                                  Bid Successfully Edited.
                                </p>

                                <div className="sucess_bid_msg_box text-center py-2">
                                  {/* <p className="font-weight-bold">
                                    You are currently winning the bid for the
                                  </p> */}
                                  <p
                                    className="mb-0 font-weight-bolder"
                                    style={{ fontSize: "20px" }}
                                  >
                                    {listingId.vehicleMake}{" "}
                                    {listingId.vehicleModel}
                                  </p>
                                  <p className="mb-0 mt-2">at</p>
                                  <p className="font-weight-bold mt-2 mb-0 green_price_text">
                                    $ {bidPrice}
                                  </p>
                                </div>
                              </div>
                              <div className="col-1 p-0"></div>
                            </div>
                          </>
                        )}
                      </Modal.Body>
                    </Modal>
                  )}

                  <div className="row m-0 p-3">
                    <div className="col-12 white-section-1 pt-2">
                      <div className="row">
                        <div className="col-12 d-flex">
                          <p className="mb-0 pd-title-2">
                            Open Bids (Other Dealers) &nbsp;
                          </p>
                          <p className="mb-0 pd-title-2">
                            <span>({bidsOtherDelears.length})</span>
                          </p>
                        </div>
                      </div>

                      <hr />

                      {!bidsOtherDelears.length && (
                        <p className="text-center font-weight-bold">
                          No record found
                        </p>
                      )}

                      {(bidsOtherDelears || [])
                        .filter((v) => v.status === "open")
                        .map((v, i) => {
                          return (
                            <div className="mb-3">
                              <div className="row m-0" key={i}>
                                <div className="col-6 p-0 d-flex justify-content-start">
                                  <p className="mb-0 pd-title-3 blue-text">
                                    {v.partner &&
                                      v.partner.business &&
                                      v.partner.business.name}
                                  </p>
                                </div>

                                {CompareCurrentTime(v.releaseBidDate)
                                  ? <div className="col-6 p-0 d-flex justify-content-end">
                                    <p className="mb-0 pd-title-4">
                                      Bidding closes in: &nbsp;&nbsp;
                                    </p>
                                    <p className="mb-0 pd-title-4">
                                      <span>
                                        {FindBidCloseTime(v.closeBidDate)}
                                      </span>
                                    </p>
                                  </div>
                                  : null}
                              </div>

                              <div
                                className="row m-0 mt-1 blue-zone cursor_pointer"
                                onClick={() =>
                                  props.history.push(
                                    `/manage-open-bids/open-new-bid/${v.id}`
                                  )
                                }
                              >
                                <div className="col-6 tab-1-left p-0">
                                  <div className="row m-0 p-2">
                                    <div className="col-4 p-0 pd-tab-inner">
                                      <img src={v.displayPhoto} alt="" />
                                    </div>
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2">
                                      <div className="row m-0">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head">
                                            {v.vehicleMake +
                                              " " +
                                              v.vehicleModel}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col p-0">
                                          <p className="mb-0 tab-head-light">
                                            CAT {v.coeCategory.split(" ")[0]}{" "}
                                            &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                            {v.parfEligibility
                                              ? "PARF Car"
                                              : "COE Car"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row m-0 mt-1">
                                        <div className="col-7 p-0 d-flex cal-meter align-items-center">
                                          <img
                                            src={calendarIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {FindDate(v.coeExpiryDate)}
                                          </p>
                                        </div>
                                        <div className="col-5 p-0 d-flex justify-content-end cal-meter align-items-center">
                                          <img
                                            src={meterIcon}
                                            alt=""
                                            className="mr-1"
                                          />
                                          <p className="mb-0">
                                            {v.maximumPower}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 py-1 pr-2 tab-1-right">
                                  {/* <div className="row m-0">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 red-text">
                                      <p className="mb-0 text-right blue-text">
                                        Latest Bid:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 red-text">
                                      <p className="mb-0 text-right blue-text">
                                        $ {v.expectedFinalPrice}
                                      </p>
                                    </div>
                                  </div> */}
                                  <div className="row m-0 mt-1">
                                    <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-2 black-text mb-3">
                                      <p className="mb-0 text-right">
                                        Opening Bid:
                                      </p>
                                    </div>
                                    <div className="col-4 p-0 black-text">
                                      <p className="mb-0 text-right">
                                        $ {v.startingBidPrice}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row m-0 mt-1">
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button className="view-detail-blue">
                                        View Details
                                      </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end p-0">
                                      <button
                                        className={`bid-for-car ${CompareCurrentTime(v.releaseBidDate) ? "" : "bid-for-car-disabled"}  `}
                                        disabled
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setShowBid(true);
                                          setlistingId(v);
                                        }}
                                      >
                                        Bid For Car
                                        
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {bidsOtherDelears.length > 3 && (
                        <div className="row m-0 mt-3 mb-4">
                          <div className="col-12">
                            <p
                              className="mb-0 text-center show-all cursor_pointer"
                              onClick={() => showMoreFunc1()}
                            >
                              {" "}
                              Show All{" "}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerDashboard;
