import React, { Component, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import carLoad1 from "../../images/img/car-loan-1.png";
import car from "../../images/Photos/jack.jpg";
import DummyImg from "../../images/Photos/DummyImg.jpg"
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { submiReqFormData } from "../../utils/apiUrls";
import { toast } from "react-toastify";

let settings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 2500,
  infinite: true,
  // prevArrow: <button>asda</button>,
  // nextArrow: <button>asdsad</button>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const n = 9;

const Financing = (props) => {
  let history = useHistory();

  const [vehiclePlateNo, setVehiclePlate] = useState("");
  const [name, setName] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  const [showValidation, setshowValidation] = useState(false);

  const SubmitData = () => {
    if (name && mobileNo && vehiclePlateNo) {
      setshowValidation(false);
      let obj = {
        type: "finance",
        name,
        mobile: mobileNo,
        additionalInfo: {
          vehicleNumber: vehiclePlateNo,
        },
      };
      submiReqFormData(obj)
        .payload.then((res) => {
          toast.success(res.message);
          setmobileNo("");
          setName("");
          setVehiclePlate("");
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    }
    else {
      setshowValidation(true);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid financing px-lg-0 px-md-0 p-0">
        <div className="row d-lg-flex d-md-flex d-none m-0">
          <div className="col-sm-7 fin-front-banner-1">
            <h1>
              Finance Your
              <br />
              Car Loans In
              <br />
              Under 5 Minutes
            </h1>
            <p>
              Get the full breakdown of your financing costs with our loan
              <br />
              and tax calculators in the most efficient way.
            </p>

            <button
              type="button"
              className="btn btn-info fin-button-1"
              onClick={() => history.push("/car-loan-new")}
            >
              Loan Calculator &nbsp; &nbsp;
              <i className="ri-arrow-right-line"></i>
            </button>

            <button
              type="button"
              onClick={() => history.push("/car-loan-roadTax")}
              className="btn btn-warning fin-button-2"
            >
              Road Tax Calculator &nbsp; &nbsp;
              <i className="ri-arrow-right-line"></i>
            </button>
          </div>

          <div className="col-sm-5 fin-front-banner-2"></div>
        </div>

        <div className="row m-0 sell-row-responsive finance-row-responsive d-lg-none d-md-none d-block">
          <div className="area_1">
            <h4>Finance Your Car Loans In Under 5 Minutes</h4>
            <p>
              Get the full breakdown of your financing costs with our loan and
              tax calculators in the most efficient way.
            </p>
          </div>
          <div className="area_2"></div>
          <button
            onClick={() => history.push("/car-loan-new")}
            type="button"
            className="btn btn-orange banner-btn btn1"
          >
            Loan Calculator &nbsp; &nbsp;
            <i className="ri-arrow-right-line"></i>
          </button>
          <button
            onClick={() => history.push("/car-loan-roadTax")}
            type="button"
            className="btn btn-orange banner-btn btn2"
          >
            Road Tax Calculator &nbsp; &nbsp;
            <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>

      <div className="container-fluid financing-container-fluid position-relative p-0">
        
        {/* <div className="whatsapp-container whatsapp-container-finance">
          <img src="../images/img/whatsapp_logo_1_1.png" alt="" />
        </div> */}
        
        <div className="financing-page-popup p-1">
          <div class="row m-0 mt-2">
            <div class="col pl-1 pr-1 d-flex align-items-center justify-content-between">
                <img src={DummyImg} />
                <p class="mb-0 mt-0 textA">Hey! Welcome to Carki.</p>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col pl-1 pr-1">
                <p class="mb-0 mt-0 textB">We’re a team of humans, not bots. chat with us! How may we help you today? </p>
            </div>
          </div>

          <div class="row m-0 mt-4">  
            <div class="col pl-1 pr-1">
              <p class="mb-0 mt-0 textC">— Wilson Wen <br/> Director Of Business Development <br/> Carki Pte Ltd</p>
            </div>
          </div>
        </div>

        <h1>
          Don’t Worry About The Paperwork,
          <br />
          Let Us Worry For You
        </h1>
        <p>
          Save up to 50% in auto financing costs with Singapore’s most
          established auto financiers.
        </p>
        <div className="pannal">
          <div className="inner-pan-1">
            <div className="form-group">
              <div className="col-sm-10 p-lg-0 d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  id="vpl"
                  className={`${showValidation && !vehiclePlateNo ? "validation_err" : ""}`}
                  placeholder="Vehicle Plate Number"
                  name="vpl"
                  value={vehiclePlateNo}
                  onChange={(e) => setVehiclePlate(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-10 p-lg-0 d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  className={`${showValidation && !name ? "validation_err" : ""}`}
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-10 p-lg-0 d-flex justify-content-center">
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  className={`${showValidation && !mobileNo ? "validation_err" : ""}`}
                  placeholder="Mobile Number"
                  name="phone"
                  value={mobileNo}
                  onChange={(e) => setmobileNo(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-10 p-0">
                <button
                  className="btn fin-button-3 form-control"
                  onClick={() => SubmitData()}
                >
                  Get A FREE Quote Today!&emsp;
                  <i className="ri-arrow-right-line"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="inner-pan-2">
            <img src={carLoad1} alt="" />
          </div>
        </div>
      </div>

      {/* <section className="reviews-background px-lg-5 px-0">
        <div className="row m-0 align-items-center review-headline">
          <div className="col-lg-10 col-md-10 col-12 p-lg-0 p-md-0 px-5">
            <h2 className="review-header px-lg-0 px-4">
              Here’s What Our Customers Have To Say About Our Service...
            </h2>
          </div>
          <div className="col-2 text-right p-0">
            <p className="mb-0 see-all-review">See All Reviews</p>
          </div>
        </div>
        <div className="px-3">
          <Slider {...settings}>
            {[...Array(n)].map((v, i) => {
              return (
                <div
                  key={i}
                  className="col-12 carousel-cards review-card border-0"
                >
                  <div className="card-body">
                    <div className="row m-0 align-items-center">
                      <div className="col-2 pl-3 review-image"></div>
                      <div className="col-9 pr-0 review-name">
                        <h5 className="mb-1 text-left">King Jack</h5>
                        <p className="mb-0">Kingsmen Tailor</p>
                      </div>
                      <div className="col-1 pl-0 review-icon">
                        <i className="fab fa-facebook-square"></i>
                      </div>
                    </div>
                    <div className="row m-0 mt-3">
                      <div className="col-12 pl-2">
                        <p>
                          From start till the end, Joseph has been nothing but
                          patient, professional, accommodating, honest and
                          transparent. Kudoz to this young team Including Mark
                          and Wan...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="row m-0 align-items-center see_all_review_mobile">
          <div className="col-12 p-0 mb-3">
            <h2 className="review-header">See All Reviews</h2>
          </div>
        </div>
      </section> */}
      <Footer />
    </React.Fragment>
  );
};

export default Financing;
