import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import car_insurance from "../../images/icons/car-insurance.png";
import Slider from "react-slick";

let settings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 2500,
  infinite: true,
  // prevArrow: <button>asda</button>,
  // nextArrow: <button>asdsad</button>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const n = 9;

const Insurance = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid workshop-cont-1 insurance_background d-flex flex-column justify-content-center px-0">
        <div className="row m-0 align-items-center">
          <div className="col-1 p-0"></div>
          <div className="col-lg-5 col-md-5 col-12 p-0 pl-lg-5 pl-md-5 pl-4">
            <div className="row m-0">
              <h1>
                Personalised Car <br /> Insurance Tailored <br />
                For Every Driver
              </h1>
            </div>
            <div className="row m-0">
              <p>
                Pay for only what you want.
                <br /> Nothing else.
                <br /> Simple, quick, and highly affordable.
                <br /> Protect yourself and your family with Carki.
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-12 p-0 pr-lg-5 pr-md-5 pr-0">
            <div className="row m-0 ml-lg-5 ml-md-5 ml-0 get_quote_background">
              <div className="row m-0 w-100 align-items-center">
                <div className="col-3 p-0">
                  <img className="sell-icon" src={car_insurance} alt="" />
                </div>
                <div className="col-9 p-0 text-center">
                  <h4>
                    Get A FREE Personalised <br /> Quote For Your Car
                  </h4>
                </div>
              </div>

              <div className="row m-0 w-100 insurance_get_loan_section mt-5">
                <div className="col-lg-6 col-12 pr-lg-3 pr-0 pl-0">
                  <h4>Car Brand</h4>
                  <div className="text-center">
                    <InputGroup className="mb-0" size="lg">
                      <FormControl
                        placeholder="7"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        placeholder="Toyota..."
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-angle-down"
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="col-lg-6 col-12 pr-lg-3 pr-0 pl-0 mt-lg-0 mt-4">
                  <h4>Car Model</h4>
                  <div className="text-center">
                    <InputGroup className="mb-0" size="lg">
                      <FormControl
                        placeholder="7"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        placeholder="Corolla..."
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-angle-down"
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="row m-0 w-100 insurance_get_loan_section mt-4">
                <div className="col-lg-6 col-12 pr-lg-3 pr-0 pl-0">
                  <h4>Date of Registration</h4>
                  <div className="text-center">
                    <InputGroup className="mb-0" size="lg">
                      <FormControl
                        placeholder="7"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        placeholder="DD/MM/YY"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-angle-down"
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="col-lg-6 col-12 pr-lg-3 pr-0 pl-0 mt-lg-0 mt-4">
                  <h4>Driver’s Date Of Birth</h4>
                  <div className="text-center">
                    <InputGroup className="mb-0" size="lg">
                      <FormControl
                        placeholder="7"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        placeholder="DD/MM/YYYY"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-angle-down"
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="row m-0 w-100 insurance_get_loan_section mt-4">
                <div className="col-lg-6 col-12 pr-lg-3 pr-0 pl-0">
                  <h4>Date of Registration</h4>
                  <div className="text-center">
                    <InputGroup className="mb-0" size="lg">
                      <FormControl
                        placeholder="7"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        placeholder="10% onwards"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-angle-down"
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="row m-0 w-100 d-flex justify-content-center">
                <Button
                  variant="success"
                  className="insurance_get_free_quote_btn"
                  size="lg"
                  block
                >
                  Get your FREE Quote
                </Button>
              </div>
            </div>
          </div>
          <div className="col-1 p-0"></div>
        </div>
      </div>

      <section className="reviews-background reviews-background-insurance px-5 position-relative">
        <div className="whatsapp-container whatsapp_container_insurance">
          <img src="../images/img/whatsapp_logo_1_1.png" alt="" />
        </div>
        <div className="row m-0 align-items-center review-headline">
          <div className="col-lg-10 col-12 p-0">
            <h2 className="review-header review-header-insurance">
              Here’s What Our Customers Have To Say About Our Service...
            </h2>
          </div>
          <div className="col-2 text-right p-0">
            <p className="mb-0 see-all-review">See All Reviews</p>
          </div>
        </div>
        <div className="px-3">
          <Slider {...settings}>
            {[...Array(n)].map((v, i) => {
              return (
                <div
                  key={i}
                  className="col-12 carousel-cards review-card border-0"
                >
                  <div className="card-body">
                    <div className="row m-0 align-items-center">
                      <div className="col-2 pl-3 review-image"></div>
                      <div className="col-9 pr-0 review-name">
                        <h5 className="mb-1 text-left">King Jack</h5>
                        <p className="mb-0">Kingsmen Tailor</p>
                      </div>
                      <div className="col-1 pl-0 review-icon">
                        <i className="fab fa-facebook-square"></i>
                      </div>
                    </div>
                    <div className="row m-0 mt-3">
                      <div className="col-12 pl-2">
                        <p>
                          From start till the end, Joseph has been nothing but
                          patient, professional, accommodating, honest and
                          transparent. Kudoz to this young team Including Mark
                          and Wan...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="row m-0 align-items-center see_all_review_mobile">
            <div className="col-12 p-0">
              <h2 className="review-header">See All Reviews</h2>
            </div>
          </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default Insurance;
