import React from "react";
import { Button } from "react-bootstrap";
import ellipse from "../../images/icons/Ellipse.png";

const CreateListing1 = (props) => {
  return (
    <div className="details_background">
      <div className="row m-0 align-items-center back_button">
        <p className="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div className="row m-0 align-items-center justify-content-center details_background_2">
        <div className="col-lg-6 p-0">
          <div className="row m-0">
            <div className="col-lg p-0 pb-3">
              <h3 className="listing-head-1">Before we get started...</h3>
            </div>
          </div>

          <div className="row m-0">
            <div className="col-lg py-5 px-4 listing-inner-one">
              <div className="row m-0 mb-4">
                <div className="col-lg-6 col-8">
                  <h5 className="listing-head-2">Approx. time for completion:</h5>
                </div>
                <div className="col-lg-6 col-4">
                  <h5 className="listing-head-3">3-5 mins</h5>
                </div>
              </div>

              <div className="row m-0 mb-2">
                <div className="col-lg">
                  <h5 className="listing-head-2 text-left">You will need:</h5>
                </div>
              </div>

              <div className="row m-0 align-items-center mb-3">
                <div className="col-lg-1 col-2 p-0 pl-3">
                  <img src={ellipse} alt="" />
                </div>
                <div className="col-lg-11 col-10">
                  <p className="listing-head-4 mb-0">Vehicle details</p>
                </div>
              </div>

              <div className="row m-0 align-items-center mb-3">
                <div className="col-lg-1 col-2 p-0 pl-3">
                  <img src={ellipse} alt="" />
                </div>
                <div className="col-lg-11 col-10">
                  <p className="listing-head-4 mb-0">Ownership details</p>
                </div>
              </div>

              <div className="row m-0 align-items-center">
                <div className="col-lg-1 col-2 p-0 pl-3">
                  <img src={ellipse} alt="" />
                </div>
                <div className="col-lg-11 col-10">
                  <p className="listing-head-4 mb-0">PARF / COE details</p>
                </div>
              </div>

              <div className="row m-0 mt-5">
                <div className="col-lg-12">
                  <p className="listing-head-5">
                    You will not be able to edit your listing once it is up for
                    auction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 p-4 listing-btn align-items-center justify-content-center">
            <div className="col-lg-12 d-flex align-items-center justify-content-center">
              <Button onClick={() => props.setListing(2)}>Start</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing1;
