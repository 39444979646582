import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import "../../../css/finance.css";
import { useHistory } from "react-router-dom";

const CarLoanRoadTax = (props) => {
  const [slideValue, setSliderValue] = useState(1200);
  const [slideValue2, setSliderValue2] = useState(5);

  const [roadTaxPerYear, setRoadTaxPerYear] = useState(0);
  const [roadTaxPerSixMonth, setRoadTaxPerSixMonth] = useState(0);

  const [roadTax1, setroadTax1] = useState(0);
  const [roadTax2, setroadTax2] = useState(0);

  const [annualCharge1, setAnnualCharge1] = useState(0);
  const [annualCharge2, setAnnualCharge2] = useState(0);

  const [vehicleScheme, setVehcileSchemen] = useState("Old OPC");
  const [fuelScheme, setFuelScheme] = useState("Petrol");

  const [manufacturingYear, saveManufactureYear] = useState("");

  useEffect(() => {
    let roadTaxPerYear, roadTaxPerSixMonth;
    if (slideValue <= 600) {
      let value = 400 * 0.782;
      roadTaxPerSixMonth = Math.round(value / 2);
      roadTaxPerYear = roadTaxPerSixMonth * 2;
      setRoadTaxPerSixMonth(roadTaxPerSixMonth);
      setRoadTaxPerYear(roadTaxPerYear);
      setroadTax1(((roadTaxPerSixMonth * 75) / 100).toFixed(0));
      setroadTax2(((roadTaxPerYear * 75) / 100).toFixed(0));
    } else if (slideValue > 600 && slideValue <= 1000) {
      let value = [400 + 0.25 * (Number(slideValue) - 600)] * 0.782;
      roadTaxPerSixMonth = Math.round(value / 2);
      roadTaxPerYear = roadTaxPerSixMonth * 2;
      setRoadTaxPerSixMonth(roadTaxPerSixMonth);
      setRoadTaxPerYear(roadTaxPerYear);
      setroadTax1(((roadTaxPerSixMonth * 75) / 100).toFixed(0));
      setroadTax2(((roadTaxPerYear * 75) / 100).toFixed(0));
    } else if (slideValue > 1000 && slideValue <= 1600) {
      let value = [500 + 0.75 * (Number(slideValue) - 1000)] * 0.782;
      roadTaxPerSixMonth = Math.round(value / 2);
      roadTaxPerYear = roadTaxPerSixMonth * 2;
      setRoadTaxPerSixMonth(roadTaxPerSixMonth);
      setRoadTaxPerYear(roadTaxPerYear);
      setroadTax1(((roadTaxPerSixMonth * 75) / 100).toFixed(0));
      setroadTax2(((roadTaxPerYear * 75) / 100).toFixed(0));
    } else if (slideValue > 1600 && slideValue <= 3000) {
      let value = [950 + 1.5 * (Number(slideValue) - 1600)] * 0.782;
      roadTaxPerSixMonth = Math.round(value / 2);
      roadTaxPerYear = roadTaxPerSixMonth * 2;
      setRoadTaxPerSixMonth(roadTaxPerSixMonth);
      setRoadTaxPerYear(roadTaxPerYear);
      setroadTax1(((roadTaxPerSixMonth * 75) / 100).toFixed(0));
      setroadTax2(((roadTaxPerYear * 75) / 100).toFixed(0));
    } else {
      let value = [3050 + 2.0 * (Number(slideValue) - 3000)] * 0.782;
      roadTaxPerSixMonth = Math.round(value / 2);
      roadTaxPerYear = roadTaxPerSixMonth * 2;
      setRoadTaxPerSixMonth(roadTaxPerSixMonth);
      setRoadTaxPerYear(roadTaxPerYear);
      setroadTax1(((roadTaxPerSixMonth * 75) / 100).toFixed(0));
      setroadTax2(((roadTaxPerYear * 75) / 100).toFixed(0));
    }

    if (slideValue2) {
      let annualChargeCalc1 = 0;
      let annualChargeCalc2 = 0;
      if (slideValue2 > 14) {
        annualChargeCalc1 = ((50 * roadTax1) / 100).toFixed(0);
        annualChargeCalc2 = ((50 * roadTax2) / 100).toFixed(0);
      } else if (slideValue2 > 13) {
        annualChargeCalc1 = ((40 * roadTax1) / 100).toFixed(0);
        annualChargeCalc2 = ((40 * roadTax2) / 100).toFixed(0);
      } else if (slideValue2 > 12) {
        annualChargeCalc1 = ((30 * roadTax1) / 100).toFixed(0);
        annualChargeCalc2 = ((30 * roadTax2) / 100).toFixed(0);
      } else if (slideValue2 > 11) {
        annualChargeCalc1 = ((20 * roadTax1) / 100).toFixed(0);
        annualChargeCalc2 = ((20 * roadTax2) / 100).toFixed(0);
      } else if (slideValue2 > 10) {
        annualChargeCalc1 = ((10 * roadTax1) / 100).toFixed(0);
        annualChargeCalc2 = ((10 * roadTax2) / 100).toFixed(0);
      }
      setAnnualCharge1(annualChargeCalc1);
      setAnnualCharge2(annualChargeCalc2);
    }
  }, [slideValue, slideValue2]);

  const setSliderValueFunc = (e) => {
    const scale = (num, in_min, in_max, out_min, out_max) => {
      return (
        ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
      );
    };
    const value = +e.target.value;
    const label = e.target.nextElementSibling;

    const rangeWidth = getComputedStyle(e.target).getPropertyValue("width");
    const labelWidth = getComputedStyle(label).getPropertyValue("width");
    // remove px
    const numWidth = +rangeWidth.substring(0, rangeWidth.length - 2);
    const numLabelWidth = +labelWidth.substring(0, labelWidth.length - 2);
    const max = +e.target.max;
    const min = +e.target.min;
    const left =
      value * (numWidth / max) -
      numLabelWidth / 2 +
      scale(value, min, max, 10, -30);
    label.style.left = left < 40 ? `${left + -20}px` : `${left + 12}px`;
    label.innerHTML = value;
    setSliderValue(e.target.value);
  };

  const setSliderValueFunc2 = (e) => {
    const scale = (num, in_min, in_max, out_min, out_max) => {
      return (
        ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
      );
    };
    const value = +e.target.value;
    const label = e.target.nextElementSibling;
    const rangeWidth = getComputedStyle(e.target).getPropertyValue("width");
    const labelWidth = getComputedStyle(label).getPropertyValue("width");
    // remove px
    const numWidth = +rangeWidth.substring(0, rangeWidth.length - 2);
    const numLabelWidth = +labelWidth.substring(0, labelWidth.length - 2);
    const max = +e.target.max;
    const min = +e.target.min;
    const left =
      value * (numWidth / max) -
      numLabelWidth / 2 +
      scale(value, min, max, 10, -10);
    label.style.left =
      left < 45
        ? `${left + 45}px`
        : left > 332
        ? `${left + 25}px`
        : `${left + 35}px`;
    label.innerHTML = value + " years";
    setSliderValue2(e.target.value);
  };

  const saveManufactureYearFunc = (value1) => {
    saveManufactureYear(value1);
    let d = new Date();
    let calcVehicleAge = Number(d.getFullYear()) - Number(value1);
    setSliderValue2(calcVehicleAge);

    let e = document.getElementById("myRange2");
    const scale = (num, in_min, in_max, out_min, out_max) => {
      return (
        ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
      );
    };
    const value = +calcVehicleAge;
    const label = e.nextElementSibling;
    const rangeWidth = getComputedStyle(e).getPropertyValue("width");
    const labelWidth = getComputedStyle(label).getPropertyValue("width");
    // remove px
    const numWidth = +rangeWidth.substring(0, rangeWidth.length - 2);
    const numLabelWidth = +labelWidth.substring(0, labelWidth.length - 2);
    const max = +e.max;
    const min = +e.min;
    const left =
      value * (numWidth / max) -
      numLabelWidth / 2 +
      scale(value, min, max, 10, -10);
    if (value > 0) {
      label.style.left =
      left < 45
        ? `${left + 20}px`
        : left > 332
        ? `${left + 25}px`
        : `${left + 35}px`;
      label.innerHTML = value + " years";
    }
  };

  let history = useHistory();
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid car-loan-cont car-loan-cont-road-tax">
        <div className="calc_road_tax_area">
          <div className="col-lg-5 col-md-5 col-12 pl-lg-5 pl-md-5 p-0 mt-lg-0 mt-md-0 mt-4 d-lg-none d-md-none d-block road_tax_calc_mobile_view">
            <div className="road_tax_payable_calc p-4">
              <p className="text-center">Your Total Road Tax payable is:</p>
              <div className="row m-0">
                <div className="col-1 p-0"></div>
                <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                  <h3>
                    <sup>$</sup> {roadTaxPerSixMonth}
                  </h3>
                  <span>per 6 months</span>
                </div>
                <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                  <h3>
                    <sup>$</sup> {roadTaxPerYear}
                  </h3>
                  <span>per year</span>
                </div>
                <div className="col-1 p-0"></div>
              </div>

              <div className="row m-0 mt-5">
                <div className="col-1 p-0"></div>
                <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 blue_labels">
                    <sup className="pb-2">$</sup> {roadTax1}
                  </p>
                </div>
                <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                  <span>Road Tax</span>
                </div>
                <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 blue_labels">
                    <sup className="pb-2">$</sup> {roadTax2}
                  </p>
                </div>
                <div className="col-1 p-0"></div>
              </div>

              <div className="row m-0 mt-4">
                <div className="col-1 p-0"></div>
                <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 blue_labels">
                    <sup className="pb-2">$</sup> {annualCharge1}
                  </p>
                </div>
                <div className="px-4 text-center col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                  <span>Annual Road Tax Surcharge</span>
                </div>
                <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 blue_labels">
                    <sup className="pb-2">$</sup> {annualCharge2}
                  </p>
                </div>
                <div className="col-1 p-0"></div>
              </div>
            </div>

            <h2>
              Let Carki do a free tax review for you to save costs on your
              vehicle and road tax today!
            </h2>

            <div className="d-flex justify-content-center align-items-center">
              <button
                className="get_started_btn"
                onClick={() =>
                  props.history.push({
                    pathname: "/lead-squeze",
                    state: {
                      vehicleEngineCC: slideValue,
                      vehicleAge: slideValue2,
                      vehicleScheme,
                      fuelScheme,
                      roadTaxSemiAnnual: roadTax1,
                      roadTaxAnnual: roadTax2,
                      roadTaxSChargeSemiAnnual: annualCharge1,
                      roadTaxSChargeAnnual: annualCharge2,
                    },
                  })
                }
              >
                Get Started
              </button>
            </div>
          </div>
        </div>

        <div className="row car-loan-cont-row car-loan-cont-row-road">
          <button onClick={() => history.push("/car-loan-new")}>
            PARF Car
          </button>
          <button onClick={() => history.push("/car-loan-used")}>
            COE Car
          </button>
          <button className="active">Road Tax</button>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="cal-section-two">
            <div className="row cal-section-row">
              <h1>Calculate Your Car Loan For Free</h1>
              <p>
                Input your vehicle details below to know how much you have to
                pay in road tax!{" "}
              </p>
            </div>
            <br />

            <div className="row m-0 calc_road_tax_area mt-5">
              <div className="col-1"></div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="" style={{ marginBottom: 80 }}>
                  <p className="input_labels">Vehicle’s Engine Capacity (CC)</p>
                  <div class="range-container pl-3">
                    <div className="left_square"></div>
                    <input
                      type="range"
                      onChange={(e) => setSliderValueFunc(e)}
                      value={slideValue}
                      min="800"
                      step="100"
                      max="6600"
                      class="slider"
                      id="myRange"
                    />
                    <label className="label1" for="range">
                      {slideValue}
                    </label>
                    <div className="right_square"></div>
                  </div>
                </div>
                <div className="calc-cont">
                  <div className="calc-row-one calc-common input_manufacture_year_calc">
                    <div className="font-weight-bold">
                      <p className="input_labels mb-lg-0 pr-2">
                        Manufacture Year
                      </p>
                    </div>
                    <div className="calc-input input_manufacture_year">
                      <div className="input-group ">
                        <input
                          type="pincode"
                          maxLength="4"
                          className="form-control text-blank"
                          placeholder="0"
                          aria-label="Username"
                          onBlur={(e) =>
                            saveManufactureYearFunc(e.target.value)
                          }
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5" style={{ marginBottom: 80 }}>
                  <p className="input_labels">Vehicle’s Age</p>
                  <div class="range-container pl-3">
                    <div className="left_square"></div>
                    <input
                      type="range"
                      onChange={(e) => setSliderValueFunc2(e)}
                      value={slideValue2}
                      min="0"
                      max="30"
                      step="1"
                      disabled={true}
                      class="slider"
                      id="myRange2"
                    />
                    <label className="label2" id="myRange2white" for="range">
                      {slideValue2} years
                    </label>
                    <div className="right_square"></div>
                  </div>
                </div>

                <div className="mt-5 vehicle_scheme">
                  <div className="calc-cont">
                    <div className="calc-row-one">
                      <div className="text-left">
                        <p className="input_labels">Vehicle’s Scheme</p>
                      </div>
                      <div className="calc-input-buttons-scheme">
                        <button
                          className={`btn_1 ${
                            vehicleScheme === "Normal Car" && "active"
                          }`}
                          onClick={() => setVehcileSchemen("Normal Car")}
                        >
                          Normal Car (non-OPC)
                        </button>
                        <button
                          className={`btn_2 ${
                            vehicleScheme === "Old OPC" && "active"
                          }`}
                          onClick={() => setVehcileSchemen("Old OPC")}
                        >
                          Old OPC
                        </button>
                        <button
                          className={`btn_3 ${
                            vehicleScheme === "Revised OPC" && "active"
                          }`}
                          onClick={() => setVehcileSchemen("Revised OPC")}
                        >
                          Revised OPC
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 fuel_scheme">
                  <div className="calc-cont">
                    <div className="calc-row-one w-100">
                      <div className="text-left">
                        <p className="input_labels">Fuel Scheme</p>
                      </div>
                      <div className="calc-input-buttons-scheme">
                        <button
                          className={`btn_1 ${
                            fuelScheme === "Petrol" && "active"
                          }`}
                          onClick={() => setFuelScheme("Petrol")}
                        >
                          Petrol
                        </button>
                        <button
                          className={`btn_2 ${
                            fuelScheme === "Diesel" && "active"
                          }`}
                          onClick={() => setFuelScheme("Diesel")}
                        >
                          Diesel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="align-items-center justify-content-center d-lg-none d-md-none d-block">
                  <div className="cal-section-info cal-section-info-mobile pb-4 m-0">
                    <p>
                      Disclaimer: Our calculators are used as tools to help you
                      to get a better sense of your financial commitments when
                      buying a car. Our figures are based on MAS restrictions on
                      motor vehicle loans by financial institutions. It does not
                      warrant the accuracy, adequacy or completeness of the
                      figures presented. Carki Pte Ltd expressly disclaims
                      liability for errors or omissions.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-5 col-12 pl-lg-5 pl-md-5 p-0 mt-lg-0 mt-md-0 mt-4 d-lg-block d-md-block d-none road_tax_calc_web_view">
                <div className="road_tax_payable_calc p-4">
                  <p className="text-center">Your Total Road Tax payable is:</p>
                  <div className="row m-0">
                    <div className="col-1 p-0"></div>
                    <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                      <h3>
                        <sup>$</sup> {roadTaxPerSixMonth}
                      </h3>
                      <span>per 6 months</span>
                    </div>
                    <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                      <h3>
                        <sup>$</sup> {roadTaxPerYear}
                      </h3>
                      <span>per year</span>
                    </div>
                    <div className="col-1 p-0"></div>
                  </div>

                  <div className="row m-0 mt-5">
                    <div className="col-1 p-0"></div>
                    <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                      <p className="mb-0 blue_labels">
                        <sup className="pb-2">$</sup> {roadTax1}
                      </p>
                    </div>
                    <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                      <span>Road Tax</span>
                    </div>
                    <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                      <p className="mb-0 blue_labels">
                        <sup className="pb-2">$</sup> {roadTax2}
                      </p>
                    </div>
                    <div className="col-1 p-0"></div>
                  </div>

                  <div className="row m-0 mt-4">
                    <div className="col-1 p-0"></div>
                    <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                      <p className="mb-0 blue_labels">
                        <sup className="pb-2">$</sup> {annualCharge1}
                      </p>
                    </div>
                    <div className="px-4 text-center col-5 p-0 d-flex flex-column justify-content-center align-items-center">
                      <span>Annual Road Tax Surcharge</span>
                    </div>
                    <div className="col-2 p-0 d-flex flex-column justify-content-center align-items-center">
                      <p className="mb-0 blue_labels">
                        <sup className="pb-2">$</sup> {annualCharge2}
                      </p>
                    </div>
                    <div className="col-1 p-0"></div>
                  </div>
                </div>

                <h2>
                  Let Carki do a free tax review for you to save costs on your
                  vehicle and road tax today!
                </h2>

                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="get_started_btn"
                    onClick={() =>
                      props.history.push({
                        pathname: "/lead-squeze",
                        state: {
                          vehicleEngineCC: slideValue,
                          vehicleAge: slideValue2,
                          vehicleScheme,
                          fuelScheme,
                          roadTaxSemiAnnual: Number(roadTax1),
                          roadTaxAnnual: Number(roadTax2),
                          roadTaxSChargeSemiAnnual: Number(annualCharge1),
                          roadTaxSChargeAnnual: Number(annualCharge2),
                        },
                      })
                    }
                  >
                    Get Started
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>

        <br />

        <div className="align-items-center justify-content-center d-lg-flex d-md-flex d-none">
          <div className="cal-section-info-two">
            <p>
              Disclaimer: Our calculators are used as tools to help you to get a
              better sense of your financial commitments when buying a car. Our
              figures are based on MAS restrictions on motor vehicle loans by
              financial institutions. It does not warrant the accuracy, adequacy
              or completeness of the figures presented. Carki Pte Ltd expressly
              disclaims liability for errors or omissions.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CarLoanRoadTax;
