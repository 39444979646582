import React, { Component, useState } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import "../../../css/finance.css";
import { useHistory } from "react-router-dom";
import Dropdown from "../../../components/common/dropdown";
import { FormControl, InputGroup } from "react-bootstrap";

const CarLoanUsed = () => {
  let history = useHistory();
  let currentDate = new Date();
  const [loanAmt, setLoanAmount] = useState(0);
  const [intRate, setIntRate] = useState(2.88);
  const [years, setYears] = useState("0");
  const [months, setMonths] = useState("0");
  const [showDropdownForYears, setshowDropdownForYears] = useState(false);
  const [showPaybleAmtBox, setShowPaybleAmtBox] = useState(false);

  const [payable_amt, set_payable_amt] = useState(0);
  const [totalInt, setTotalInterest] = useState(0);

  // const calculateCarLoan = () => {
  //   if (loanAmt < 10000) {
  //     setShowPaybleAmtBox(false);
  //     setshowDropdownForYears(false);
  //   } else {
  //     let TotalInterest =
  //       Number(loanAmt) * ((Number(intRate) / 100) * Number(years));
  //     // let TotalLoan = TotalInterest + Number(loanAmt);
  //     // let MonthlyInstallment = TotalLoan / Number(years) / 12;
  //     let TotalLoan = TotalInterest + Number(loanAmt);
  //     setTotalInterest(TotalInterest.toFixed(0))
  //     let totalLoanTenure = (Number(years) * 12) + Number(months);

  //     let MonthlyInstallment = TotalLoan / totalLoanTenure;
  //     set_payable_amt(MonthlyInstallment.toFixed(0));
  //     setShowPaybleAmtBox(true);
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  const calculateCarLoan = () => {
    if (loanAmt < 10000) {
      setShowPaybleAmtBox(false);
      setshowDropdownForYears(false);
    } else {
      let TotalInterest = (Number(loanAmt) * (Number(intRate) /100) * (Number(years) + (Number(months) / 12)));
      setTotalInterest(TotalInterest.toFixed(0));
      let TotalLoan = TotalInterest + Number(loanAmt);

      let totalLoanTenure = Number(years) * 12 + Number(months);

      let MonthlyInstallment = TotalLoan / totalLoanTenure;
      set_payable_amt(MonthlyInstallment.toFixed(2));
      setShowPaybleAmtBox(true);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const resetFields = () => {
    setLoanAmount(0);
    setIntRate(2.88);
    setYears("0");
    setMonths("0");
    setShowPaybleAmtBox(false);
    setshowDropdownForYears(false);
    set_payable_amt(0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const addCOEexpiryDate = (e) => {
    let findCOEDate = new Date(e.target.value);
    let currentDate = new Date();
    let findMonthsDiff;
    if(findCOEDate.getTime() > currentDate.getTime()) {
      findMonthsDiff = (findCOEDate.getFullYear() - currentDate.getFullYear()) * 12 + (findCOEDate.getMonth() - currentDate.getMonth());
    }
    else {
      findMonthsDiff = (findCOEDate.getFullYear() - currentDate.getFullYear()) * 12 + Math.abs((findCOEDate.getMonth() - currentDate.getMonth()));
    }
    if(findMonthsDiff < -3){
      findMonthsDiff = findMonthsDiff + 3;
    }
    if(findMonthsDiff > 3){
      findMonthsDiff = findMonthsDiff - 3;
    }
    let diffYear = Math.trunc((findMonthsDiff / 12));
    let diffMonths = (diffYear * 12) - findMonthsDiff;
    diffMonths = Math.abs(diffMonths);
    diffYear = Math.abs(diffYear);
    setYears(String(diffYear));
    setMonths(String(diffMonths));
  }

  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid car-loan-cont finance-container">
        {showPaybleAmtBox ? (
          <div>
            <div className="row m-0 calculated_loan_box d-lg-none d-md-none d-flex">
              <div className="col-12 row m-0 align-items-center p-0">
                <div className="col-8 pl-4 estimate_payable text-center">
                  Total Interest Accrued::
                </div>
                <div className="col-4 estimate_payable_amt text-center">
                  ${totalInt}
                </div>
              </div>
              <div className="col-12 row m-0 align-items-center p-0">
                <div className="col-8 pl-4 estimate_payable text-center">
                  Your estimated payable quantum per month is:
                </div>
                <div className="col-4 estimate_payable_amt text-center">
                  ${payable_amt}
                </div>
              </div>
              <div className="col-12 row m-0 align-items-center p-0">
                <div className="col-7 pl-4 estimate_payable text-center">
                  For a loan period of:
                </div>
                <div className="col-5 pr-0 estimate_payable_amt text-center">
                  {years} years
                  <div>{months} months</div>
                  <div>({(Number(years) * 12) + Number(months)} months)</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row car-loan-cont-row">
          <button onClick={() => history.push("/car-loan-new")}>
            PARF Car
          </button>
          <button className="active">COE Car</button>
          <button onClick={() => history.push("/car-loan-roadTax")}>
            Road Tax
          </button>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="cal-section-two">
            <div className="row cal-section-row">
              <h1>Calculate Your Car Loan For Free</h1>
              <p>Estimate the amount of money you have to pay in instalments</p>
              <p className="orng">
                Carki offers as low as 2.88% interest for your vehicle loans!
              </p>
            </div>
            {showPaybleAmtBox ? (
              <div className="calculated_loan_box d-lg-block d-md-block d-none">
                <div className="row m-0 mt-4">
                  <div className="col-1 p-0"></div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable" style={{ textAlign: "end" }}>
                      Total Interest Accrued::
                    </div>
                    <div className="col-4 estimate_payable_amt">
                      ${totalInt}
                    </div>
                  </div>
                  <div className="col-6 row m-0 align-items-center p-0">
                    <div className="col-5 estimate_payable">
                      For a loan period of:
                    </div>
                    <div className="col-6 text-center estimate_payable_amt mt-2">
                    <div>{years} years {months} months</div>
                      <div className="mt-3 text-center" style={{ whiteSpace: 'nowrap' }}>({(Number(years) * 12) + Number(months)} months)</div>
                    </div>
                  </div>
                  <div className="col-0 p-0"></div>
                </div>
                <div className="row m-0 mb-5 mt-4">
                  <div className="col-1 p-0"></div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable">
                      Your estimated payable quantum per month is:
                    </div>
                    <div className="col-4 estimate_payable_amt">
                      ${payable_amt}
                    </div>
                  </div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-5 estimate_payable">

                    </div>
                    <div className="col-4 estimate_payable_amt">

                    </div>
                  </div>
                  <div className="col-1 p-0"></div>
                </div>
              </div>
            ) : null}
         
            <div className="d-lg-block d-none mt-4">
              <div className="row m-0">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="row m-0">
                    <div className="col-6">
                      <p className="input-labels">Loan Amount</p>
                      <div className="calc-input">
                        <div className="input-group">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={loanAmt}
                            onChange={(e) => setLoanAmount(e.target.value)}
                          />
                        </div>
                        {loanAmt
                          ? loanAmt < 10000 && (
                            <p className="error_logs">
                              Loan amount needs to be minimum $10,000
                            </p>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <p className="input-labels">Interest Rate
                        (% p.a.)</p>
                      <div className="calc-input">
                        <div className="input-group">
                          {/* <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                          </InputGroup.Prepend> */}
                          <FormControl
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            placeholder="2.88"
                            aria-label="Text input with segmented dropdown button"
                            value={intRate}
                            onChange={(e) => setIntRate(e.target.value)}
                          />
                          {/* <InputGroup.Append>
                            <InputGroup.Text id="basic-addon1">
                              <i
                                style={{ color: "white" }}
                                className="fas fa-angle-down"
                              ></i>
                            </InputGroup.Text>
                          </InputGroup.Append> */}
                        </div>
                        {/* {loanAmt
                          ? loanAmt < 10000 && (
                            <p className="error_logs">
                              Loan amount needs to be minimum $10,000
                            </p>
                          )
                          : null} */}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 my-4">
                    <div className="col-6">
                      <p className="input-labels">COE Expiry Date</p>
                      <div className="calc-input">
                        <div className="input-group">
                          <FormControl
                            type="text"
                            className="form-control"
                            // value={carPrice}
                            min={`${currentDate.getFullYear() - 7}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`}
                            type="date"
                            format="yyyy-MM-dd"
                            onChange={(e) => addCOEexpiryDate(e)}
                            placeholder="DD/MM/YYYY"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <InputGroup className="mb-0" size="lg">

                      </InputGroup>
                    </div>
                    <div className="col-6 d-flex flex-column justify-content-center">
                      <p className="input-labels">Loan Tenure (COE Car Offset 3 months)</p>
                      <div className="calc-input">
                        <div
                          className="input-group cal-size-a input-loan-car-used"
                        // onClick={() =>
                        //   setshowDropdownForYears(!showDropdownForYears)
                        // }
                        >
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="7"
                                value={years}
                                readOnly
                                style={{ width: "50px" }}
                                aria-label="Text input with segmented dropdown button"
                              />
                              <p className="font-weight-bold mb-0 pl-3">Years</p>
                            </div>
                            <div className="d-flex align-items-center ml-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="7"
                                value={months}
                                readOnly
                                style={{ width: "50px" }}
                                aria-label="Text input with segmented dropdown button"
                              />
                              <p className="font-weight-bold mb-0 pl-3">Months</p>
                            </div>
                          </div>
                        </div>
                        {showDropdownForYears && (
                          <Dropdown
                            setValue={(v) => {
                              setYears(v);
                              setshowDropdownForYears(false);
                            }}
                            elements={["1", "2", "3", "4", "5", "6", "7"]}
                          />
                        )}
                      </div>
                      {/* <InputGroup
                        className="mb-0 pointer"
                        size="lg"
                      // onClick={() => setshowDropdownForYears(!showDropdownForYears)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="7"
                              value={years}
                              readOnly
                              style={{ width: "50px" }}
                              aria-label="Text input with segmented dropdown button"
                            />
                            <p className="font-weight-bold mb-0 pl-3">Years</p>
                          </div>
                          <div className="d-flex align-items-center ml-4">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="7"
                              value={months}
                              readOnly
                              style={{ width: "50px" }}
                              aria-label="Text input with segmented dropdown button"
                            />
                            <p className="font-weight-bold mb-0 pl-3">Months</p>
                          </div>
                        </div>
                      </InputGroup> */}
                    </div>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
            </div>

            <div className="d-lg-none d-block">
              <div className="calc-cont">
                <div className="calc-row-one calc-common">
                  <div className="cal-name">
                    <p>Loan Amount</p>
                  </div>
                  <div className="calc-input">
                    <div className="input-group cal-size-a">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                      </div>
                      <input
                        type="number" onWheel={(e) => e.target.blur()}
                        className="form-control"
                        placeholder="0"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={loanAmt}
                        onChange={(e) => setLoanAmount(e.target.value)}
                      />
                    </div>
                    {loanAmt
                      ? loanAmt < 10000 && (
                        <p className="error_logs">
                          Loan amount needs to be minimum $10,000
                        </p>
                      )
                      : null}
                  </div>
                </div>
              </div>
              <br />
              <div className="calc-cont">
                <div className="calc-row-one calc-common w-100" style={{padding: '0px 12px'}}>
                  <div className="cal-name text-left">
                    <p>COE Expiry Date</p>
                  </div>
                  <div className="calc-input w-100">
                    <div className="input-group cal-size-a">
                      <input
                        type="text"
                        className="form-control"
                        // value={carPrice}
                        type="date"
                        min={`${currentDate.getFullYear() - 7}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`}
                        format="yyyy-MM-dd"
                        onChange={(e) => addCOEexpiryDate(e)}
                        placeholder="DD/MM/YYYY"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="calc-cont calc-cont-loan">
                <div className="calc-row-one calc-common loan-tenure-car-used">
                  <div className="cal-name">
                    <p className="mb-1 text-left">Loan Tenure <br /></p>
                    <p>(COE Car Offset 3 months)</p>
                  </div>

                  <div className="d-flex calc-row-loan-data">
                    <div className="calc-input">
                      <div
                        className="input-group cal-size-a input-loan-car-used"
                      // onClick={() =>
                      //   setshowDropdownForYears(!showDropdownForYears)
                      // }
                      >
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="7"
                              value={years}
                              readOnly
                              style={{ width: "50px" }}
                              aria-label="Text input with segmented dropdown button"
                            />
                            <p className="font-weight-bold mb-0 pl-3">Years</p>
                          </div>
                          <div className="d-flex align-items-center ml-4">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="7"
                              value={months}
                              readOnly
                              style={{ width: "50px" }}
                              aria-label="Text input with segmented dropdown button"
                            />
                            <p className="font-weight-bold mb-0 pl-3">Months</p>
                          </div>
                        </div>
                      </div>
                      {showDropdownForYears && (
                        <Dropdown
                          setValue={(v) => {
                            setYears(v);
                            setshowDropdownForYears(false);
                          }}
                          elements={["1", "2", "3", "4", "5", "6", "7"]}
                        />
                      )}
                    </div>

                  </div>
                </div>
              </div>
              <br />
              <div className="calc-cont">
                <div className="calc-row-one calc-common w-100" style={{padding: '0px 12px'}}>
                  <div className="cal-name text-left">
                    <p>Interest Rate (% p.a.)</p>
                  </div>
                  <div className="calc-input w-100">
                    <div className="input-group cal-size-a">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="2.88"
                        aria-label="Text input with segmented dropdown button"
                        value={intRate}
                        onChange={(e) => setIntRate(e.target.value)}
                      />
                      {/* <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <hr />
            <br />

            <div className="calc-two-info">
              <p className="calc-text-blue">
                Additional Information for Carki Loans
              </p>
              <p className="clac-text-black">
                Maximum loan value of 90% of car price.
              </p>
              <p className="clac-text-black">
                Minimum downpayment value of 10% of car price.
              </p>
              <p className="clac-text-black">Minimum loan amount: $10,000</p>
              <p className="clac-text-black">Maximum loan tenure: 7 years</p>
            </div>
            <div className="align-items-center justify-content-center d-lg-none d-md-none d-block">
              <div className="cal-section-info cal-section-info-mobile pb-4">
                <p>
                  Disclaimer: Our calculators are used as tools to help you to
                  get a better sense of your financial commitments when buying a
                  car. Our figures are based on MAS restrictions on motor
                  vehicle loans by financial institutions. It does not warrant
                  the accuracy, adequacy or completeness of the figures
                  presented. Carki Pte Ltd expressly disclaims liability for
                  errors or omissions.
                </p>
              </div>
            </div>
            <div className="calc-cont">
              <div className="calc-row-one calc-common submit-button">
                <div className="cal-name d-lg-block d-md-block d-none">
                  <p></p>
                </div>
                <div className="calc-input-buttons submit-button-btn">
                  <button
                    className="percent-30"
                    onClick={() => calculateCarLoan()}
                  >
                    Calculate Car Loan Now
                  </button>
                </div>
                <div className="calc-input-buttons submit-button-reset">
                  <button className="percent-30" onClick={() => resetFields()}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="align-items-center justify-content-center d-lg-flex d-md-flex d-none">
          <div className="cal-section-info-two">
            <p>
              Disclaimer: Our calculators are used as tools to help you to get a
              better sense of your financial commitments when buying a car. Our
              figures are based on MAS restrictions on motor vehicle loans by
              financial institutions. It does not warrant the accuracy, adequacy
              or completeness of the figures presented. Carki Pte Ltd expressly
              disclaims liability for errors or omissions.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CarLoanUsed;
