import React from "react";
import { Link } from "react-router-dom";
import "../../css/common_css/common.css";
import logo from "../../images/img/Carki_Logo_New.png";

const CarBackground = () => {
  return (
    <div className="background d-flex flex-column align-items-center justify-content-center">
      <Link to="/"><img src={logo} alt="Cars image" /></Link>
    </div>
  );
};

export default CarBackground;
