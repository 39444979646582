import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import CarBackground from "../../components/common/carBackground";
import "../../css/login_register.css";
import { LoginApi, MemberLoginApi } from "../../utils/apiUrls";
import { toast } from "react-toastify";
import TabsComponent from "../../components/common/Tab";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("Partner");

  const [ErrorLabel, setErrorLabel] = useState(false);

  const submitForm = () => {
    const obj = {
      email,
      password,
    };
    if (userType === "Partner") {
      LoginApi(obj)
        .payload.then((res) => {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("loggedInUserDetails", JSON.stringify(res.data));
          props.history.push("/partner-dashboard");
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    } else {
      MemberLoginApi(obj)
        .payload.then((res) => {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("loggedInUserDetails", JSON.stringify(res.data));
          props.history.push("/member-homepage");
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    }
  };

  return (
    <div className="row m-0">
      <div className="col-12 col-lg-7 p-0">
        <CarBackground />
      </div>
      <div className="col-12 col-lg-5 p-0 dark-background">
        <div className="row m-0">
          <div className="col-lg-2 col-1 p-0"></div>
          <div className="col-lg-8 col-10 p-0">
            <div className="row m-0 row_1">
              <div className="col-6 p-0 d-flex justify-content-center">
                <Button
                  variant="primary"
                  onClick={() => props.history.push("/login")}
                  className="btns prmy_btn"
                >
                  Log in
                </Button>
              </div>
              <div className="col-6 p-0 d-flex justify-content-center">
                <Button
                  variant="outline-primary"
                  onClick={() => props.history.push("/register")}
                  className="btns outline_btn"
                >
                  Create account
                </Button>
              </div>
            </div>

            {ErrorLabel && (
              <div className="row m-0 row_2">
                <div className="col-12 p-0">
                  <p className="mb-0 mt-5 message_label">
                    We do not recognize your email or password.
                    <br /> Please try again.
                  </p>
                </div>
              </div>
            )}

            {!ErrorLabel &&
              props.location.state &&
              props.location.state ===
                "Your account has been successfully registered! Try signing in to Carki." && (
                <div className="row m-0 row_2">
                  <div className="col-12 p-0">
                    <p className="mb-0 mt-5 message_label_sucess">
                      Your account has been successfully registered!
                      <br />
                      Try signing in to Carki.
                    </p>
                  </div>
                </div>
              )}

            {!ErrorLabel &&
              props.location.state &&
              props.location.state ===
                "Your password has been successfully changed! Try signing in to Carki." && (
                <div className="row m-0 row_2">
                  <div className="col-12 p-0">
                    <p className="mb-0 mt-5 message_label_sucess">
                      Your password has been successfully changed!
                      <br />
                      Try signing in to Carki.
                    </p>
                  </div>
                </div>
              )}

            <div className="row m-0 row_3">
              <div className="col-12 p-0">
                <p className="mb-0 heading_label">
                  Log in{" "}
                  <span>
                    <Link className="forgot_pwd_link" to="/register">
                      / Create account
                    </Link>
                  </span>
                </p>
              </div>
            </div>

            <div className="row m-0 row_4">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Email</label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                  />
                </InputGroup>
              </div>
            </div>
            <div className="row m-0 row_4 pt-1">
              <div className="col-12 p-0">
                <label htmlFor="basic-url">Password</label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                  />
                </InputGroup>
              </div>
            </div>

            {/* <div className="row m-0 row_4 pt-1 my-4">
              <div className="col-12 p-0">
                <TabsComponent
                  userType={userType}
                  setUserType={(type) => setUserType(type)}
                />
              </div>
            </div> */}

            <div className="row m-0 row_5 pt-1">
              <div className="col-6 p-0 d-flex align-items-center">
                <input type="checkbox" value="" />
                <p className="mb-0 pl-2 keep_signed_text">Keep me signed in!</p>
              </div>
              <div className="col-6 p-0 d-flex justify-content-end">
                <Link className="forgot_pwd_link" to="/partner-change-password">
                  Forgot password?
                </Link>
              </div>
            </div>

            <div className="row m-0 row_6">
              <div className="col-12 p-0 d-flex justify-content-center mt-5">
                <Button
                  variant="outline-primary"
                  onClick={() => submitForm()}
                  className="btns login_btn"
                >
                  Log in
                </Button>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-1 p-0"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
