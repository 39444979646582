import axios from "axios";

const CallApi = async (options = {}) => {
  const headerOptions = {
    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };

  return new Promise((resolve, reject) => {
    if (options.multipart) {
      headerOptions["content-type"] = "multipart/form-data";
    }
    console.log("AXIOS WORKING !!");
    axios(Object.assign(options, { headers: headerOptions }))
      .then((response) => {
        if (response.errors) {
          reject(response.errors);
        }
        resolve(Object.assign(response.data, { requestData: options.data }));
      })
      .catch((error) => {
        // if (error.response && error.response.status === 401) {
        //   removeItemValue();
        // }

        if (error.response) {
          return reject(
            Object.assign(error.response, { requestData: options.data })
          );
        }
      });
  });
};

export default CallApi;
