import React, { useEffect, useState } from "react";
import "../../css/sellSteps.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import bestPrice1 from "../../images/svg-icons/price_1.svg";
import info1 from "../../images/svg-icons/car_info_1.svg";
import waitingPeriod1 from "../../images/svg-icons/waiting_1.svg";
import confirmDetails from "../../images/icons/phone_1.png";
import { useHistory } from "react-router-dom";
import { submiReqFormData } from "../../utils/apiUrls";
import { toast } from "react-toastify";

const SellStep1 = (props) => {
  return (
    <div class="jumbotron step-1 stepCommonPro">
      <div
        id="carouselExampleControls"
        class="carousel slide stepCarousel"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="jumbo-link-s1">
            <a href="#">
              <b>Vehicle Info</b>
            </a>
            <i class="ri-arrow-right-s-line"></i>
            <a href="#">Confirm Contact Detail</a>
            <i class="ri-arrow-right-s-line"></i>
            <a href="#">How Long You Can Wait</a>
          </div>

          <div class="row">
            <div class="col-sm">
              <h5>Step 1</h5>
              <div class="icon-div">
                <img src={info1} alt="" />
              </div>
              <p class="step_3_sub_heading" style={{ width: "40%" }}>
                Fill up your vehicle and personal information to give car
                dealers on Carki a better estimate of the value of your car.
              </p>
              <br />
            </div>
          </div>
          <div class="jumbo-input sell-3">
            <div class="form-inline sell-inline-3">
              <div className="d-flex align-items-center div_step_3">
                <div className="mr-lg-3">
                  <p className="car_plate_no">
                    <b>Car plate number</b>
                  </p>
                  <input
                    type="text"
                    className={`${props.showValidation && !props.value.vehicleNumber ? "form-control commonFormControl validation_err" : "form-control commonFormControl"}`}
                    placeholder="SGX 8888 HUAT"
                    id="currency"
                    name="vehicleNumber"
                    value={props.value.vehicleNumber}
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div>
                <div className="ml-lg-4">
                  <p className="car_plate_no">
                    <b>NRIC (last 4 characters)</b>
                  </p>
                  <input
                    type="text"
                    className={`${props.showValidation && !props.value.nric ? "form-control commonFormControl validation_err" : "form-control commonFormControl"}`}
                    placeholder="567D"
                    id="currency"
                    name="nric"
                    value={props.value.nric}
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div>
              </div>
              <button
                onClick={() => props.setStepNo()}
                type="submit"
                class="btn btn-success commonButtonProp mt-5"
              >
                <b>Confirm</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SellStep2 = (props) => {
  const [activeBtn, setActiveBtn] = useState(1);

  useEffect(() => {
    props.onChangeFunc(activeBtn);
  }, [activeBtn]);

  return (
    <div class="jumbotron step-2 stepCommonPro">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="jumbo-link-s1">
            <a href="#">Vehicle Info</a>
            <i class="ri-arrow-right-s-line"></i>
            <a href="#">Confirm Contact Detail</a>
            <i class="ri-arrow-right-s-line"></i>
            <a href="#">
              <b>How Long You Can Wait</b>
            </a>
          </div>

          <div class="row">
            <div class="col-sm">
              <h5>Step 3</h5>
              <div class="icon-div">
                <img src={waitingPeriod1} alt="" />
              </div>
              <p>How fast would you like your car to be sold?</p>
              <br />
              <div class="row step2Button">
                <button
                  class={`startRadius ${activeBtn === 1 && "active"}`}
                  onClick={() => setActiveBtn(1)}
                >
                  1-2 Weeks
                </button>
                <button
                  class={`mst-pop ${activeBtn === 2 && "active"}`}
                  onClick={() => setActiveBtn(2)}
                >
                  3-4 Weeks <p class="mst-pop-cont">(Most Populer)</p>{" "}
                </button>
                <button
                  class={`${activeBtn === 3 && "active"}`}
                  onClick={() => setActiveBtn(3)}
                >
                  1-2 Months
                </button>
                <button
                  class={`endRadius ${activeBtn === 4 && "active"}`}
                  onClick={() => setActiveBtn(4)}
                >
                  3-6 Months
                </button>{" "}
                <br />
              </div>
              <br />
              <br />
              <button
                onClick={() => props.submitPrice()}
                type="submit"
                class="btn btn-success commonButtonProp"
              >
                <b>Submit And Finish</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const SellStep3 = (props) => {
//   return (
//     <div class="jumbotron step-3 stepCommonPro">
//       <div
//         id="carouselExampleControls"
//         class="carousel slide"
//         data-ride="carousel"
//       >
//         <div class="carousel-inner">
//           <div class="jumbo-link-s1">
//             <a href="#">Expacted Price</a>
//             <i class="ri-arrow-right-s-line"></i>
//             <a href="#">How Long You Can Wait</a>
//             <i class="ri-arrow-right-s-line"></i>
//             <a href="#">
//               <b>Vehicle Info</b>
//             </a>
//             <i class="ri-arrow-right-s-line"></i>
//             <a href="#">Confirm Contact Detail</a>
//           </div>

//           <div class="row">
//             <div class="col-sm">
//               <h5>Step 3</h5>
//               <div class="icon-div">
//                 <img src={info1} alt="" />
//               </div>
//               <p class="step_3_sub_heading" style={{ width: "40%" }}>
//                 Fill up your vehicle information to give car dealers on Carki a
//                 better estimate of the value of your car.
//               </p>
//               <br />
//               <p>
//                 <b>Car plate number</b>
//               </p>
//             </div>
//           </div>
//           <div class="jumbo-input sell-3">
//             <form class="form-inline sell-inline-3" action="#">
//               <input
//                 type="text"
//                 class="form-control commonFormControl"
//                 placeholder="SGX 8888 HUAT"
//                 id="currency"
//                 onChange={(e) => props.onChangeFunc(e.target.value)}
//               />
//               <button
//                 onClick={() => props.submitPrice()}
//                 type="submit"
//                 class="btn btn-success commonButtonProp"
//               >
//                 <b>Confirm</b>
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const SellStep4 = (props) => {
  return (
    <div class="jumbotron step-4 stepCommonPro">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="jumbo-link-s1">
            <a href="#">Vehicle Info</a>
            <i class="ri-arrow-right-s-line"></i>
            <a href="#">
              <b>Confirm Contact Detail</b>
            </a>
            <i class="ri-arrow-right-s-line"></i>
            <a href="#">How Long You Can Wait</a>
          </div>

          <div class="row">
            <div class="col-sm">
              <h5>Step 2</h5>
              <div class="icon-div">
                <img src={confirmDetails} alt="" />
              </div>
              <p>Confirm your contact details below:</p>
              <br />
            </div>
          </div>
          <div class="jumbo-input sell-4">
            <div class="form-inline sell-inline-4" action="#">
              <div className="d-flex align-items-center div_step_3">
                <div>
                  <p className="car_plate_no">
                    <b>Name</b>
                  </p>
                  <input
                    type="text"
                    className={`${props.showValidation && !props.value.name ? "form-control commonFormControl validation_err" : "form-control commonFormControl"}`}
                    placeholder="Lim Chu Kang"
                    id="currency"
                    name="name"
                    value={props.value.name}
                    onChange={(e) => props.onChangeFunc(e)}
                  />
                </div>
                <div>
                  <p className="car_plate_no">
                    <b>Phone number</b>
                  </p>
                  <input
                    type="number"
                    className={`${props.showValidation && !props.value.mobile ? "form-control commonFormControl validation_err" : "form-control commonFormControl"}`}
                    placeholder="9888 8888"

                    maxLength="8"
                    id="currency"
                    name="mobile"
                    value={props.value.mobile}
                    onChange={(e) => {
                      if (e.target.value.length >= 9) {
                        return false;
                      }
                      else {
                        props.onChangeFunc(e)
                      }

                    }}
                  />
                </div>
              </div>
              <button
                type="submit"
                onClick={() => props.setStepNo()}
                class="btn btn-success commonButtonProp"
              >
                <b>Confirm</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SellStep5 = () => {
  let history = useHistory();

  return (
    <div class="jumbotron step-5 stepCommonPro" style={{ paddingBottom: 140 }}>
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="row m-0">
            <div class="col-sm p-0">
              <h5>Registration Completed!</h5>
              <div class="icon-div">
                <div className="orange_tick_box">
                  <i className="fa fa-check" />
                </div>
              </div>
              <p className="sell_step5_sub_heading">
                Thank you for your interest! We will get back to you soon.
              </p>
              <br />
            </div>
          </div>
          <div class="jumbo-input sell-5">
            <div class="form-inline sell-inline-5">
              <button
                type="submit"
                onClick={() => history.push("/")}
                class="btn commonButtonProp"
              >
                <b>Back to Home</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const sellSteps = (props) => {
  const [stepNo, setStepNo] = useState(1);

  const [inputData, setInputData] = useState({
    weeks: "1-2 weeks",
  });

  const [showValidation, setshowValidation] = useState(false);

  useEffect(() => {
    setInputData({
      ...inputData,
      ["vehicleNumber"]: props.location.state,
    });
  }, [props]);

  const setWeekFunc = (value) => {
    if (value === 1) {
      setInputData({
        ...inputData,
        ["weeks"]: "1-2 weeks",
      });
    } else if (value === 2) {
      setInputData({
        ...inputData,
        ["weeks"]: "3-4 weeks",
      });
    }
    if (value === 3) {
      setInputData({
        ...inputData,
        ["weeks"]: "1-2 months",
      });
    }
    if (value === 4) {
      setInputData({
        ...inputData,
        ["weeks"]: "3-6 months",
      });
    }
  };

  const SubmitData = () => {
    let obj = {
      type: "sell",
      mobile: inputData.mobile,
      name: inputData.name,
      additionalInfo: {
        vehicleNumber: inputData.vehicleNumber,
        timeline: inputData.weeks,
        nric: inputData.nric,
        timeline: inputData.weeks,
      },
    };
    submiReqFormData(obj)
      .payload.then((e) => {
        setStepNo(4);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const onChangeHandlerFunc = (e) => {
    let value = e.target.value;
    setInputData({
      ...inputData,
      [e.target.name]: value,
    });
  };

  return (
    <React.Fragment>
      <Header />
      <div style={{ paddingTop: 60 }} className="sell_steps_background">
        {stepNo !== 1 && stepNo !== 4 && (
          <i
            className="fa fa-chevron-left arrow_left"
            onClick={() => setStepNo(stepNo - 1)}
          />
        )}

        {stepNo === 1 && (
          <SellStep1
            onChangeFunc={(e) => onChangeHandlerFunc(e)}
            setStepNo={() => {
              if (inputData.vehicleNumber && inputData.nric) {
                setStepNo(2);
                setshowValidation(false);
              }
              else {
                setshowValidation(true);
              }
            }}
            value={inputData}
            showValidation={showValidation}
          />
        )}
        {stepNo === 2 && (
          <SellStep4
            onChangeFunc={(e) => onChangeHandlerFunc(e)}
            setStepNo={() => {
              if (inputData.name && inputData.mobile) {
                setStepNo(3);
                setshowValidation(false);
              }
              else {
                setshowValidation(true);
              }
            }}
            value={inputData}
            showValidation={showValidation}
          />
        )}
        {stepNo === 3 && (
          <SellStep2
            onChangeFunc={(e) => setWeekFunc(e)}
            submitPrice={() => SubmitData()}
            value={inputData}
            showValidation={showValidation}
          />
        )}

        {stepNo === 4 && <SellStep5 />}
        {stepNo !== 4 && (
          <i
            className="fa fa-chevron-right arrow_right"
            onClick={() => {
              if (stepNo === 1) {
                if (inputData.vehicleNumber && inputData.nric) {
                  setStepNo(stepNo + 1);
                  setshowValidation(false);
                }
                else{
                  setshowValidation(true);
                }
              }
              else if (stepNo === 2) {
                if (inputData.name && inputData.mobile) {
                  setStepNo(stepNo + 1);
                  setshowValidation(false);
                }
                else{
                  setshowValidation(true);
                }
              }
              else{
                setStepNo(stepNo + 1);
              }
            }}
          />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default sellSteps;
