import React from "react";
import { Button } from "react-bootstrap";
import Previous from "../../images/icons/Previous.png";
import Next from "../../images/icons/Next.png";

const CreateListing2 = (props) => {
  return (
    <div className="details_background">
      <div className="row m-0 align-items-center back_button">
        <p className="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div className="row m-0 details_background_2 list-bg-2">
        <div className="col-lg-12 align-items-center justify-content-center p-0 pl-5 pr-5 listing-page-2">
          <div className="row m-0 mt-5 listing-tab-resp">
            <div className="col-lg-12 col-md-12 col-sm-12 px-1 py-2 listing-tab">
              <a href="#" className="active">
                Vehicle Details
              </a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Ownership</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Rebate</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Bidding</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Review</a>
            </div>
          </div>

          <div className="row m-0 mt-5 listing-tab-web">
            <div className="col-lg-12 p-2 listing-btn-section d-flex align-items-center justify-content-center">
              <button className="startRadius listing-btn-section-cmn listing-act-btn">
                Vehicle Details
              </button>
              <button className="listing-btn-section-cmn">Ownership</button>
              <button className="listing-btn-section-cmn">Rebate</button>
              <button className="listing-btn-section-cmn">Bidding</button>
              <button className="endRadius listing-btn-section-cmn">
                Review
              </button>
            </div>
          </div>

          <div className="row m-0 mt-4">
            <div className="col-lg-12">
              <h3 className="listing-head-1 listing-left-align">
                Vehicle Details
              </h3>
              <hr />
            </div>
          </div>

          <div className="row m-0 mt-3">
            <div className="col-lg-12">
              <h3 className="listing-head-6">Details</h3>
            </div>
          </div>

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Vehicle No.</p>
              <input
                type="text"
                placeholder="SIN 8888 G"
                name="vehicleNumber"
                value={props.inputData.vehicleNumber}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Vehicle Make</p>
              <input
                type="text"
                placeholder="Toyota"
                name="vehicleMake"
                value={props.inputData.vehicleMake}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-0">
              <p className="input-head">Vehicle Model</p>
              <input
                type="text"
                placeholder="Wish 1.8 Auto"
                name="vehicleModel"
                value={props.inputData.vehicleModel}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Primary Color.</p>
              <input
                type="text"
                placeholder="Orange"
                name="primaryColor"
                value={props.inputData.primaryColor}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-0">
              <p className="input-head">Manufacturing Year</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="2008"
                maxLength="4"
                name="manufacturingYear"
                value={props.inputData.manufacturingYear}
                onChange={(e) => {
                  if (e.target.value.length > 4) {
                    e.preventDefault();
                  } else {
                    props.onChangeFunc(e);
                  }
                }}
              />
            </div>
          </div>

          <div className="row m-0 mt-4">
            <div className="col-lg-12">
              <hr />
            </div>
          </div>

          <div className="row m-0 mt-3">
            <div className="col-lg-12">
              <h3 className="listing-head-6">Components</h3>
            </div>
          </div>

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Engine No.</p>
              <input
                type="text"
                placeholder="Jhon"
                name="engineNumber"
                value={props.inputData.engineNumber}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Chassis No.</p>
              <input
                type="text"
                placeholder="Jhon"
                name="chassisNumber"
                value={props.inputData.chassisNumber}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Maximum Power Output</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="kW"
                name="maximumPower"
                value={props.inputData.maximumPower}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div className="row m-0 p-lg-4 p-md-4 pb-5 mt-5 listing-btn align-items-center justify-content-center">
            <div className="col-lg-6 d-flex align-items-center justify-content-between">
              <Button className="prev-btn" onClick={() => props.setListing(1)}>
                <img src={Previous} alt="" />
                <p className="">Previous</p>
              </Button>
              <Button className="next-btn" onClick={() => props.setListing(3)}>
                <p className="">Next</p>
                <img src={Next} alt="" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing2;
