import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import sucessKey from "../../../images/icons/key.png";
import { submiReqFormData } from "../../../utils/apiUrls";
import { toast } from "react-toastify";

const LeadSquezePage = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  useEffect(() => {
    if (props.location) {
      if (props.location.state === undefined) {
        props.history.push("/car-loan-roadTax");
      }
    }
  });

  const SubmitData = () => {
    let obj = {
      type: "roadTax",
      name,
      email,
      mobile: mobileNo,
      additionalInfo: props.location.state,
    };
    submiReqFormData(obj)
      .payload.then((e) => {
        props.history.push("/thank-you");
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  return (
    <React.Fragment>
      <Header />

      <div className="lead_squeze_background row m-0 d-lg-flex d-none">
        <div className="col-2 p-0"></div>
        <div className="col-4 p-0">
          <img src={sucessKey} alt="" width="280" />
        </div>
        <div className="col-3 p-0">
          <h1>Discover.</h1>
          <p>
            The secrets of the auto industry that dealers don’t want you to know
            and gain the upper hand in negotiations.
          </p>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fas fa-user-alt"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fas fa-phone-alt"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              value={mobileNo}
              onChange={(e) => setmobileNo(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => SubmitData()}
              className="get_your_answer_btn"
            >
              Get Your Answer
            </button>
          </div>
        </div>
        <div className="col-3 p-0"></div>
      </div>

      <div className="lead_squeze_background row m-0 d-lg-none d-flex">
        <div className="row m-0">
          <h1>Discover.</h1>
        </div>
        <div className="row m-0">
          <div className="col-7 p-0 pr-2">
            <p>
              The secrets of the auto industry that dealers don’t want you to
              know and gain the upper hand in negotiations.
            </p>
          </div>
          <div className="col-5 p-0 d-flex align-items-center">
            <img src={sucessKey} alt="" width="120" />
          </div>
        </div>

        <div className="row m-0 w-100 mt-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fas fa-user-alt"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <i class="fas fa-phone-alt"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              aria-label="Username"
              value={mobileNo}
              onChange={(e) => setmobileNo(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              onClick={() => SubmitData()}
              className="get_your_answer_btn"
            >
              Get Your Answer
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default LeadSquezePage;
