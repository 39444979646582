import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import CarBackground from "../../components/common/carBackground";
import TabsComponent from "../../components/common/Tab";
import "../../css/login_register.css";
import {
  ResetForgottenPwdPartner,
  SendResetPwdLinkMember,
  SendResetPwdLinkPartner,
} from "../../utils/apiUrls";

const ResetPassword = (props) => {
  const [showType, setShowType] = useState(0);

  const [email, setEmail] = useState("");

  const [emailCode, setemailCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [userType, setUserType] = useState("Partner");

  let queryParam = new URLSearchParams(props.location.search).get("email-code");
  let queryParamEmail = new URLSearchParams(props.location.search).get("email");

  useEffect(() => {
    if (queryParam) {
      setemailCode(queryParam);
      setShowType(2);
    }
  }, []);

  const sendResetPwdLinkFunc = () => {
    let obj = {
      email: email,
    };
    if (userType === "Partner") {
      SendResetPwdLinkPartner(obj)
        .payload.then(() => {
          setShowType(1);
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    } else {
      SendResetPwdLinkMember(obj)
        .payload.then(() => {
          setShowType(1);
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    }
  };

  const ResetForgottenPwdFunc = () => {
    let obj = {
      emailCode,
      newPassword,
    };
    ResetForgottenPwdPartner(obj)
      .payload.then(() => {
        props.history.push({
          pathname: "/login",
          state:
            "Your password has been successfully changed! Try signing in to Carki.",
        });
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  return (
    <div className="row m-0">
      <div className="col-12 col-lg-7 p-0">
        <CarBackground />
      </div>
      <div className="col-12 col-lg-5 p-0 dark-background">
        <div className="row m-0">
          <div className="col-lg-2 col-1 p-0"></div>
          <div className="col-lg-8 col-10 p-0">
            <div className="row m-0 row_1">
              <div className="col-6 p-0 d-flex justify-content-center">
                <Button
                  variant="primary"
                  onClick={() => props.history.push("/login")}
                  className="btns prmy_btn"
                >
                  Log in
                </Button>
              </div>
              <div className="col-6 p-0 d-flex justify-content-center">
                <Button
                  variant="outline-primary"
                  onClick={() => props.history.push("/register")}
                  className="btns outline_btn"
                >
                  Create account
                </Button>
              </div>
            </div>

            {showType === 0 && (
              <React.Fragment>
                <div className="row m-0 row_3 mt-lg-5 mt-2">
                  <div className="col-12 p-0">
                    <p className="mb-0 heading_label pb-0">Reset Password</p>
                  </div>
                </div>

                <div className="row m-0 row_2 mb-lg-5 mb-4">
                  <div className="col-12 p-0">
                    <p className="mb-0 mt-5 message_label">
                      Let us confirm your identity first.
                    </p>
                  </div>
                </div>

                <div className="row m-0 row_4">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Email</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                {/* <div className="row m-0 row_4 pt-1 mt-4 mb-1">
                  <div className="col-12 p-0">
                    <TabsComponent
                      userType={userType}
                      setUserType={(type) => setUserType(type)}
                    />
                  </div>
                </div> */}

                <div className="row m-0 row_6">
                  <div className="col-12 p-0 d-flex justify-content-center mt-5">
                    <Button
                      onClick={() => sendResetPwdLinkFunc()}
                      variant="outline-primary"
                      className="btns login_btn"
                    >
                      Send Confirmation
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}

            {showType === 1 && (
              <React.Fragment>
                <div className="row m-0 row_3 mt-5 pl-4">
                  <div className="col-12 p-0">
                    <p className="mb-0 heading_label pb-0">Reset Password</p>
                  </div>
                </div>

                <div className="row m-0 row_2 mb-4 pl-4">
                  <div className="col-12 p-0">
                    <p
                      className="mb-0 mt-5 message_label"
                      style={{ whiteSpace: "initial" }}
                    >
                      A confirmation email has been sent to {email}.
                    </p>
                  </div>
                </div>
                <div className="row m-0 row_1 mb-3">
                  <div className="col-6 p-0 d-flex justify-content-center"></div>
                  <div className="col-6 p-0 d-flex justify-content-center align-items-center">
                    <div className="icon_didnt_get_mail d-flex justify-content-center align-items-center">
                      !
                    </div>
                    <p className="mb-0 pl-2 didnot_get_mail">
                      Didn’t get an email?
                    </p>
                  </div>
                </div>
                <div className="row m-0 row_1 pt-0">
                  <div className="col-6 p-0 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      onClick={() => props.history.push("/login")}
                      className="btns prmy_btn"
                    >
                      Got it!
                    </Button>
                  </div>
                  <div className="col-6 p-0 d-flex justify-content-center">
                    <Button
                      variant="outline-primary"
                      className="btns outline_btn"
                      onClick={() => sendResetPwdLinkFunc()}
                    >
                      Try again
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}

            {showType === 2 && (
              <React.Fragment>
                <div className="row m-0 row_3 mt-5">
                  <div className="col-12 p-0">
                    <p className="mb-0 heading_label pb-0">Reset Password</p>
                  </div>
                </div>

                <div className="row m-0 row_2 mb-lg-5 mb-3">
                  <div className="col-12 p-0">
                    <p className="mb-0 mt-5 message_label">
                      Reset password for {queryParamEmail}
                    </p>
                  </div>
                </div>

                <div className="row m-0 row_4 pt-1">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">New Password</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        type="password"
                        aria-describedby="basic-addon3"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="row m-0 row_4 pt-1">
                  <div className="col-12 p-0">
                    <label htmlFor="basic-url">Confirm Password</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        id="basic-url"
                        type="password"
                        aria-describedby="basic-addon3"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className="row m-0 row_6">
                  <div className="col-12 p-0 d-flex justify-content-center mt-5">
                    <Button
                      onClick={() => ResetForgottenPwdFunc()}
                      variant="outline-primary"
                      className="btns login_btn"
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="col-lg-2 col-1 p-0"></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
