import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Previous from "../../images/icons/Previous.png";
import Next from "../../images/icons/Next.png";

const CreateListing4 = (props) => {
  const [diffbwyears, setDiffbwYears] = useState("");

  const yearsDiffFunc = (d1, d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
  };

  useEffect(() => {
    let date = new Date();
    let date2 =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    yearsDiffFunc(props.data.firstRegistrationDate, date2);
    let diffYears = yearsDiffFunc(props.data.firstRegistrationDate, date2);
    if (diffYears >= 10) {
      props.onChangeFunc2("parfEligibility", "Forfeited");
    } else {
      props.onChangeFunc2("parfEligibility", "Eligible");
    }
    setDiffbwYears(diffYears);
  }, []);

  return (
    <div class="details_background">
      <div class="row m-0 align-items-center back_button">
        <p class="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div class="row m-0 details_background_2 list-bg-2">
        <div class="col-lg-12 align-items-center justify-content-center p-0 pl-5 pr-5 listing-page-2">
          <div class="row m-0 mt-5 listing-tab-resp">
            <div class="col-lg-12 col-md-12 col-sm-12 px-1 py-2 listing-tab">
              <a href="#">Vehicle Details</a>
              <i class="ri-arrow-right-s-line"></i>
              <a href="#">Ownership</a>
              <i class="ri-arrow-right-s-line"></i>
              <a href="#" class="active">
                Rebate
              </a>
              <i class="ri-arrow-right-s-line"></i>
              <a href="#">Bidding</a>
              <i class="ri-arrow-right-s-line"></i>
              <a href="#">Review</a>
            </div>
          </div>

          <div class="row m-0 mt-5 listing-tab-web">
            <div class="col-lg-12 p-2 listing-btn-section d-flex align-items-center justify-content-center">
              <button class="startRadius listing-btn-section-cmn">
                Vehicle Details
              </button>
              <button class="listing-btn-section-cmn">Ownership</button>
              <button class="listing-btn-section-cmn listing-act-btn">
                Rebate
              </button>
              <button class="listing-btn-section-cmn">Bidding</button>
              <button class="endRadius listing-btn-section-cmn">Review</button>
            </div>
          </div>

          <div class="row m-0 mt-4">
            <div class="col-lg-12">
              <h3 class="listing-head-1 listing-left-align">Rebate</h3>
              <hr />
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col-lg-12">
              <h3 class="listing-head-6">Intended PARF Rebate Details</h3>
              {diffbwyears >= 10 && (
                <p class="listing-head-7">
                  Since you intend for your vehicle to be de-registered in
                  greater than 10 years after its first registration date, you
                  are uneligible for PARF Rebate.{" "}
                </p>
              )}
            </div>
          </div>

          <div class="row m-0 mt-4 listing-inputs">
            <div class="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p class="input-head">PARF Eligibility Expiry Date</p>
              <input
                type="date"
                placeholder="SIN 8888 G"
                readOnly={diffbwyears >= 10}
                name="parfEligibilityExpiryDate"
                value={props.data.parfEligibilityExpiryDate && props.data.parfEligibilityExpiryDate.split("T")[0]}
                onChange={(e) => props.onChangeFunc(e)}
                className={`${diffbwyears >= 10 && "listing-err-page"}`}
              />
            </div>
            <div class="col-lg-4">
              <p class="input-head">PARF Rebate Amount</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="$"
                readOnly={diffbwyears >= 10}
                name="parfRebateAmount"
                onChange={(e) => props.onChangeFunc(e)}
                value={props.data.parfRebateAmount}
                className={`${diffbwyears >= 10 && "listing-err-page"}`}
              />
            </div>
          </div>

          <div class="row m-0 mt-5">
            <div class="col-lg-12">
              <hr />
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col-lg-12">
              <h3 class="listing-head-6">Intended COE Rebate Details</h3>
            </div>
          </div>

          <div class="row m-0 mt-4 listing-inputs">
            <div class="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p class="input-head">COE Expiry Date</p>
              <input
                type="date"
                placeholder="Jhon"
                name="coeExpiryDate"
                value={props.data.coeExpiryDate && props.data.coeExpiryDate.split("T")[0]}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div class="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p class="input-head">COE Category</p>
              <label for="Select"></label>
              <select
                name="select"
                id="select"
                name="coeCategory"
                onChange={(e) => props.onChangeFunc(e)}
              >
                <option value="A Category">A</option>
                <option value="B Category">B</option>
                <option value="C Category">C</option>
                <option value="D Category">D</option>
              </select>
            </div>
            <div class="col-lg-4">
              <p class="input-head">COE Period (Years)</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="0"
                name="coePeriodInYears"
                value={props.data.coePeriodInYears}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div class="row m-0 mt-4 listing-inputs">
            <div class="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p class="input-head">PQP Paid</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="pqpPaidAmount"
                value={props.data.pqpPaidAmount}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div class="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p class="input-head">COE Rebate Amount</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="coeRebateAmount"
                value={props.data.coeRebateAmount}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div class="col-lg-4">
              <p class="input-head">Total Rebate Amount</p>
              <input
                type="number"  onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="totalRebateAmount"
                value={props.data.totalRebateAmount}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div className="row m-0 p-lg-4 p-md-4 pb-5 mt-5 listing-btn align-items-center justify-content-center">
            <div className="col-lg-6 d-flex align-items-center justify-content-between">
              <Button className="prev-btn" onClick={() => props.setListing(3)}>
                <img src={Previous} alt="" />
                <p className="">Previous</p>
              </Button>
              <Button className="next-btn" onClick={() => props.setListing(5)}>
                <p className="">Next</p>
                <img src={Next} alt="" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing4;
