import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Previous from "../../images/icons/Previous.png";
import Next from "../../images/icons/Next.png";

const CreateListing3 = (props) => {
  const [vehicleExport, setVehicleExport] = useState(false);
  let findTodayDate = new Date();
  let date = `${findTodayDate.getFullYear()}-${(
    "0" +
    (findTodayDate.getMonth() + 1)
  ).slice(-2)}-${("0" + findTodayDate.getDate()).slice(-2)}`;

  return (
    <div className="details_background">
      <div className="row m-0 align-items-center back_button">
        <p className="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div className="row m-0 details_background_2 list-bg-2">
        <div className="col-lg-12 align-items-center justify-content-center p-0 pl-5 pr-5 listing-page-2">
          <div className="row m-0 mt-5 listing-tab-resp">
            <div className="col-lg-12 col-md-12 col-sm-12 px-1 py-2 listing-tab">
              <a href="#">Vehicle Details</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#" className="active">
                Ownership
              </a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Rebate</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Bidding</a>
              <i className="ri-arrow-right-s-line"></i>
              <a href="#">Review</a>
            </div>
          </div>

          <div className="row m-0 mt-5 listing-tab-web">
            <div className="col-lg-12 p-2 listing-btn-section d-flex align-items-center justify-content-center">
              <button className="startRadius listing-btn-section-cmn">
                Vehicle Details
              </button>
              <button className="listing-btn-section-cmn listing-act-btn">
                Ownership
              </button>
              <button className="listing-btn-section-cmn">Rebate</button>
              <button className="listing-btn-section-cmn">Bidding</button>
              <button className="endRadius listing-btn-section-cmn">
                Review
              </button>
            </div>
          </div>

          <div className="row m-0 mt-4">
            <div className="col-lg-12">
              <h3 className="listing-head-1 listing-left-align">Ownership</h3>
              <hr />
            </div>
          </div>

          <div className="row m-0 mt-3">
            <div className="col-lg-12">
              <h3 className="listing-head-6">Registration / Deregistration</h3>
            </div>
          </div>

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">First Registration Date</p>
              <input
                type="date"
                placeholder="SIN 8888 G"
                max={date}
                name="firstRegistrationDate"
                value={
                  props.inputData.firstRegistrationDate &&
                  props.inputData.firstRegistrationDate.split("T")[0]
                }
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Original Registration Date</p>
              <input
                type="date"
                placeholder="Toyota"
                name="originalRegistrationDate"
                max={date}
                value={
                  props.inputData.originalRegistrationDate &&
                  props.inputData.originalRegistrationDate.split("T")[0]
                }
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4">
              <p className="input-head">Intended Deregistration Date</p>
              <input
                type="date"
                placeholder="Wish 1.8 Auto"
                name="intentedDeregistrationDate"
                value={
                  props.inputData.intentedDeregistrationDate &&
                  props.inputData.intentedDeregistrationDate.split("T")[0]
                }
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Open Market Value</p>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="openMarketValue"
                value={props.inputData.openMarketValue}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
            <div className="col-lg-4">
              <p className="input-head">Actual ARF Paid</p>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="$"
                name="actualArfPaidAmount"
                value={props.inputData.actualArfPaidAmount}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div className="row m-0 mt-5">
            <div className="col-lg-12">
              <hr />
            </div>
          </div>

          <div className="row m-0 mt-3">
            <div className="col-lg-12">
              <h3 className="listing-head-6">Transfer</h3>
            </div>
          </div>

          <div className="row m-0 mt-4 listing-inputs">
            <div className="col-lg-4 pb-lg-0 pb-md-0 pb-3">
              <p className="input-head">Vehicle to be Exported?</p>
              <div className="listing-yes-no d-flex">
                <div
                  className={`${"yes-no yes"} ${
                    props.inputData.isVehicleExported && "active"
                  }`}
                  onClick={() => {
                    setVehicleExport(true);
                    props.onChangeFunc2("isVehicleExported", true);
                  }}
                >
                  <p>Yes</p>
                </div>
                <div
                  className={`${"yes-no no"} ${
                    !props.inputData.isVehicleExported && "active"
                  }`}
                  onClick={() => {
                    setVehicleExport(false);
                    props.onChangeFunc2("isVehicleExported", false);
                  }}
                >
                  <p>No</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <p className="input-head">Transfer Count</p>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="0"
                name="transferCount"
                value={props.inputData.transferCount}
                onChange={(e) => props.onChangeFunc(e)}
              />
            </div>
          </div>

          <div className="row m-0 p-lg-4 p-md-4 pb-5 mt-5 listing-btn align-items-center justify-content-center">
            <div className="col-lg-6 d-flex align-items-center justify-content-between">
              <Button className="prev-btn" onClick={() => props.setListing(2)}>
                <img src={Previous} alt="" />
                <p className="">Previous</p>
              </Button>
              <Button className="next-btn" onClick={() => props.setListing(4)}>
                <p className="">Next</p>
                <img src={Next} alt="" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing3;
