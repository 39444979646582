import React, { Component, useEffect, useState } from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import "../../../css/finance.css";
import { useHistory } from "react-router-dom";
import Dropdown from "../../../components/common/dropdown";

const CarLoanNew = () => {
  let history = useHistory();
  let currentDate = new Date();
  const [carPrice, setCarPrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [ofCarPrice, setOfCarPrice] = useState(0);
  const [intRate, setIntRate] = useState(1.88);
  const [years, setYears] = useState("0");
  const [months, setMonths] = useState("0");
  const [loanStartDate, setLoanStartDate] = useState("July");
  const [loanStartYear, setLoanStartYear] = useState("2021");
  const [showDropdown, setshowDropdown] = useState("");
  const [OMVValue, setOmvValue] = useState(0);
  const [arfValue, setarfValue] = useState("");
  const [vesValue, setVesValue] = useState(`B - $${0} (Neutral)`);
  const [catValue, setCatValue] = useState("A");

  const [coeValue, setCoeValue] = useState(0);
  const [offPeakvalue, setOffPeakvalue] = useState("no");

  const [showPaybleAmtBox, setShowPaybleAmtBox] = useState(false);
  const [payable_amt, set_payable_amt] = useState(0);

  const [totalInt, setTotalInterest] = useState(0);

  useEffect(() => {
    //Calculate down payment below
    let downPaymentValue = (Number(carPrice) * Number(ofCarPrice)) / 100;
    setDownPayment(downPaymentValue);
  }, [carPrice, ofCarPrice]);

  useEffect(() => {
    //Calculate ARF value below
    let calculatedArfValue;
    let newVesValue = vesValue.split("$")[1].split(" ")[0];
    if (OMVValue < 20000) {
      calculatedArfValue = Number(OMVValue) - Number(newVesValue);
    } else if (OMVValue > 50000) {
      calculatedArfValue =
        (Number(OMVValue) - 50000) * 180 + 62000 - newVesValue;
    } else {
      calculatedArfValue =
        (Number(OMVValue) - 20000) * 140 + 20000 - newVesValue;
    }

    setarfValue(calculatedArfValue);
  }, [OMVValue, vesValue]);

  const calculateCarLoan = () => {
    let loanAmount =
      offPeakvalue === "yes"
        ? Number(carPrice) - Number(downPayment) - 17000
        : Number(carPrice) - Number(downPayment);
    let TotalInterest =
      Number(loanAmount) * ((Number(intRate) / 100) * Number(years));
    let TotalLoan = TotalInterest + Number(loanAmount);

    let totalLoanTenure = (Number(years) * 12) + Number(months);
    setTotalInterest(TotalInterest.toFixed(0))
    let MonthlyInstallment = TotalLoan / totalLoanTenure;
    set_payable_amt(MonthlyInstallment.toFixed(2));
    setShowPaybleAmtBox(true);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const resetFields = () => {
    setCarPrice(0);
    setDownPayment(0);
    setOfCarPrice(30);
    setIntRate(1.88);
    setYears("0");
    setMonths("0");
    setLoanStartDate("July");
    setLoanStartYear("2021");
    setshowDropdown("");
    setOmvValue(0);
    setarfValue("");
    setVesValue(`B - $${0} (Neutral)`);
    setCatValue("A");
    setCoeValue(0);
    setOffPeakvalue("no");
    setShowPaybleAmtBox(false);
    set_payable_amt(0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const addCOEexpiryDate = (e) => {
    let findCOEDate = new Date(e.target.value);
    let currentDate = new Date();
    let findMonthsDiff;
    if(findCOEDate.getTime() > currentDate.getTime()) {
      findMonthsDiff = (findCOEDate.getFullYear() - currentDate.getFullYear()) * 12 + (findCOEDate.getMonth() - currentDate.getMonth());
    }
    else {
      findMonthsDiff = (findCOEDate.getFullYear() - currentDate.getFullYear()) * 12 + Math.abs((findCOEDate.getMonth() - currentDate.getMonth()));
    }
    // findMonthsDiff = findMonthsDiff > 3 ? findMonthsDiff - 3 : findMonthsDiff;
    let diffYear = Math.floor((findMonthsDiff / 12));
    let diffMonths = (diffYear * 12) - findMonthsDiff;
    diffMonths = Math.abs(diffMonths);
    diffYear = Math.abs(diffYear);
    setYears(String(diffYear));
    setMonths(String(diffMonths));
  }

  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid car-loan-cont finance-container">
        {showPaybleAmtBox ? (
          <div>
            <div className="row m-0 calculated_loan_box d-lg-none d-md-none d-flex">
            <div className="col-12 row m-0 align-items-center p-0">
                <div className="col-8 pl-4 estimate_payable text-center">
                Total Interest Accrued:
                </div>
                <div className="col-4 estimate_payable_amt text-center">
                  ${totalInt}
                </div>
              </div>
              <div className="col-12 row m-0 align-items-center p-0">
                <div className="col-8 pl-4 estimate_payable text-center">
                  Your estimated payable quantum per month is:
                </div>
                <div className="col-4 estimate_payable_amt text-center">
                  ${payable_amt}
                </div>
              </div>
              <div className="col-12 row m-0 align-items-center p-0">
                <div className="col-7 pl-4 estimate_payable text-center">
                  For a loan period of:
                </div>
                <div className="col-5 pr-0 estimate_payable_amt text-center">
                  {years} years
                  <div>{months} months</div>
                  <div>({(Number(years) * 12) + Number(months)} months)</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row car-loan-cont-row">
          <button className="active">PARF Car</button>
          <button onClick={() => history.push("/car-loan-used")}>
            COE Car
          </button>
          <button onClick={() => history.push("/car-loan-roadTax")}>
            Road Tax
          </button>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="cal-section">
            <div className="row cal-section-row">
              <h1>Calculate Your Car Loan For Free</h1>
              <p>Estimate the amount of money you have to pay in instalments</p>
              <p className="orng">
                Carki offers as low as 1.88% interest for your vehicle loans!
              </p>
            </div>
            {showPaybleAmtBox ? (
              <div className="calculated_loan_box d-lg-block d-md-block d-none">
                <div className="row m-0 mt-4">
                  <div className="col-1 p-0"></div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable" style={{textAlign: "end"}}>
                    Total Interest Accrued:
                    </div>
                    <div className="col-4 estimate_payable_amt">
                    ${totalInt}
                    </div>
                  </div>
                  <div className="col-6 row m-0 align-items-center p-0">
                    <div className="col-5 estimate_payable">
                      For a loan period of:
                    </div>
                    <div className="col-7 text-center estimate_payable_amt mt-2">
                      <div>{years} years {months} months</div>
                      <div className="mt-3 text-center" style={{ whiteSpace: 'nowrap' }}>({(Number(years) * 12) + Number(months)} months)</div>
                    </div>
                  </div>
                  <div className="col-0 p-0"></div>
                </div>
                <div className="row m-0 mb-5 mt-4">
                  <div className="col-1 p-0"></div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable">
                      Your estimated payable quantum per month is:
                    </div>
                    <div className="col-4 estimate_payable_amt">
                      ${payable_amt}
                    </div>
                  </div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-5 estimate_payable">
                      
                    </div>
                    <div className="col-4 estimate_payable_amt">
                     
                    </div>
                  </div>
                  <div className="col-1 p-0"></div>
                </div>
              </div>
            ) : null}
            <br />
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>Car Price</p>
                </div>
                <div className="calc-input">
                  <div className="input-group cal-size-a">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={carPrice}
                      type="number" onWheel={(e) => e.target.blur()}
                      onChange={(e) => setCarPrice(e.target.value)}
                      placeholder="0"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>Down Payment</p>
                </div>
                <div className="calc-input mr-lg-4 mr-md-4 mr-0">
                  <div className="input-group cal-size-a">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0"
                      value={downPayment}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      readOnly
                    />
                  </div>
                </div>
                <div className="calc-input-buttons calc-input-buttons-percentage">
                  <button
                    className={`percent-30 ${ofCarPrice === 0 && "active"}`}
                    onClick={() => setOfCarPrice(0)}
                  >
                    0%
                  </button>
                  <button
                    className={` ${ofCarPrice === 10 && "active"}`}
                    onClick={() => setOfCarPrice(10)}
                  >
                    10%
                  </button>
                  <button
                    className={`percent-50  ${ofCarPrice === 20 && "active"}`}
                    onClick={() => setOfCarPrice(20)}
                  >
                    20%
                  </button>

                  <p className="blue-text d-lg-none d-md-none d-block">
                    Of Car Price
                  </p>
                </div>
                <p className="blue-text d-lg-block d-md-block d-none">
                  Of Car Price
                </p>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common responsive_interest_rate">
                <div className="cal-name">
                  <p>COE Expiry Date</p>
                </div>
                <div className="calc-input">
                  <div className="input-group cal-size-a">
                    <input
                      className="form-control"
                      // value={carPrice}
                      min={`${currentDate.getFullYear() - 7}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`}
                      type="date"
                      format="yyyy-MM-dd"
                      onChange={(e) => addCOEexpiryDate(e)}
                      placeholder="DD/MM/YYYY"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont calc-cont-loan px-3 px-lg-0">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>Loan Tenure (Years)</p>
                  {/* <p>(COE Car Offset 3 months)</p> */}
                </div>
                <div className="calc-input">
                  <div
                    className="input-group cal-size-a"
                  // onClick={() => setshowDropdown(showDropdown ? "" : "1")}
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="7"
                          value={years}
                          readOnly
                          style={{ width: "50px" }}
                          aria-label="Text input with segmented dropdown button"
                        />
                        <p className="font-weight-bold mb-0 pl-3">Years</p>
                      </div>
                      <div className="d-flex align-items-center ml-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="7"
                          value={months}
                          readOnly
                          style={{ width: "50px" }}
                          aria-label="Text input with segmented dropdown button"
                        />
                        <p className="font-weight-bold mb-0 pl-3">Months</p>
                      </div>
                    </div>
                    {/* <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                      </div>
                    </div> */}
                  </div>
                  {showDropdown === "1" && (
                    <Dropdown
                      setValue={(v) => {
                        setYears(v);
                        setshowDropdown("");
                      }}
                      elements={["1", "2", "3", "4", "5", "6", "7"]}
                    />
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common responsive_interest_rate">
                <div className="cal-name">
                  <p>Interest Rate (% p.a.)</p>
                </div>
                <div className="calc-input">
                  <div className="input-group cal-size-a">
                    <input
                      type="number" onWheel={(e) => e.target.blur()}
                      className="form-control"
                      placeholder="1.88"
                      value={intRate}
                      onChange={(e) => setIntRate(e.target.value)}
                      aria-label="Text input with segmented dropdown button"
                    />
                    {/* <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>Loan Start Date</p>
                </div>
                <div className="d-flex calc-row-loan-data">
                  <div className="calc-input loan-start-date-month">
                    <div
                      className="input-group cal-size-b"
                      onClick={() => setshowDropdown(showDropdown ? "" : "2")}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="May"
                        aria-label="Text input with segmented dropdown button"
                        value={loanStartDate}
                        readOnly
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                        </div>
                      </div>
                    </div>
                    {showDropdown === "2" && (
                      <Dropdown
                        setValue={(v) => {
                          setLoanStartDate(v);
                          setshowDropdown("");
                        }}
                        elements={[
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "July",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ]}
                      />
                    )}
                  </div>
                  <div className="calc-input loan-start-date-year">
                    <div
                      className="input-group cal-size-c"
                      onClick={() => setshowDropdown(showDropdown ? "" : "3")}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="2021"
                        aria-label="Text input with segmented dropdown button"
                        value={loanStartYear}
                        readOnly
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                          <a className="dropdown-item" href="#"></a>
                        </div>
                      </div>
                    </div>
                    {showDropdown === "3" && (
                      <Dropdown
                        setValue={(v) => {
                          setLoanStartYear(v);
                          setshowDropdown("");
                        }}
                        elements={["2020", "2021", "2022"]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <br />
            <hr />
            <br />

            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>OMV</p>
                </div>
                <div className="calc-input">
                  <div className="input-group cal-size-a">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        $
                      </span>
                    </div>
                    <input
                      type="number" onWheel={(e) => e.target.blur()}
                      className="form-control"
                      placeholder="0"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={OMVValue}
                      onChange={(e) => setOmvValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>ARF</p>
                </div>
                <div className="calc-input">
                  <div className="input-group cal-size-a">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control text-blank"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={arfValue}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>VES</p>
                </div>
                <div className="calc-input">
                  <div
                    className="input-group cal-size-a"
                    onClick={() => setshowDropdown(showDropdown ? "" : "4")}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="B — $0 (Neutral)"
                      aria-label="Text input with segmented dropdown button"
                      value={vesValue}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                      </div>
                    </div>
                  </div>
                  {showDropdown === "4" && (
                    <Dropdown
                      setValue={(v) => {
                        setVesValue(v);
                        setshowDropdown("");
                      }}
                      elements={[
                        `A1 - $${25000} (Rebate)`,
                        `A2 - $${15000} (Rebate)`,
                        `B - $${0} (Neutral)`,
                        `C1 - $${15000} (Surcharge)`,
                        `C2 - $${15000} (Surcharge)`,
                      ]}
                    />
                  )}
                </div>
                <div className="calc-input-buttons calc-input-buttons-cat">
                  <button
                    className={`percent-30 ${catValue === "A" && "active"}`}
                    onClick={() => setCatValue("A")}
                  >
                    CAT A
                  </button>
                  <button
                    className={`percent-50 ${catValue === "B" && "active"}`}
                    onClick={() => setCatValue("B")}
                  >
                    CAT B
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common">
                <div className="cal-name">
                  <p>COE</p>
                </div>
                <div className="calc-input">
                  <div className="calc-input cal-size-a">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                      </div>
                      <input
                        type="number" onWheel={(e) => e.target.blur()}
                        className="form-control"
                        placeholder="0"
                        aria-label="Username"
                        onChange={(e) => setCoeValue(e.target.value)}
                        value={coeValue}
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common calc-off-peak-arae">
                <div className="cal-name">
                  <p>Off-peak Car?</p>
                </div>
                <div className="calc-input-buttons">
                  <button
                    className={`percent-30 ${offPeakvalue === "yes" && "active"
                      }`}
                    onClick={() => setOffPeakvalue("yes")}
                  >
                    YES
                  </button>
                  <button
                    className={`percent-50 ${offPeakvalue === "no" && "active"
                      }`}
                    onClick={() => setOffPeakvalue("no")}
                  >
                    NO
                  </button>
                </div>
                <p className="blue-text">
                  If yes, car price will be reduced by $17,000{" "}
                </p>
              </div>
            </div>
            <div className="align-items-center justify-content-center d-lg-none d-md-none d-block">
              <div className="cal-section-info cal-section-info-mobile pb-4">
                <p>
                  Disclaimer: Our calculators are used as tools to help you to
                  get a better sense of your financial commitments when buying a
                  car. Our figures are based on MAS restrictions on motor
                  vehicle loans by financial institutions. It does not warrant
                  the accuracy, adequacy or completeness of the figures
                  presented. Carki Pte Ltd expressly disclaims liability for
                  errors or omissions.
                </p>
              </div>
            </div>
            <br />
            <div className="calc-cont">
              <div className="calc-row-one calc-common submit-button">
                <div className="cal-name d-lg-block d-md-block d-none">
                  <p></p>
                </div>
                <div className="calc-input-buttons submit-button-btn">
                  <button
                    className="percent-30"
                    onClick={() => calculateCarLoan()}
                  >
                    Calculate Car Loan Now
                  </button>
                </div>
                <div className="calc-input-buttons submit-button-reset">
                  <button className="percent-30" onClick={() => resetFields()}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="align-items-center justify-content-center d-lg-flex d-md-flex d-none">
          <div className="cal-section-info pb-4">
            <p>
              Disclaimer: Our calculators are used as tools to help you to get a
              better sense of your financial commitments when buying a car. Our
              figures are based on MAS restrictions on motor vehicle loans by
              financial institutions. It does not warrant the accuracy, adequacy
              or completeness of the figures presented. Carki Pte Ltd expressly
              disclaims liability for errors or omissions.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CarLoanNew;
