import React from "react";
import "../../css/common_css/common.css";

const Dropdown = (props) => {
  return (
    <div className="dropdown_background">
        {props.elements.map((v, i) => {
            return <p key={i} onClick={() => props.setValue(v)}>{v}</p>
        })}
    </div>
  );
};

export default Dropdown;
