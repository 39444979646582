import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import "./css/style.css";
import "./css/responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
      />
      <Routes />
    </BrowserRouter>
  );
};

export default App;
