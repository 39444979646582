import React, { Component, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import contact_1 from "../../images/icons/phone_1.png";
import info1 from "../../images/svg-icons/car_info_1.svg";
import waitingPeriod1 from "../../images/svg-icons/waiting_1.svg";
import contact1 from "../../images/svg-icons/contact_1.svg";
import connect1 from "../../images/svg-icons/connect_1.svg";

const Sell = (props) => {
  const [price, setInputValue] = useState("");
  const [showValidation, setshowValidation] = useState(false);

  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid sell px-lg-0 px-md-0 p-0">
        <div className="row sell-row d-lg-flex d-md-flex d-none m-0">
          <div className="col-sm-8 sell-front-banner-1">
            <h1 className="heading">
              Sell Your Car
              <br />
              to Carki in
              <br />3 simple steps
            </h1>
            <p className="heading-sub-txt">
              Selling your cars has never been easier and more <br />
              transparent through your neighbourhood Carki.
            </p>

            <button
              onClick={() => props.history.push("/sell-steps")}
              type="button"
              className="btn btn-orange banner-btn"
            >
              Get A FREE Quote &nbsp; &nbsp;
              <i className="ri-arrow-right-line"></i>
            </button>
          </div>

          <div className="col-sm-4 sell-front-banner-2"></div>
        </div>

        <div className="row m-0 sell-row-responsive d-lg-none d-md-none d-block">
          <div className="area_1">
            <h4>Sell Your Car to Carki in 5 Simple Steps</h4>
            <p>
              Selling your cars has never been easier and more transparent
              through your neighbourhood Carki.
            </p>
          </div>
          <div className="area_2"></div>
          <button
            onClick={() => props.history.push("/sell-steps")}
            type="button"
            className="btn btn-orange banner-btn"
          >
            Get A FREE Quote &nbsp; &nbsp;
            <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>

      <div className="container-fluid steps-div position-relative">
        {/* <div className="whatsapp-container whatsapp-container-section">
          <img src="../images/img/whatsapp_logo_1_1.png" alt="" />
        </div> */}
        <div className="row sell-row-1">
          <div className="col-xl">
            <h2 className="heading2">
              Everything Done Digitally:
              <br />
              No More Travelling To Dealerships!
            </h2>
          </div>
        </div>
        <div className="row sell-row-2">
          <div className="col-sm">
            <h5>Step 1</h5>
            <div className="icon-div">
              <img src={info1} alt="" />
            </div>
            <p className="para-text">
              Fill up your vehicle
              <br />
              information
            </p>
          </div>
          <div className="col-sm">
            <h5>Step 2</h5>
            <div className="icon-div">
              <img src={contact_1} alt="" />
            </div>
            <p className="para-text">
              Fill up your contact
              <br />
              details
            </p>
          </div>
          <div className="col-sm">
            <h5>Step 3</h5>
            <div className="icon-div">
              <img src={waitingPeriod1} alt="" />
            </div>
            <p className="para-text">
              Decide on your
              <br />
              waiting period
            </p>
          </div>
        </div>
      </div>

      <div className="jumbotron jumbo-sell">
        <h1 className="heading2">Try Now For Free!</h1>
        <p className="para-text">Highest valuation within 4 hours</p>
        <div className="jumbo-input">
          <div className="form-inline sell-inline">
            {/* <span className="dollor-sign">$ </span> */}
            <input
              type="text"
              placeholder="Car plate number"
              className={`${showValidation && !price ? "form-control validation_err" : "form-control"}`}
              id="currency"
              value={price}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-orange"
              onClick={() => {
                if (price) {
                  setshowValidation(false);
                  props.history.push({
                    pathname: "/sell-steps",
                    state: price,
                  })
                }
                else{
                  setshowValidation(true);
                }
              }
              }
            >
              Auction Your Car
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Sell;
