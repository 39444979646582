import React, { useState, useEffect } from "react";
import PartnerPageRouting from "../../../components/common/partnerPageRouting";
import Header from "../../../components/header";
import "../../../css/partnerView.css";
import meterIcon from "../../../images/icons/Meter.png";
import calendarIcon from "../../../images/icons/calendar.png";
import carkiLogo from "../../../images/icons/CARKI_LOGO.png";
import { FormControl, InputGroup, Spinner } from "react-bootstrap";
import {
  GetAllListingApiBuy,
  GetAllListingApiRequired,
  GetAllListingApiSell,
  GetListingByIdApiSold,
  GetPartnerProfile,
  GetPartnerProfileAnalysisApi,
  UpdatePartnerProfile,
  UpdatePartnerProfilePhotoOnly,
} from "../../../utils/apiUrls";
import { toast } from "react-toastify";
import { FindDate } from "../../../utils/constants";

let date = new Date();
const MyProfileEdit = (props) => {
  const [profileDetails, setProfileDetails] = useState({});
  const [profileAnalysis, setProfileAnalysisData] = useState({});
  const [showPage, setShowPage] = useState(false);

  const [profileImage, setProfileImgeSrc] = useState("");

  const [bussinessName, setBussinessName] = useState("");
  const [uen, setBussinessUEN] = useState("");
  const [pointOfContactName, setpointOfContactName] = useState("");
  const [pointOfContactPhoneNumber, setpointOfContactPhoneNumber] =
    useState("");
  const [pointOfContactEmail, setpointOfContactEmail] = useState("");

  const [myBidsUsedCar, setMyBidsUsedCar] = useState([]);
  const [listCount2, setListCount2] = useState(4);
  const [myListingUsedCar, setMyListingUsedCar] = useState([]);
  const [listCount3, setListCount3] = useState(4);

  useEffect(() => {
    GetPartnerProfileFunc();
    GetAllListingApiBuyFunc(listCount2);
    GetAllListingApiSellFunc(listCount3);
    GetPartnerProfileAnalysisFunc();
  }, []);

  const GetPartnerProfileFunc = () => {
    GetPartnerProfile().payload.then((res) => {
      let obj = res.data;
      let breakName = res.data.name.split(" ");
      obj.firstName = breakName[0];
      obj.lastName = breakName[1];
      setProfileDetails(obj);
      setBussinessName(obj.business.name);
      setBussinessUEN(obj.business.uen);
      setpointOfContactName(obj.business.pointOfContactName);
      setpointOfContactPhoneNumber(obj.business.pointOfContactPhoneNumber);
      setpointOfContactEmail(obj.business.pointOfContactEmail);
      setProfileImgeSrc(obj.profilePhoto);
      setShowPage(true);
    });
  };

  const GetPartnerProfileAnalysisFunc = () => {
    GetPartnerProfileAnalysisApi().payload.then((res) => {
      setProfileAnalysisData(res.data);
    });
  };

  const GetAllListingApiBuyFunc = (listCount) => {
    GetAllListingApiRequired(listCount).payload.then((res) => {
      setMyBidsUsedCar(
        res.data.listingArray.filter((v) => v.status === "closed")
      );
    });
  };

  const GetAllListingApiSellFunc = (listCount) => {
    GetListingByIdApiSold(listCount).payload.then((res) => {
      setMyListingUsedCar(
        res.data.listingArray.filter((v) => v.status === "closed")
      );
    });
  };

  const showMoreFunc3 = () => {
    setListCount3(listCount3 + 2);
    GetAllListingApiSellFunc(listCount3 + 2);
  };

  const showMoreFunc2 = () => {
    setListCount2(listCount2 + 2);
    GetAllListingApiBuyFunc(listCount2 + 2);
  };

  const UpdatePartnerProfilePhotoOnlyFunc = (img) => {
    let formData = new FormData();
    formData.append("profilePhoto", img);
    UpdatePartnerProfilePhotoOnly(formData)
      .payload.then(() => {
        toast.success("Profile photo updated successfully");
        GetPartnerProfile().payload.then((res) => {
          localStorage.setItem("loggedInUserDetails", JSON.stringify(res.data));
        });
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const UpdatePartnerProfileFunc = () => {
    let newObj = profileDetails;
    newObj.name = profileDetails.firstName + " " + profileDetails.lastName;
    delete newObj.firstName;
    delete newObj.lastName;
    let business = {
      name: bussinessName,
      uen,
      pointOfContactName,
      pointOfContactPhoneNumber,
      pointOfContactEmail,
    };
    newObj.business = business;
    UpdatePartnerProfile(newObj)
      .payload.then(() => {
        localStorage.setItem(
          "loggedInUserDetails",
          JSON.stringify({
            id: profileDetails.id,
            name: profileDetails.name,
            email: profileDetails.email,
          })
        );
        toast.success("Profile updated successfully");
        GetPartnerProfileFunc();
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  const onChangeHandler = (e) => {
    let value;
    value = e.target.value;
    setProfileDetails({
      ...profileDetails,
      [e.target.name]: value,
    });
  };

  return (
    <React.Fragment>
      <Header />

      <div className="background_white my_profile_bk pr-lg-4">
        <div className="row m-0">
          <div
            className="col-3 d-lg-block d-md-block d-none px-5"
            style={{ marginTop: "160px" }}
          >
            <PartnerPageRouting activePage="my-profile" />
          </div>

          <div className="col-lg-9 col-md-9 col-12 pr-lg-5 pr-md-5 p-0 my_profile_gray_bk">
            {showPage ? (
              <div className="row m-0">
                <div className="col-3 p-0 d-lg-block d-none">
                  <div
                    className="user_profile_div"
                    style={{
                      backgroundImage: profileImage
                        ? `url(${profileImage})`
                        : ` url(${profileDetails.profilePhoto})`,
                      backgroundPosition: `center`,
                      backgroundSize: "cover",
                    }}
                  ></div>

                  <label className="change_pro_btn d-flex justify-content-center align-items-center">
                    {" "}
                    Change Profile Picture
                    <input
                      type="file"
                      className="change_pro_btn"
                      id="myfile"
                      name="profilePhoto"
                      onChange={(e) => {
                        setProfileImgeSrc(
                          URL.createObjectURL(e.target.files[0])
                        );
                        UpdatePartnerProfilePhotoOnlyFunc(e.target.files[0]);
                      }}
                    />
                  </label>

                  {/* <button className="change_pro_btn" name="profilePhoto">
                    Change Profile Picture
                  </button> */}

                  <div className="listing_info mt-5">
                    <h1>
                      {" "}
                      Data:{" "}
                      {date.toLocaleString("default", {
                        month: "short",
                      })}{" "}
                      {date.getFullYear()}
                    </h1>
                    <div className="listing_info_card px-4 py-2">
                      <div>
                        <h2>Listings</h2>
                        <div className="listing_info_sub_card">
                          <div className="row m-0">
                            <div className="col-9 text-right">
                              <div className="green_text">Sold:</div>
                              <div className="black_text">
                                Currently Listed:
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="orange_text">{profileAnalysis.soldListingsCount}</div>
                              <div className="orange_text">{profileAnalysis.allListingsCount}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <h2>Bids</h2>
                        <h3>
                          Active <span>({profileAnalysis.openBidsCount})</span>
                        </h3>
                        <div className="listing_info_sub_card">
                          <div className="row m-0">
                            <div className="col-9 text-right">
                              <div className="green_text">In Progress:</div>
                            </div>
                            <div className="col-3">
                              <div className="orange_text">{profileAnalysis.openBidsCount}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1">
                        <h3>
                          Inactive <span>({profileAnalysis.wonBidsCount + profileAnalysis.outbiddedListingsCount})</span>
                        </h3>
                        <div className="listing_info_sub_card">
                          <div className="row m-0">
                            <div className="col-9 text-right">
                              <div className="green_text">Won:</div>
                              <div className="black_text">Outbidded:</div>
                            </div>
                            <div className="col-3">
                              <div className="orange_text">{profileAnalysis.wonBidsCount}</div>
                              <div className="orange_text">{profileAnalysis.outbiddedListingsCount}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-12 pl-lg-5 p-0 right_side_bk">
                  <div className="row m-0 align-items-center justify-content-between d-lg-flex d-none">
                    <h3 className="mb-0 font-weight-bold">
                      Personal Particulars
                    </h3>
                    <button
                      className="change_pro_btn edit_pro_btn update_profile_btn"
                      onClick={() => UpdatePartnerProfileFunc()}
                    >
                      Update Profile
                    </button>
                  </div>

                  <div className="white_card my_profile_edit_form_fields row m-0 mt-3 px-5 py-3 d-lg-flex d-none align-items-baseline justify-content-between">
                    <div className="row m-0 w-100">
                      <div className="input_heading">First Name</div>
                      <InputGroup className="mb-0" size="lg">
                        <FormControl
                          aria-label="Username"
                          name="firstName"
                          aria-describedby="basic-addon1"
                          value={profileDetails.firstName}
                          onChange={(e) => onChangeHandler(e)}
                        />
                      </InputGroup>
                    </div>
                    <div className="row m-0 mt-3 w-100">
                      <div className="input_heading">Last Name</div>
                      <InputGroup className="mb-0" size="lg">
                        <FormControl
                          name="lastName"
                          aria-label="Username"
                          value={profileDetails.lastName}
                          onChange={(e) => onChangeHandler(e)}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="row m-0 mt-3 w-100">
                      <div className="input_heading">Phone Number</div>
                      <InputGroup className="mb-0" size="lg">
                        <FormControl
                          aria-label="Username"
                          name="mobile"
                          value={profileDetails.mobile}
                          onChange={(e) => onChangeHandler(e)}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="row m-0 mt-3 w-100">
                      <div className="input_heading">Email Address</div>
                      <InputGroup className="mb-0" size="lg">
                        <FormControl
                          aria-label="Username"
                          name="email"
                          value={profileDetails.email}
                          onChange={(e) => onChangeHandler(e)}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="d-lg-block d-none">
                    <h3 className="mb-0 font-weight-bold mt-4">Short Bio</h3>
                    <div className="white_card px-5 py-3  my_profile_edit_form_fields">
                      <div className="row m-0 w-100">
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            placeholder="Your bio is empty."
                            aria-label="Username"
                            name="bio"
                            aria-describedby="basic-addon1"
                            value={profileDetails.bio}
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>

                  <div className="d-lg-block d-none">
                    <h3 className="mb-0 font-weight-bold mt-4">
                      Company Information
                    </h3>
                    <div className="white_card px-5 py-3  my_profile_edit_form_fields">
                      <div className="row m-0 w-100">
                        <div className="input_heading">Company Name</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="Username"
                            name="business"
                            aria-describedby="basic-addon1"
                            value={bussinessName}
                            onChange={(e) => setBussinessName(e.target.value)}
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">UEN</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            name="uen"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={uen}
                            onChange={(e) => setBussinessUEN(e.target.value)}
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">POC Name</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="Username"
                            name="pointOfContactName"
                            aria-describedby="basic-addon1"
                            value={pointOfContactName}
                            onChange={(e) =>
                              setpointOfContactName(e.target.value)
                            }
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">POC Phone Number</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="pointOfContactPhoneNumber"
                            aria-describedby="basic-addon1"
                            value={pointOfContactPhoneNumber}
                            onChange={(e) =>
                              setpointOfContactPhoneNumber(e.target.value)
                            }
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">POC Email Address</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="pointOfContactEmail"
                            aria-describedby="basic-addon1"
                            value={pointOfContactEmail}
                            onChange={(e) =>
                              setpointOfContactEmail(e.target.value)
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>

                  <div className="my_profile_mobile_view d-lg-none d-block">
                    <div className="row m-0 pt-4 d-flex justify-content-between">
                      <h1 className="mb-0 name"></h1>
                      <button
                        className="change_pro_btn update_profile_btn"
                        onClick={() => UpdatePartnerProfileFunc()}
                      >
                        Update Profile
                      </button>
                    </div>

                    <div className="row m-0 justify-content-center">
                      <div
                        className="image_div_mobile"
                        style={{
                          backgroundImage: profileImage
                            ? `url(${profileImage})`
                            : ` url(${profileDetails.profilePhoto})`,
                          backgroundPosition: `center`,
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="inside_change_pro_btn">
                          <label className="change_pro_btn_mobile d-flex justify-content-center align-items-center">
                            {" "}
                            Change Profile Picture
                            <input
                              type="file"
                              className="change_pro_btn"
                              id="myfile"
                              name="profilePhoto"
                              onChange={(e) => {
                                setProfileImgeSrc(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                UpdatePartnerProfilePhotoOnlyFunc(
                                  e.target.files[0]
                                );
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <h3 className="mb-0 font-weight-bold mt-4 h3_headings">
                        Personal Particulars
                      </h3>
                      <div className="white_card p-3  my_profile_edit_form_fields mt-2">
                        <div className="row m-0 w-100">
                          <div className="input_heading">First Name</div>
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              aria-label="Username"
                              name="firstName"
                              aria-describedby="basic-addon1"
                              value={profileDetails.firstName}
                              onChange={(e) => onChangeHandler(e)}
                            />
                          </InputGroup>
                        </div>
                        <div className="row m-0 mt-3 w-100">
                          <div className="input_heading">Last Name</div>
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              aria-label="Username"
                              name="lastName"
                              aria-describedby="basic-addon1"
                              value={profileDetails.lastName}
                              onChange={(e) => onChangeHandler(e)}
                            />
                          </InputGroup>
                        </div>
                        <div className="row m-0 mt-3 w-100">
                          <div className="input_heading">Phone Number</div>
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              aria-label="Username"
                              name="mobile"
                              value={profileDetails.mobile}
                              onChange={(e) => onChangeHandler(e)}
                              aria-describedby="basic-addon1"
                            />
                          </InputGroup>
                        </div>
                        <div className="row m-0 mt-3 w-100">
                          <div className="input_heading">Email Address</div>
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              aria-label="Username"
                              name="email"
                              value={profileDetails.email}
                              onChange={(e) => onChangeHandler(e)}
                              aria-describedby="basic-addon1"
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <h3 className="mb-0 font-weight-bold mt-4 h3_headings">
                        Short Bio
                      </h3>
                      <div className="white_card p-3  my_profile_edit_form_fields mt-2">
                        <div className="row m-0 w-100">
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              placeholder="Your bio is empty."
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              name="bio"
                              value={profileDetails.bio}
                              onChange={(e) => onChangeHandler(e)}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </div>

                    <h3 className="mb-0 font-weight-bold mt-4">
                      Company Information
                    </h3>
                    <div className="white_card my_profile_edit_form_fields row m-0 mt-2 p-3 d-flex align-items-baseline justify-content-between">
                      <div className="row m-0 w-100">
                        <div className="input_heading h3_headings">
                          Company Name
                        </div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="Username"
                            name="business"
                            aria-describedby="basic-addon1"
                            value={bussinessName}
                            onChange={(e) => setBussinessName(e.target.value)}
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">UEN</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            name="uen"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={uen}
                            onChange={(e) => setBussinessUEN(e.target.value)}
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">POC Name</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="Username"
                            name="pointOfContactName"
                            aria-describedby="basic-addon1"
                            value={pointOfContactName}
                            onChange={(e) =>
                              setpointOfContactName(e.target.value)
                            }
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">POC Phone Number</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="pointOfContactPhoneNumber"
                            aria-describedby="basic-addon1"
                            value={pointOfContactPhoneNumber}
                            onChange={(e) =>
                              setpointOfContactPhoneNumber(e.target.value)
                            }
                          />
                        </InputGroup>
                      </div>

                      <div className="row m-0 mt-3 w-100">
                        <div className="input_heading">POC Email Address</div>
                        <InputGroup className="mb-0" size="lg">
                          <FormControl
                            aria-label="pointOfContactEmail"
                            aria-describedby="basic-addon1"
                            value={pointOfContactEmail}
                            onChange={(e) =>
                              setpointOfContactEmail(e.target.value)
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <hr className="w-100 my-4" />

                    <div className="row m-0 active_inactive_listing mt-4">
                      <p className="active_inactive_listing_card_heading mb-2">
                        Data: <span> {date.toLocaleString("default", {
                        month: "short",
                      })}{" "}
                      {date.getFullYear()}</span>
                      </p>
                      <div className="active_inactive_listing_card">
                        <div className="active_inactive_listing_card_text row m-0">
                          <div className="col-6 p-0 text-center">
                            <p className="mb-0">Active Listings</p>
                          </div>
                          <div className="col-6 p-0 text-center">
                            <p className="mb-0">Inactive Listings</p>
                          </div>
                        </div>

                        <div className="active_inactive_listing_card_text_2 row m-0">
                          <div className="col-6 p-0 text-center first">
                            <p className="mb-0 font-weight-bold pt-1">
                              Current
                            </p>
                            <p className="mb-0 orange_text font-weight-bold">
                              {profileAnalysis.allListingsCount}
                            </p>
                          </div>
                          <div className="col-6 p-0 text-center second">
                            <p className="mb-0 font-weight-bold pt-1">Sold</p>
                            <p className="mb-0 green_text font-weight-bold">
                            {profileAnalysis.soldListingsCount}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="active_inactive_listing_card mt-4">
                        <div className="active_inactive_listing_card_text row m-0">
                          <div className="col-6 p-0 text-center">
                            <p className="mb-0">Active Listings</p>
                          </div>
                          <div className="col-6 p-0 text-center">
                            <p className="mb-0">Inactive Listings</p>
                          </div>
                        </div>

                        <div className="active_inactive_listing_card_text_2 row m-0">
                          <div className="col-6 p-0 text-center first d-flex justify-content-center">
                          <div>
                              <p className="mb-0 font-weight-bold pt-1">
                                In Progress
                              </p>
                              <p className="mb-0 orange_text font-weight-bold">
                              {profileAnalysis.openBidsCount}
                              </p>
                            </div>
                           
                          </div>
                          <div className="col-6 p-0 text-center second d-flex justify-content-around">
                            <div>
                              <p className="mb-0 font-weight-bold pt-1">Won</p>
                              <p className="mb-0 green_text font-weight-bold">
                              {profileAnalysis.wonBidsCount}
                              </p>
                            </div>
                            <div>
                              <p className="mb-0 font-weight-bold pt-1">
                                Outbidded
                              </p>
                              <p className="mb-0 orange_text font-weight-bold">
                              {profileAnalysis.outbiddedListingsCount}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 mt-4">
                    <div className="col-lg-6 col-12 p-0">
                      <h2 className="mb-0 recently_heading">
                        Recently Acquired
                      </h2>
                      <div className="recently_acquired_background p-3 mr-lg-3">
                        {!myBidsUsedCar.length && (
                          <p className="text-center font-weight-bold">
                            No record found
                          </p>
                        )}
                        {(myBidsUsedCar || []).map((v, i) => {
                          return (
                            <div
                              className="row m-0 mt-3 blue-zone cursor_pointer"
                              key={i}
                            >
                              <div className="col-8 tab-1-left p-0">
                                <div className="row m-0 p-2 align-items-center">
                                  <div className="col-4 p-0 pd-tab-inner car_logo_open_list">
                                    <img
                                      src={
                                        v.displayPhoto
                                          ? v.displayPhoto
                                          : carkiLogo
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-lg-2 pl-0">
                                    <div className="row m-0">
                                      <div className="col p-0">
                                        <p className="mb-0 tab-head">
                                          {v.vehicleModel} {v.vehicleMake}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row m-0 mt-lg-2 mt-1">
                                      <div className="col p-0">
                                        <p className="mb-0 tab-head-light">
                                          CAT {v.coeCategory.split(" ")[0]}{" "}
                                          &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                          {v.parfEligibility
                                            ? "PARF Car"
                                            : "COE Car"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row m-0 mt-lg-2 mt-1">
                                      <div className="col-7 pl-0 d-flex cal-meter align-items-center">
                                        <img
                                          src={calendarIcon}
                                          alt=""
                                          className="mr-1"
                                        />
                                        <p className="mb-0">
                                          {FindDate(v.coeExpiryDate)}
                                        </p>
                                      </div>
                                      <div className="col-5 pr-0 pl-1 d-flex justify-content-start cal-meter align-items-center">
                                        <img
                                          src={meterIcon}
                                          alt=""
                                          className="mr-1"
                                        />
                                        <p className="mb-0">{v.maximumPower}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 pt-2 px-lg-3 px-1 tab-1-right d-flex flex-column justify-content-around">
                                <div className=" d-flex flex-column justify-content-center align-items-center">
                                  <div className="green_text pb-1">
                                    Bought for:
                                  </div>
                                  <div className="green_text">
                                    $ {v.finalSoldPrice}
                                  </div>
                                </div>
                                <div className="green_btn mb-1">
                                  <button>View Details</button>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {myBidsUsedCar.length > 2 && (
                          <div
                            onClick={() => showMoreFunc2()}
                            className="col-12 p-0 mt-3 d-flex align-items-center justify-content-center show_all_text cursor_pointer"
                          >
                            Show All{" "}
                            <i
                              className="fas fa-angle-down pl-2"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 p-0">
                      <h2 className="mb-0 mt-lg-0 mt-4 recently_heading">
                        Recently Sold
                      </h2>
                      <div className="recently_acquired_background p-3">
                        {!myListingUsedCar.length && (
                          <p className="text-center font-weight-bold">
                            No record found
                          </p>
                        )}
                        {(myListingUsedCar || []).map((v, i) => {
                          return (
                            <div
                              className="row m-0 mt-3 blue-zone cursor_pointer"
                              key={i}
                            >
                              <div className="col-8 tab-1-left p-0">
                                <div className="row m-0 p-2 align-items-center">
                                  <div className="col-4 p-0 pd-tab-inner car_logo_open_list">
                                    <img
                                      src={
                                        v.displayPhoto
                                          ? v.displayPhoto
                                          : carkiLogo
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-8 p-0 pl-lg-0 pl-md-0 pl-lg-2 pl-0">
                                    <div className="row m-0">
                                      <div className="col p-0">
                                        <p className="mb-0 tab-head">
                                          {v.vehicleModel} {v.vehicleMake}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row m-0 mt-lg-2 mt-1">
                                      <div className="col p-0">
                                        <p className="mb-0 tab-head-light">
                                          CAT {v.coeCategory.split(" ")[0]}{" "}
                                          &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                                          {v.parfEligibility
                                            ? "PARF Car"
                                            : "COE Car"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row m-0 mt-lg-2 mt-1">
                                      <div className="col-7 pl-0 d-flex cal-meter align-items-center">
                                        <img
                                          src={calendarIcon}
                                          alt=""
                                          className="mr-1"
                                        />
                                        <p className="mb-0">
                                          {FindDate(v.coeExpiryDate)}
                                        </p>
                                      </div>
                                      <div className="col-5 pr-0 pl-1 d-flex justify-content-start cal-meter align-items-center">
                                        <img
                                          src={meterIcon}
                                          alt=""
                                          className="mr-1"
                                        />
                                        <p className="mb-0">{v.maximumPower}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 pt-2 px-lg-3 px-1 tab-1-right d-flex flex-column justify-content-around">
                                <div className=" d-flex flex-column justify-content-center align-items-center">
                                  <div className="green_text pb-1">
                                    Bought for:
                                  </div>
                                  <div className="green_text">
                                    $ {v.finalSoldPrice}
                                  </div>
                                </div>
                                <div className="green_btn mb-1">
                                  <button>View Details</button>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {myListingUsedCar.length > 2 && (
                          <div
                            onClick={() => showMoreFunc3()}
                            className="col-12 p-0 mt-3 d-flex align-items-center justify-content-center show_all_text cursor_pointer"
                          >
                            Show All{" "}
                            <i
                              className="fas fa-angle-down pl-2"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyProfileEdit;
