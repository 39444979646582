import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Home from "../views/static_pages/home";
import Sell from "../views/static_pages/sell";
import Financing from "../views/static_pages/financing";
import Workshops from "../views/static_pages/workshops";
import Buy from "../views/static_pages/Buy";
import Login from "../views/static_pages/Login";
import Register from "../views/static_pages/Register";
import ResetPassword from "../views/static_pages/ResetPassword";
import PartnerIndividualBid from "../views/static_pages/PartnerPages/PartnerIndividualBid";
import Insurance from "../views/static_pages/Insurance";
import CarLoanNew from "../views/static_pages/FinancialModules/CarLoanNew";
import CarLoanUsed from "../views/static_pages/FinancialModules/CarLoanUsed";
import CarLoanRoadTax from "../views/static_pages/FinancialModules/CarLoanRoadTax";
import sellSteps from "../views/static_pages/sellSteps";
import PartnerDashboard from "../views/static_pages/PartnerPages/PartnerDashboard";
import PartnerCreateNewListing from "../views/static_pages/PartnerPages/PartnerCreateNewListing";
import ManageOpenListing from "../views/static_pages/PartnerPages/ManageOpenListing";
import IndividualListingViewOpen from "../components/PartnerComponents/IndividualListingViewOpen";
import LeadSquezePage from "../views/static_pages/FinancialModules/LeadSquezePage";
import ThankYouPage from "../views/static_pages/FinancialModules/ThankYouPage";
import MemberHomePage from "../views/static_pages/MemberPages/MemberHomePage";
import MyProfileView from "../views/static_pages/PartnerPages/MyProfileView";
import MyProfileEdit from "../views/static_pages/PartnerPages/MyProfileEdit";
import ManageOpenBids from "../views/static_pages/PartnerPages/ManageOpenBids";
import ViewClosedListing from "../views/static_pages/PartnerPages/ViewClosedListing";
import IndividualListingViewClosed from "../components/PartnerComponents/IndividualListingViewClosed";
import IndividualBidViewClosed from "../components/PartnerComponents/IndividualBidViewClosed";
import IndividualBidViewOpenExistingBidder from "../components/PartnerComponents/IndividualBidViewOpenExistingBidder";
import IndividualBidViewOpenNewBidder from "../components/PartnerComponents/IndividualBidViewOpenNewBidder";
import ViewBidHistory from "../views/static_pages/PartnerPages/ViewBidHistory";

const routes = () => {
  const { pathname } = useLocation();
  let accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/partner-change-password" component={ResetPassword} />
      <Route path="/sell" component={Sell} exact />
      <Route path="/sell-steps" component={sellSteps} />
      <Route path="/buy" component={Buy} />
      <Route path="/financing" component={Financing} />
      <Route path="/car-loan-new" component={CarLoanNew} />
      <Route path="/car-loan-used" component={CarLoanUsed} />
      <Route path="/car-loan-roadTax" component={CarLoanRoadTax} />
      <Route path="/workshops" component={Workshops} />
      <Route path="/insurance" component={Insurance} />
      <Route path="/lead-squeze" component={LeadSquezePage} />
      <Route path="/thank-you" component={ThankYouPage} />
      {accessToken && (
        <>
          <Route path="/partner-dashboard" component={PartnerDashboard} />
          <Route
            path="/partner-create-listing"
            component={PartnerCreateNewListing}
          />
          <Route
            path="/manage-open-listing"
            exact
            component={ManageOpenListing}
          />
          <Route
            path="/manage-open-listing/:id"
            component={IndividualListingViewOpen}
          />
          <Route
            path="/view-closed-listing"
            exact
            component={ViewClosedListing}
          />
           <Route
            path="/view-closed-listing/:id"
            exact
            component={IndividualListingViewClosed}
          />
          <Route path="/manage-open-bids" exact component={ManageOpenBids} />
          <Route path="/view-closed-bid" exact component={ViewBidHistory} />
          <Route
            path="/manage-open-bids/:id"
            exact
            component={IndividualBidViewClosed}
          />
           <Route
            path="/manage-open-bids/open-existing-bid/:id"
            component={IndividualBidViewOpenExistingBidder}
          />
              <Route
            path="/manage-open-bids/open-new-bid/:id"
            component={IndividualBidViewOpenNewBidder}
          />
          {/* <Route path="/partner-individual-bid" component={PartnerIndividualBid} /> */}
          <Route path="/my-profile/view" component={MyProfileView} />
          <Route path="/my-profile/edit" component={MyProfileEdit} />
          <Route path="/member-homepage" component={MemberHomePage} />
        </>
      )}
      <Route path="/notFound" />
      <Redirect from="*" to="/notFound" />
    </Switch>
  );
};
export default routes;
