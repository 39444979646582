import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/img/carki_footer_logo.png";
import "../css/footer.css";

const footer = () => (
  <footer className="footer-section">
    <div className="footer-nav mb-4">
      <nav className="navbar navbar-expand-lg  container navbar-primary pt-3 px-lg-2 px-4">
        <Link className="footer-brand" to="/">
          <img className="footer-brand-img" src={logo} alt="navbar brand" />
        </Link>
        <ul className="navbar-nav d-lg-none d-md-none d-flex flex-row">
            <li className="nav-item">
              <a href="#" className="nav-link social-icons">
                <i className="fab fa-facebook-square mr-3" style={{fontSize: '34px'}}></i>
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link social-icons">
                <i className="fab fa-facebook-messenger" style={{fontSize: '34px'}}></i>
              </a>
            </li>
          </ul>
        <div className="navbar-collapse justify-content-between" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link text-secondary" href="#">
                Help/FAQs
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-secondary" href="#">
                Locate Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-secondary" href="#">
                Enquiries
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-secondary" href="#">
                Join the Carki Community
              </a>
            </li>
          </ul>

          <ul className="navbar-nav d-lg-flex d-md-flex d-none">
            <li className="nav-item">
              <a href="#" className="nav-link social-icons">
                <i className="fab fa-facebook-square"></i>
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link social-icons">
                <i className="fab fa-facebook-messenger"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* <div className="footer-border container px-3"></div> */}
    </div>

    <div className="footer-copy text-center px-lg-0 px-md-0 px-3">
      Copyright &copy; 2021 Carki Pte Ltd. All Rights Reserved.
    </div>
  </footer>
);

export default footer;
