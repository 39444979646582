import React from "react";
import { Button } from "react-bootstrap";
import Previous from "../../images/icons/Previous.png";
import Next from "../../images/icons/Next.png";
import { useHistory } from "react-router-dom";

const CreateListing7 = () => {
  let history = useHistory();

  return (
    <div className="details_background">
      <div className="row m-0 align-items-center back_button">
        <p className="col-lg-12 col-sm-12 col-md-12 p-0 mb-0 bid_details_title">
          Create New Listing
        </p>
      </div>

      <div className="row m-0 align-items-center justify-content-center details_background_2">
        <div className="col-lg-6 p-0">
          <div className="row m-0">
            <div className="col-lg-12 p-0 pb-3">
              <h3 className="listing-head-1">
                Your listing has been submitted for reviewing!
              </h3>
            </div>
          </div>

          <div className="row m-0 mr-lg-0 ml-md-0 ml-lg-0 mr-md-0 mr-3 ml-3">
            <div className="col-lg p-4 listing-inner-one text-center">
              <p className="listing-thanx-page">
                The Carki team will review your listing <br /> application
                shortly and will notify you in <br />
                <span>1 - 2 business days</span> if your listing is <br />{" "}
                successful.
              </p>
              <br />
              <p className="listing-thanx-page">Thank you for using Carki!</p>
            </div>
          </div>
          <div className="row m-0 p-4 listing-btn align-items-center justify-content-center">
            <div className="col-lg-12 return-btn d-flex align-items-center justify-content-center">
              <Button onClick={() => history.push("/partner-dashboard")}>
                Return to Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateListing7;
