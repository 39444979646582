import moment from "moment";

export const FindDate = (value) => {
  let date = new Date(value);
  let finalDate = `${date.getDate()}/${date.getMonth() + 1
    }/${date.getFullYear()}`;
  return finalDate;
};

export const FindDateWithTime = (value) => {
  let date = new Date(value);
  let finalDate = `${date.getDate()}/${date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  return finalDate;
};

export const getMonthByNameFunc = (value) => {
  let d = new Date(value);
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return d.getDate() + " " + month[d.getMonth() + 1] + " " + d.getFullYear();
};

export const getMonthByNameFunc2 = (value) => {
  let d = new Date(value);
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear();
};

export const FindBidCloseTime = (closeBidDate) => {
  if (closeBidDate) {
    let findUTCdate = new Date(closeBidDate);
    let finalUTCdate = `${findUTCdate.getFullYear()}-${findUTCdate.getMonth() + 1
      }-${findUTCdate.getDate()} ${findUTCdate.getUTCHours()}:${findUTCdate.getUTCMinutes()}:${findUTCdate.getUTCSeconds()}`;
    let dateFuture = new Date(finalUTCdate);
    let FindDateNow = new Date();

    let currentUTCdate = `${FindDateNow.getFullYear()}-${FindDateNow.getMonth() + 1
      }-${FindDateNow.getDate()} ${FindDateNow.getUTCHours()}:${FindDateNow.getUTCMinutes()}:${FindDateNow.getUTCSeconds()}`;
    let dateNow = new Date(currentUTCdate);
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    const diffTime = Math.abs(dateFuture - dateNow);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let seconds = Math.floor((dateFuture - dateNow) / 1000) % 60;
    let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  
    let days = diffDays === 0 ? 0 : diffDays - 1;
    if (String(hours).length === 1) {
      hours = `0${hours}`;
    }
    if (String(minutes).length === 1) {
      minutes = `0${minutes}`;
    }
    if (String(seconds).length === 1) {
      seconds = `0${seconds}`;
    }
    return days + "D" + " " + hours + ":" + minutes + ":" + seconds;
  }
};

export const CompareCurrentTime = (value) => {
  let date = new Date(value);
  let FindDateNow = new Date();
  if(date.getTime() < FindDateNow.getTime()) {
    return true
  }
  else{
    return false;
  }
};

const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
const arrayRange = range(currentYear, currentYear - 15, -1);

export const yearArray = arrayRange.map((v) => {
  return {
    label: v,
    value: v,
  };
});


export const monthPeriodArray = [

  {
    label: "DEC, 2nd Bidding",
    value: "12 2",
  },
  {
    label: "DEC, 1st Bidding",
    value: "12 1",
  },

  {
    label: "NOV, 2nd Bidding",
    value: "11 2",
  },
  {
    label: "NOV, 1st Bidding",
    value: "11 1",
  },

  {
    label: "OCT, 2nd Bidding",
    value: "10 2",
  },
  {
    label: "OCT, 1st Bidding",
    value: "10 1",
  },

  {
    label: "SEP, 2nd Bidding",
    value: "09 2",
  },
  {
    label: "SEP, 1st Bidding",
    value: "09 1",
  },
  {
    label: "AUG, 2nd Bidding",
    value: "08 2",
  },
  {
    label: "AUG, 1st Bidding",
    value: "08 1",
  },


  {
    label: "JUL, 2nd Bidding",
    value: "07 2",
  },
  {
    label: "JUL, 1st Bidding",
    value: "07 1",
  },

  {
    label: "JUN, 2nd Bidding",
    value: "06 2",
  },
  {
    label: "JUN, 1st Bidding",
    value: "06 1",
  },

  {
    label: "MAY, 2nd Bidding",
    value: "05 2",
  },
  {
    label: "MAY, 1st Bidding",
    value: "05 1",
  },

  {
    label: "APR, 2nd Bidding",
    value: "04 2",
  },
  {
    label: "APR, 1st Bidding",
    value: "04 1",
  },

  {
    label: "MAR, 2nd Bidding",
    value: "03 2",
  },
  {
    label: "MAR, 1st Bidding",
    value: "03 1",
  },

  {
    label: "FEB, 2nd Bidding",
    value: "02 2",
  },
  {
    label: "FEB, 1st Bidding",
    value: "02 1",
  },

  {
    label: "JAN, 2nd Bidding",
    value: "01 2",
  },
  {
    label: "JAN, 1st Bidding",
    value: "01 1",
  },

];

export const change0ToNA = (value) => {
  return value === 0 ? "N/A" : value;
}

export const FindTimeAgo = (value) => {
  const dateTimeAgo = moment(value).fromNow();
  return dateTimeAgo;
}