import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import GvMotorworks1 from "../../images/img/gv_motorworks_1.png";
import Tyre1 from "../../images/img/tyre_1.png";
import CarBattery1 from "../../images/img/car_battery_1.png";
import OilChange1 from "../../images/img/oil_change_1.png";
import AirConditioning1 from "../../images/img/air_conditioning_1.png";
import BrakePads1 from "../../images/img/brake_pads_1.png";
import Timing1 from "../../images/img/timing_1.png";
import { Button, FormControl, InputGroup } from "react-bootstrap";

const Workshops = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid workshop-cont-1 d-flex flex-column justify-content-center">
        <div className="work-jumbo-1 font-weight-bolder text-center bg-transparent">
          <h1>
            Search Car Workshops And
            <br />
            Repair Services Easily
          </h1>
          <p>
            Check out our catalogue of reliable workshops proven to be quality
            <br />
            service providers in the auto industry.
          </p>
        </div>
        <div className="work-jumbo-2 d-flex flex-column justify-content-center align-items-center bg-transparent">
          <form>
            <div className="form-group row">
              <div className="col-xs-4 mr-lg-3 mr-md-3 mr-0">
                <InputGroup size="lg">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-lg">
                      <i className="fas fa-map-marker-alt"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Large"
                    placeholder="Search via district or postal code... "
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </InputGroup>
              </div>
              <div className="col-xs-4 mr-lg-3 mr-md-3 mr-0 mt-lg-0 mt-md-0 mt-4">
                <InputGroup size="lg">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-lg"></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Large"
                    placeholder="Choose type of service"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </InputGroup>
              </div>
              <div className="col-xs-3 search_btn_workshop mt-lg-0 mt-lg-0 mt-5">
                <Button variant="success" size="lg">
                  Search
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid workshop-cont-2 d-flex flex-column justify-content-center position-relative">
        <div className="whatsapp-container whatsapp-container-section whatsapp-container whatsapp-container-workshop">
          <img src="../images/img/whatsapp_logo_1_1.png" alt="" />
        </div>
        <div className="row row-head-1">
          <h3>Recommended Workshops</h3>
        </div>
        <div className="row row-head-2">
          <div className="row-cont">
            <div className="m-0 d-flex justify-content-center align-items-center workshop_cards">
              <div className="mx-2 px-0">
                <p className="mb-1 text-right w-100 sponsored_result">
                  Sponsored Result
                </p>
                <div className="work-img-cont">
                  <div className="work-img">
                    <img
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                      src={GvMotorworks1}
                      alt=""
                    />
                  </div>
                  <div className="work-content px-3">
                    <h4>GV Motorworks Pte Ltd</h4>
                    <p>68 Kaki Bukit Ave 6, #01-17, Singapore 417883</p>
                    <p>5.0 Google Reviews</p>
                    <p>Phone Number: +65 6844 9201</p>
                    <a href="#" className="right-aln">
                      <p>View More</p>
                    </a>
                  </div>
                </div>
              </div>

              <div className="mx-2 px-0">
                <p className="mb-1 text-right w-100 sponsored_result">
                  Sponsored Result
                </p>
                <div className="work-img-cont">
                  <div className="work-img">
                    <img
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                      src={GvMotorworks1}
                      alt=""
                    />
                  </div>
                  <div className="work-content px-3">
                    <h4>GV Motorworks Pte Ltd</h4>
                    <p>68 Kaki Bukit Ave 6, #01-17, Singapore 417883</p>
                    <p>5.0 Google Reviews</p>
                    <p>Phone Number: +65 6844 9201</p>
                    <a href="#" className="right-aln">
                      <p>View More</p>
                    </a>
                  </div>
                </div>
              </div>

              <div className="mx-2 px-0">
                <p className="mb-1 text-right w-100 sponsored_result">
                  Sponsored Result
                </p>
                <div className="work-img-cont">
                  <div className="work-img">
                    <img
                      style={{ borderRadius: "8px 8px 0px 0px" }}
                      src={GvMotorworks1}
                      alt=""
                    />
                  </div>
                  <div className="work-content px-3">
                    <h4>GV Motorworks Pte Ltd</h4>
                    <p>68 Kaki Bukit Ave 6, #01-17, Singapore 417883</p>
                    <p>5.0 Google Reviews</p>
                    <p>Phone Number: +65 6844 9201</p>
                    <a href="#" className="right-aln">
                      <p>View More</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm more-img-cont d-flex justify-content-center align-items-center">
            <a href="#">
              <h5>
                View More <i className="ri-arrow-right-s-line"></i>
              </h5>
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid workshop-cont-3">
        <div className="row row-head-3">
          <h3>Popular Services</h3>
        </div>

        <div className="row row-icon-cont-1 d-flex justify-content-center align-items-center">
          <div className="col-sm-3 work-icon-cont mx-2 px-0 d-flex flex-column justify-content-center align-items-center">
            <img src={Tyre1} alt="" />
            <h5>Tyre Replacement</h5>
          </div>
          <div className="col-sm-3 work-icon-cont work-icon-cont-dark  mx-2 px-0 d-flex flex-column justify-content-center align-items-center">
            <img src={CarBattery1} alt="" />
            <h5>Battery Replacement</h5>
          </div>
          <div className="col-sm-3 work-icon-cont mx-2 px-0 d-flex flex-column justify-content-center align-items-center">
            <img src={OilChange1} alt="" />
            <h5>Oil Regulation</h5>
          </div>
        </div>

        <div className="row row-icon-cont-2 d-flex justify-content-center align-items-center">
          <div className="col-sm-3 work-icon-cont work-icon-cont-dark mx-2 my-3 px-0 d-flex flex-column justify-content-center align-items-center">
            <img src={BrakePads1} alt="" />
            <h5>Brake Pads Replacement</h5>
          </div>
          <div className="col-sm-3 work-icon-cont mx-2 my-3 px-0 d-flex flex-column justify-content-center align-items-center">
            <img src={Timing1} alt="" />
            <h5>Gear Timing</h5>
          </div>
          <div className="col-sm-3 work-icon-cont work-icon-cont-dark mx-2 px-0 d-flex flex-column justify-content-center align-items-center">
            <img src={AirConditioning1} alt="" />
            <h5>Aircon Maintenance</h5>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Workshops;
