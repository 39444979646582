import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Dropdown from "../../components/common/dropdown";
import sell from "../../images/svg-icons/sell.svg";
import scrapCar from "../../images/svg-icons/scrap_car.svg";
import renewal from "../../images/svg-icons/renewal.svg";
import rental from "../../images/svg-icons/rental.svg";
import moneyBox from "../../images/svg-icons/money-box.svg";
import loadA from "../../images/svg-icons/load-a.svg";
import insurance from "../../images/svg-icons/Insurance.svg";
import car from "../../images/img/image.png";
import carLoan from "../../images/img/car-loan-1.png";
import playBtn from "../../images/svg-icons/play-btn.svg";
import arrowUp from "../../images/svg-icons/up_arrow.svg";
import arrowDown from "../../images/svg-icons/down-arrow.svg";
import fast from "../../images/svg-icons/fast.svg";
import quality from "../../images/svg-icons/quality.svg";
import intrest2 from "../../images/svg-icons/interest2.svg";
import DummyImg from "../../images/Photos/DummyImg.jpg";
import CalcBackground from "../../images/Photos/CalcBackground.png";
import CalcMobileBackground from "../../images/Photos/CalcMobileBackground.png";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import Slider from "react-slick";
import { GetCoeResults, submiReqFormData } from "../../utils/apiUrls";
import { toast } from "react-toastify";
import {
  change0ToNA,
  monthPeriodArray,
  yearArray,
} from "../../utils/constants";

let settings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 2500,
  infinite: true,
  // prevArrow: <button>asda</button>,
  // nextArrow: <button>asdsad</button>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const n = 9;
let date = new Date();
let monthArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Home = (props) => {
  let currentDate = new Date();
  let accessToken = localStorage.getItem("accessToken");
  const [loanAmt, setLoanAmount] = useState(0);
  const [intRate, setIntRate] = useState(1.88);
  const [years, setYears] = useState("0");
  const [months, setMonths] = useState("0");
  const [showDropdownForYears, setshowDropdownForYears] = useState(false);
  const [showPaybleAmtBox, setShowPaybleAmtBox] = useState(false);
  const [coeData, setCOEdata] = useState([]);
  const [coeDataLimit, setCoeDataLimit] = useState(3);
  const [coeDataSkip, setCoeDataSkip] = useState(0);

  const [payable_amt, set_payable_amt] = useState(0);

  const [coestartDateFilter, setcoestartDateFilter] = useState("");
  const [coebiddingFilter, setcoebiddingFilter] = useState(2);

  const [categoryFilter, setCategoryFilter] = useState("Category A");

  const [totalInt, setTotalInterest] = useState(0);

  const [selectedMonth, setselectedMonth] = useState("");
  const [getCoeResultBoolean, setgetCoeResultBoolean] = useState(true);
  const [selectedCarTaBvalue, setCarTaBvalue] = useState("PARF");

  const [carPrice, setCarPrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [ofCarPrice, setOfCarPrice] = useState(0);

  const [loanStartDate, setLoanStartDate] = useState("July");
  const [loanStartYear, setLoanStartYear] = useState("2021");
  const [showDropdown, setshowDropdown] = useState("");
  const [offPeakvalue, setOffPeakvalue] = useState("no");

  const [showValidation, setshowValidation] = useState(false);
  // const [OMVValue, setOmvValue] = useState(0);
  // const [arfValue, setarfValue] = useState("");
  // const [vesValue, setVesValue] = useState(`B - $${0} (Neutral)`);
  // const [catValue, setCatValue] = useState("A");

  // const [coeValue, setCoeValue] = useState(0);
  // const [offPeakvalue, setOffPeakvalue] = useState("no");

  useEffect(() => {
    if (accessToken) {
      props.history.push("/partner-dashboard");
    }
    let date = new Date();
    let passDate = `
    ${('0' + (date.getMonth() + 1)).slice(-2)}/${"01"}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    setcoestartDateFilter(passDate);
    GetCoeResultsFunc(coeDataSkip, passDate, coebiddingFilter);
  }, [accessToken]);


  useEffect(() => {
    //Calculate down payment below
    let downPaymentValue = (Number(carPrice) * Number(ofCarPrice)) / 100;
    setDownPayment(downPaymentValue);
  }, [carPrice, ofCarPrice]);
  // useEffect(() => {

  //   GetCoeResultsFunc(coeDataSkip, coestartDateFilter, coebiddingFilter);
  // }, [coestartDateFilter, coebiddingFilter]);

  const GetCoeResultsFunc = (skip, startDate, coebiddingFilter) => {
    console.log("startDate", startDate);
    let newDate = new Date(startDate);
    console.log("newDate", newDate);
    GetCoeResults(
      coeDataLimit,
      skip,
      newDate.toISOString(),
      coebiddingFilter
    ).payload.then((res) => {
      if (res.data.coeArray.length) {
        setCOEdata(res.data.coeArray);
      }
      else {
        setCOEdata([]);

        if (coeDataSkip > 0) {
          setCoeDataSkip(0);
          GetCoeResultsFunc(0, coestartDateFilter, coebiddingFilter);
        }
      }

      // if (!res.data.coeArray.length && getCoeResultBoolean) {
      //   console.log(!res.data.coeArray.length && getCoeResultBoolean)
      //   setselectedMonth(`0${newDate.getMonth() + 1} 1`);
      //   console.log(`${newDate.getMonth()} 1`);
      //   GetCoeResultsFunc(coeDataSkip, startDate, 1);
      //   setgetCoeResultBoolean(false);
      // }
    });
  };

  const showMoreCoeResults = () => {
    let date = new Date();
    let currentDate = `${date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    let passDate = coestartDateFilter ? coestartDateFilter : currentDate;
    GetCoeResultsFunc(coeDataSkip + 3, passDate, coebiddingFilter);
    setCoeDataSkip(coeDataSkip + 3);
  };

  const calculateCarLoan = () => {
    if (loanAmt < 10000) {
      setShowPaybleAmtBox(false);
      setshowDropdownForYears(false);
    } else {
      let TotalInterest = (Number(loanAmt) * (Number(intRate) / 100) * (Number(years) + (Number(months) / 12)));
      setTotalInterest(TotalInterest.toFixed(0));
      let TotalLoan = TotalInterest + Number(loanAmt);

      let totalLoanTenure = Number(years) * 12 + Number(months);

      let MonthlyInstallment = TotalLoan / totalLoanTenure;
      set_payable_amt(MonthlyInstallment.toFixed(2));
      setShowPaybleAmtBox(true);
      window.scroll({
        top: 1600,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const calculateCarLoanParf = () => {
    let loanAmount =
      offPeakvalue === "yes"
        ? Number(carPrice) - Number(downPayment) - 17000
        : Number(carPrice) - Number(downPayment);
    let TotalInterest =
      Number(loanAmount) * ((Number(intRate) / 100) * Number(years));
    let TotalLoan = TotalInterest + Number(loanAmount);

    let totalLoanTenure = (Number(years) * 12) + Number(months);
    setTotalInterest(TotalInterest.toFixed(0))
    let MonthlyInstallment = TotalLoan / totalLoanTenure;
    set_payable_amt(MonthlyInstallment.toFixed(2));
    setShowPaybleAmtBox(true);
    window.scroll({
      top: 1600,
      left: 0,
      behavior: "smooth",
    });
  };

  const resetFields = () => {
    setLoanAmount(0);
    setIntRate(2.88);
    setYears("0");
    setMonths("0");
    setShowPaybleAmtBox(false);
    setshowDropdownForYears(false);
    set_payable_amt(0);
    window.scroll({
      top: 850,
      left: 0,
      behavior: "smooth",
    });
  };

  const [vehiclePlateNo, setVehiclePlate] = useState("");
  const [name, setName] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  const SubmitData = () => {
    if (name && mobileNo && vehiclePlateNo) {
      setshowValidation(false);
      let obj = {
        type: "finance",
        name,
        mobile: mobileNo,
        additionalInfo: {
          vehicleNumber: vehiclePlateNo,
        },
      };
      submiReqFormData(obj)
        .payload.then((res) => {
          toast.success(res.message);
          setmobileNo("");
          setName("");
          setVehiclePlate("");
        })
        .catch((e) => {
          toast.error(e.data.message);
        });
    }
    else {
      setshowValidation(true);
    }
  };

  // const addCOEexpiryDate = (e) => {
  //   let coeDate = new Date(e.target.value);
  //   let date = new Date();
  //   let findDate = `${date.getFullYear()}-${date.getMonth()
  //     }-${date.getDate()}`;
  //   let currentDate = new Date(findDate);
  //   var diffYear = (currentDate.getTime() - coeDate.getTime()) / 1000;
  //   diffYear /= 60 * 60 * 24;
  //   let diffYearValue = Math.abs(Math.round(diffYear / 365.25));

  //   // var diffMonth = (currentDate.getTime() - coeDate.getTime()) / 1000;
  //   // diffMonth /= 60 * 60 * 24 * 7 * 4;
  //   // let diffMonthValue = Math.abs(Math.round(diffMonth));
  //   let diffMonthValue = (coeDate.getFullYear() - currentDate.getFullYear()) * 12 + (coeDate.getMonth() - currentDate.getMonth());
  //   let findOffset = diffMonthValue - 3;
  //   if(findOffset < 0) {
  //     diffYearValue = diffYearValue - 1;
  //   }
  //   else {
  //     diffMonthValue = diffMonthValue - 3;
  //   }
  //   console.log(diffMonthValue, diffYearValue)
  //   // diffMonthValue = diffMonthValue - (diffYearValue * 12);
  //   // setYears(String(diffYearValue));
  //   // setMonths(String(diffMonthValue));
  // };

  const addCOEexpiryDate = (e, str) => {
    let findCOEDate = new Date(e.target.value);
    let currentDate = new Date();
    let findMonthsDiff;
    if (findCOEDate.getTime() > currentDate.getTime()) {
      findMonthsDiff =
        (findCOEDate.getFullYear() - currentDate.getFullYear()) * 12 +
        (findCOEDate.getMonth() - currentDate.getMonth());
    } else {
      findMonthsDiff =
        (findCOEDate.getFullYear() - currentDate.getFullYear()) * 12 +
        Math.abs(findCOEDate.getMonth() - currentDate.getMonth());
    }
    if (str === "coeCar") {

      if (findMonthsDiff < -3) {
        findMonthsDiff = findMonthsDiff + 3;
      }
      if (findMonthsDiff > 3) {
        findMonthsDiff = findMonthsDiff - 3;
      }
    }
    let diffYear = Math.trunc((findMonthsDiff / 12));
    let diffMonths = (diffYear * 12) - findMonthsDiff;
    diffMonths = Math.abs(diffMonths);
    diffYear = Math.abs(diffYear);
    setYears(String(diffYear));
    setMonths(String(diffMonths));
  };

  return (
    <React.Fragment>
      <Header />

      {/* <div className="hero-container">
              <div className="hero-clipath">
                <div className="hero-content">
                  <p className="hero-text">COE prices surge ahead of another supply cut spile is biggest in over 10 years - The
                    Straits Times</p>
                </div>
              </div>
            </div> */}

      <div id="navHeadLine" className="col-lg-9">
        <h5>
          COE prices surge ahead of another supply cut; spike is biggest in over
          10 years -{" "}
          <span style={{ color: "#C4C4C4", fontWeight: "500" }}>
            The Straits Times
          </span>
        </h5>
      </div>

      <div className="content mx-auto">
        <div className="table-content">
          <table className="table table-bordered d-lg-table d-md-table d-none">
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ color: "#F5861F", width: "430px" }}
                >
                  COE Results
                </th>
                {coeData
                  .sort((a, b) => { return a.id - b.id })
                  .sort((a, b) => `` + a.createdAt.localeCompare(b.createdAt))
                  .map((v, i) => {
                    return (
                      <th className="coe-tab" key={i}>
                        <div className="tab-header-contt w-100 row_header row m-0">
                          <div className="col-4 p-0">
                            {" "}
                            <img
                              className="pr-2"
                              src={v.pqpChange > 0 ? arrowUp : arrowDown}
                              alt=""
                            />
                            CAT {v.category.split(" ")[1]}
                          </div>
                          <div className="col-2 p-0">
                            {monthArr[date.getMonth() - 1]}
                          </div>
                          <div className="col-2 p-0 d-flex justify-content-end">
                            {monthArr[date.getMonth()]}
                          </div>
                        </div>
                      </th>
                    );
                  })}

                <th></th>

                {coeData.length === 1 && <>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                  <th className="coe-tab border_none"></th>
                </>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  scope="row"
                // className="d-flex align-items-center justify-content-center"
                >
                  <div className="d-flex align-items-center justify-content-center select-bar mt-2">
                    <div className="position-relative">
                      <select
                        onChange={(e) => {
                          let d = new Date();
                          let value = e.target.value;
                          let splitValue = value.split(" ");
                          let splitValue2 = coestartDateFilter
                            .split("-")[2]
                            .split(" ");

                          let date = `${splitValue[0]}-01-${splitValue2[0]} ${(
                            "0" + d.getHours()
                          ).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}:01`;

                          GetCoeResultsFunc(
                            coeDataSkip,
                            date,
                            Number(splitValue[1])
                          );
                          setcoestartDateFilter(date);
                          setcoebiddingFilter(Number(splitValue[1]));
                          setCoeDataSkip(0);
                        }}
                      >
                        {[...monthPeriodArray].reverse().splice(0, (currentDate.getMonth() + 1) * 2).reverse().map((v, i) => {

                          return (
                            <option
                              key={i}
                              value={v.value}
                              selected={v.value == selectedMonth}
                            >
                              {v.label}
                            </option>
                          );
                        })}
                      </select>
                      <div className="select-bar-arrows">
                        <i class="fas fa-chevron-up"></i>
                        <i class="fas fa-chevron-down"></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center select-bar mt-3">
                    <div className="position-relative">
                      <select
                        className="select-1"
                        onChange={(e) => {
                          let d = new Date();
                          let value = e.target.value;
                          let startDate = coestartDateFilter.split("-");
                          let newStartDate = `${startDate[0]}-${startDate[1]
                            }-${value} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
                          GetCoeResultsFunc(
                            coeDataSkip,
                            newStartDate,
                            coebiddingFilter
                          );
                          setcoestartDateFilter(newStartDate);
                          setCoeDataSkip(0);
                        }}
                      >
                        {yearArray.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                      </select>
                      <div className="select-bar-arrows">
                        <i class="fas fa-chevron-up"></i>
                        <i class="fas fa-chevron-down"></i>
                      </div>
                    </div>
                  </div>
                </th>
                {coeData.map((v, i) => {
                  return (
                    <td className="coe-tab" key={i}>
                      <div className="tab-header-contt w-100 row row_td m-0">
                        <div className="col-4 p-0 head_1">COE</div>
                        <div className="col-3 p-0 amt_1">${v.premium}</div>
                        <div className="col-1 p-0">
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                        <div className="col-3 p-0 amt_2">${v.premium}</div>
                      </div>
                      <div
                        className="d-flex justify-content-end pt-2 amt_3"
                        style={{
                          color: v.premiumChange > 0 ? "#33D00C" : "#FF0000",
                        }}
                      >
                        {v.premiumChange > 0 ? "+" : "-"} $
                        {change0ToNA(Math.abs(v.premiumChange))} &nbsp;&nbsp;{" "}
                        {change0ToNA(v.premiumChangePercentage)}
                      </div>

                      <div className="tab-header-contt w-100 row row_td m-0 mt-3">
                        <div className="col-4 p-0 head_1">PQP</div>
                        <div className="col-3 p-0 amt_1">
                          ${change0ToNA(v.pqpCurrentMonth)}
                        </div>
                        <div className="col-1 p-0">
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                        <div className="col-3 p-0 amt_2">
                          ${change0ToNA(v.pqpNextMonth)}
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-end pt-2 amt_3"
                        style={{
                          color: v.pqpChange < 0 ? "#33D00C" : "#FF0000",
                        }}
                      >
                        {v.pqpChange > 0 ? "+" : "-"} $
                        {change0ToNA(Math.abs(v.pqpChange))} &nbsp;&nbsp;{" "}
                        {change0ToNA(v.pqpChangePercetage)}
                      </div>
                    </td>
                  );
                })}

                <td
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    border: "none",
                    height: "130px",
                    fontSize: 26,
                    color: "#EB8524",
                    borderRight: coeData.length === 1 && "1px solid #dee2e6"
                  }}
                >
                  {coeData.length ? (
                    <i
                      className="fas fa-chevron-right cursor_pointer"
                      onClick={() => showMoreCoeResults()}
                    ></i>
                  ) : (
                    <p className="mb-0 mt-3">No results found</p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="table table-bordered d-lg-none d-md-none d-table"
            style={{ border: "none" }}
          >
            <thead>
              <tr>
                <th className="text-center" style={{ color: "#F5861F" }}>
                  COE Resultss
                </th>
                {coeData
                  .filter((v) => v.category === categoryFilter)
                  .map((v, i) => {
                    return (
                      <th className="coe-tab" key={i}>
                        <div className="tab-header-contt w-100 row_header row m-0">
                          <div className="col-4 p-0">
                            {" "}
                            <img className="pr-2" src={arrowUp} alt="" />
                            CAT {v.category.split(" ")[1]}
                          </div>
                          <div className="col-2 p-0">
                            {monthArr[date.getMonth() - 1]}
                          </div>
                          <div className="col-2 p-0 d-flex justify-content-end">
                            {monthArr[date.getMonth()]}
                          </div>
                        </div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  scope="row"
                // className="d-flex align-items-center justify-content-center"
                >
                  <div className="d-flex align-items-center justify-content-center select-bar mt-0">
                    <div className="position-relative">
                      <select
                        onChange={(e) => {
                          let d = new Date();
                          let value = e.target.value;
                          let splitValue = value.split(" ");
                          let splitValue2 = coestartDateFilter
                            .split("-")[2]
                            .split(" ");

                          let date = `${splitValue[0]}-01-${splitValue2[0]} ${(
                            "0" + d.getHours()
                          ).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}:01`;

                          GetCoeResultsFunc(
                            coeDataSkip,
                            date,
                            Number(splitValue[1])
                          );
                          setcoestartDateFilter(date);
                          setcoebiddingFilter(Number(splitValue[1]));
                        }}
                      >
                        {[...monthPeriodArray].reverse().splice(0, (currentDate.getMonth() + 1) * 2).reverse().map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                      </select>
                      <div className="select-bar-arrows">
                        <i class="fas fa-chevron-up"></i>
                        <i class="fas fa-chevron-down"></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center select-bar mt-4">
                    <div className="position-relative">
                      <select
                        onChange={(e) => {
                          let d = new Date();
                          let value = e.target.value;
                          let startDate = coestartDateFilter.split("-");
                          let newStartDate = `${startDate[0]}-${startDate[1]
                            }-${value} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
                          GetCoeResultsFunc(
                            coeDataSkip,
                            newStartDate,
                            coebiddingFilter
                          );
                          setcoestartDateFilter(newStartDate);
                        }}
                      >
                        {yearArray.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                      </select>
                      <div className="select-bar-arrows">
                        <i class="fas fa-chevron-down fa-chevron-down-2"></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center select-bar mt-4">
                    <div className="position-relative">
                      <select
                        onChange={(e) => setCategoryFilter(e.target.value)}
                      >
                        <option value="Category A">CAT A</option>
                        <option value="Category B">CAT B</option>
                        <option value="Category C">CAT C</option>
                        <option value="Category D">CAT D</option>
                        <option value="Category E">CAT E</option>
                      </select>
                      <div className="select-bar-arrows">
                        <i class="fas fa-chevron-down fa-chevron-down-2"></i>
                      </div>
                    </div>
                  </div>
                </th>
                {coeData
                  .filter((v) => v.category === categoryFilter)
                  .map((v, i) => {
                    return (
                      <td className="coe-tab" key={i}>
                        <div className="tab-header-contt w-100 row row_td m-0">
                          <div className="col-4 p-0 head_1">COE</div>
                          <div className="col-3 p-0 amt_1">${v.premium}</div>
                          <div className="col-1 p-0">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </div>
                          <div className="col-3 p-0 amt_2">${v.premium}</div>
                        </div>
                        <div className="d-flex justify-content-end pt-2 amt_3">
                          ${change0ToNA(v.premiumChange)} &nbsp;&nbsp;{" "}
                          {change0ToNA(v.premiumChangePercentage)}
                        </div>

                        <div className="tab-header-contt w-100 row row_td m-0 mt-3">
                          <div className="col-4 p-0 head_1">PQP</div>
                          <div className="col-3 p-0 amt_1">
                            ${change0ToNA(v.pqpCurrentMonth)}
                          </div>
                          <div className="col-1 p-0">
                            <i class="fas fa-long-arrow-alt-right"></i>
                          </div>
                          <div className="col-3 p-0 amt_2">
                            ${change0ToNA(v.pqpNextMonth)}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end pt-2 amt_3">
                          ${change0ToNA(v.pqpChange)} &nbsp;&nbsp;{" "}
                          {change0ToNA(v.pqpChangePercetage)}
                        </div>
                      </td>
                    );
                  })}
              </tr>
            </tbody>
          </table>
        </div>
        <section className="body-hero">
          <div className="row p-0 m-0">
            <div className="col-1 p-0"></div>
            <h1 className="col-10 p-0 body-hero-header text-center h1 font-weight-bolder heading-home">
              Making Vehicle Ownership Accessible For Everyone
            </h1>
            <div className="col-1 p-0"></div>
          </div>
          <div className="body-hero-icons">
            <div
              className="hero-icon-container cursor_pointer"
              onClick={() => props.history.push("/sell")}
            >
              <div className="body-hero-icon">
                <img className="sell-icon" src={sell} alt="" />
              </div>
              <h6>Sell</h6>
            </div>
            <div
              className="hero-icon-container cursor_pointer"
              onClick={() => props.history.push("/sell")}
            >
              <div className="body-hero-icon">
                <img className="scrap-icon" src={scrapCar} alt="" />
              </div>
              <h6>Scrap / Export</h6>
            </div>
            <div
              className="hero-icon-container cursor_pointer"
              onClick={() => props.history.push("/financing")}
            >
              <div className="body-hero-icon">
                <img className="coe-icon" src={renewal} alt="" />
              </div>
              <h6>COE Renewal</h6>
            </div>
            {/* <div
              className="hero-icon-container cursor_pointer"
              onClick={() => props.history.push("#")}
            >
              <div className="body-hero-icon">
                <img className="rental-icon" src={rental} alt="" />
              </div>
              <h6>Rental</h6>
            </div> */}
          </div>
        </section>

        <section className="section-get-loan">
          <div className="financing-page-popup financing-page-popup-home p-1">
            <div class="row m-0 mt-2">
              <div class="col pl-1 pr-1 d-flex align-items-center justify-content-between">
                <img src={DummyImg} />
                <p class="mb-0 mt-0 textA">Hey! Welcome to Carki.</p>
              </div>
            </div>

            <div class="row m-0 mt-3">
              <div class="col pl-1 pr-1">
                <p class="mb-0 mt-0 textB">
                  We’re a team of humans, not bots. chat with us! How may we
                  help you today?{" "}
                </p>
              </div>
            </div>

            <div class="row m-0 mt-4">
              <div class="col pl-1 pr-1">
                <p class="mb-0 mt-0 textC">
                  — Wilson Wen <br /> Director Of Business Development <br />{" "}
                  Carki Pte Ltd
                </p>
              </div>
            </div>
          </div>

          <div className="row m-0">
            <div className="col-lg-2 col-md-2 col-1"></div>
            <div className="col-lg-8 col-md-8 col-10 col-0 text-center p-0">
              <h5 className="heading">
                Get The Best Price For Your Car Free
                <br />
                Within 4 Hours
              </h5>
              <p className="sub-heading text-center mt-5">
                Save up to 50% in auto financing costs with Singapore’s most
                established auto financiers. {/* <br /> */}
                You can get auto loans from <a href="#">as low as 1.88%</a>{" "}
                through our exclusive partners!
              </p>

              <div className="row m-0 mt-5 form_area">
                <div className="col-lg-6 col-md-6 col-12 p-0">
                  <div className="col-lg-9 col-md-9 col-12 p-0">
                    <InputGroup className="mb-4" size="lg">
                      <FormControl
                        aria-label="Default"
                        className={`${showValidation && !vehiclePlateNo ? "validation_err" : ""}`}
                        aria-describedby="inputGroup-sizing-default"
                        placeholder="Vehicle Plate Number"
                        value={vehiclePlateNo}
                        onChange={(e) => setVehiclePlate(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4" size="lg">
                      <FormControl
                        aria-label="Default"
                        className={`${showValidation && !name ? "validation_err" : ""}`}
                        aria-describedby="inputGroup-sizing-default"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4" size="lg">
                      <FormControl
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        className={`${showValidation && !mobileNo ? "validation_err" : ""}`}
                        placeholder="Mobile Number"
                        value={mobileNo}
                        onChange={(e) => setmobileNo(e.target.value)}
                      />
                    </InputGroup>
                    <Button
                      variant="success"
                      className="get_free_quote_btn_homepage"
                      size="lg"
                      onClick={() => SubmitData()}
                      block
                    >
                      Get A FREE Quote Today!&emsp;
                      <i className="ri-arrow-right-line"></i>
                    </Button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 car_image">
                  <img
                    className="loan-img"
                    src={carLoan}
                    width="100"
                    height="100"
                    alt="Car image"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-1"></div>
          </div>
        </section>

        <section className="value-container">
          <div className="row m-0">
            <div className="col-12 p-0 value-calculator value-rounded container-lg">
              <img
                class="calcBackground d-none d-sm-none d-md-none d-lg-block d-xl-block mb-0 pb-0"
                width="100%"
                src={CalcBackground}
              // style={{height : selectedCarTaBvalue === "PARF" ? '116%' : "90%"}}
              />
              <img
                class="calcBackground d-block d-sm-block d-md-none d-lg-none d-xl-none mb-0 pb-0"
                width="100%"
                src={CalcMobileBackground}
              />

              <div className="innerCalc">
                <h5 className="value-header text-center h2 font-weight-bolder mb-2">
                  Calculate Your Used Car Loan For Free Now
                </h5>
                <h6 className="value-subheader font-weight-bolder mb-2 text-center">
                  Estimate the amount of money you have to pay in instalments
                </h6>
                <h6 className="value-subheader-2 font-weight-bolder mb-3 text-center">
                  Carki offers as low as <em>{selectedCarTaBvalue === "PARF" ? "1.88%" : "2.88%"} interest</em> for your
                  vehicle loans!
                </h6>

                <div class="row m-0 mt-4">
                  <div class="col-12 p-0 pl-sm-2 pr-sm-2 homePageCalSection d-flex align-items-center justify-content-center">
                    <button
                      onClick={() => {
                        setCarTaBvalue("PARF");
                        setIntRate(1.88)
                      }}
                      className={`calSection2 mr-3 ${selectedCarTaBvalue === "PARF" && "calSection1"
                        }`}
                    >
                      PARF CAR
                    </button>

                    <button
                      onClick={() => {
                        setCarTaBvalue("COE");
                        setIntRate(2.88)
                      }}
                      className={`calSection2 ${selectedCarTaBvalue === "COE" && "calSection1"
                        }`}
                    >
                      COE CAR
                    </button>
                  </div>
                </div>

                {showPaybleAmtBox ? (
                  <div>
                    <div className="row m-0 calculated_loan_box d-lg-none d-md-none d-flex">
                      <div className="col-12 row m-0 align-items-center p-0">
                        <div className="col-8 pl-4 estimate_payable text-center">
                          Total Interest Accrued:
                        </div>
                        <div className="col-4 estimate_payable_amt text-center">
                          ${totalInt}
                        </div>
                      </div>
                      <div className="col-12 row m-0 align-items-center p-0">
                        <div className="col-8 pl-4 estimate_payable text-center">
                          Your estimated payable quantum per month is:
                        </div>
                        <div className="col-4 estimate_payable_amt text-center">
                          ${payable_amt}
                        </div>
                      </div>
                      <div className="col-12 row m-0 align-items-center p-0">
                        <div className="col-7 pl-4 estimate_payable text-center">
                          For a loan period of:
                        </div>
                        <div className="col-5 pr-0 estimate_payable_amt text-center">
                          {years} years
                          <div>{months} months</div>
                          <div>
                            {Number(years) * 12 + Number(months)} months
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {showPaybleAmtBox ? (
                  <div className="calculated_loan_box d-lg-block d-md-block d-none">
                    <div className="row m-0 mt-4">
                      <div className="col-1 p-0"></div>
                      <div className="col-5 row m-0 align-items-center p-0">
                        <div
                          className="col-7 pl-4 estimate_payable"
                          style={{ textAlign: "end" }}
                        >
                          Total Interest Accrued:
                        </div>
                        <div className="col-4 estimate_payable_amt">
                          ${totalInt}
                        </div>
                      </div>
                      <div className="col-5 row m-0 align-items-center p-0">
                        <div className="col-5 estimate_payable">
                          For a loan period of:
                        </div>
                        <div className="col-6 text-center estimate_payable_amt mt-2">
                          <div>{years} years {months} months</div>
                          <div
                            className="mt-3 "
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ({Number(years) * 12 + Number(months)} months)
                          </div>
                        </div>
                      </div>
                      <div className="col-0 p-0"></div>
                    </div>
                    <div className="row m-0 mb-5 mt-4">
                      <div className="col-2 p-0"></div>
                      <div className="col-4 row m-0 align-items-center p-0">
                        <div className="col-6 pl-4 estimate_payable">
                          Your estimated payable quantum per month is:
                        </div>
                        <div className="p-0 col-4 estimate_payable_amt">
                          ${payable_amt}
                        </div>
                      </div>
                      <div className="col-5 row m-0 align-items-center p-0">
                        <div className="col-7 pl-5 estimate_payable"></div>
                        <div className="col-4 estimate_payable_amt"></div>
                      </div>
                      <div className="col-1 p-0"></div>
                    </div>
                  </div>
                ) : null}

                {selectedCarTaBvalue === "PARF" && (
                  <div className="parf_homepage">
                    <div className="calc-cont">
                      <div className="calc-row-one calc-common">
                        <div className="cal-name">
                          <p>Car Price</p>
                        </div>
                        <div className="calc-input">
                          <div className="input-group cal-size-a">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                $
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={carPrice}
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => setCarPrice(e.target.value)}
                              placeholder="0"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="calc-cont mt-lg-4">
                      <div className="calc-row-one calc-common">
                        <div className="cal-name">
                          <p>Down Payment</p>
                        </div>
                        <div className="calc-input mr-lg-4 mr-md-4 mr-0">
                          <div className="input-group cal-size-a">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                $
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="0"
                              value={downPayment}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="calc-input-buttons calc-input-buttons-percentage">
                          <button
                            className={`percent-30 ${ofCarPrice === 0 && "active"
                              }`}
                            onClick={() => setOfCarPrice(0)}
                          >
                            0%
                          </button>
                          <button
                            className={` ${ofCarPrice === 10 && "active"}`}
                            onClick={() => setOfCarPrice(10)}
                          >
                            10%
                          </button>
                          <button
                            className={`percent-50  ${ofCarPrice === 20 && "active"
                              }`}
                            onClick={() => setOfCarPrice(20)}
                          >
                            20%
                          </button>

                          <p className="blue-text d-lg-none d-md-none d-block mb-0">
                            Of Car Price
                          </p>
                        </div>
                        <p className="blue-text d-lg-block d-md-block d-none">
                          Of Car Price
                        </p>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="calc-cont mt-lg-4">
                      <div className="calc-row-one calc-common">
                        <div className="cal-name">
                          <p>COE Expiry Date</p>
                        </div>
                        <div className="calc-input">
                          <div className="input-group cal-size-a">
                            <input
                              className="form-control"
                              // value={carPrice}
                              min={`${currentDate.getFullYear() - 7}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`}
                              type="date"
                              format="yyyy-MM-dd"
                              onChange={(e) => addCOEexpiryDate(e)}
                              placeholder="DD/MM/YYYY"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="calc-cont mt-lg-4 calc-cont-loan">
                      <div className="calc-row-one calc-common">
                        <div className="cal-name">
                          <p>Loan Tenure (Years)</p>
                          {/* <p>(COE Car Offset 3 months)</p> */}
                        </div>
                        <div className="calc-input">
                          <div
                            className="input-group cal-size-a"
                          // onClick={() => setshowDropdown(showDropdown ? "" : "1")}
                          >
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="7"
                                  value={years}
                                  readOnly
                                  style={{ width: "50px" }}
                                  aria-label="Text input with segmented dropdown button"
                                />
                                <p className="font-weight-bold mb-0 pl-3">
                                  Years
                                </p>
                              </div>
                              <div className="d-flex align-items-center ml-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="7"
                                  value={months}
                                  readOnly
                                  style={{ width: "50px" }}
                                  aria-label="Text input with segmented dropdown button"
                                />
                                <p className="font-weight-bold mb-0 pl-3">
                                  Months
                                </p>
                              </div>
                            </div>
                            {/* <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                        <a className="dropdown-item" href="#"></a>
                      </div>
                    </div> */}
                          </div>
                          {showDropdown === "1" && (
                            <Dropdown
                              setValue={(v) => {
                                setYears(v);
                                setshowDropdown("");
                              }}
                              elements={["1", "2", "3", "4", "5", "6", "7"]}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="calc-cont mt-lg-4">
                      <div className="calc-row-one calc-common">
                        <div className="cal-name">
                          <p>Interest Rate (% p.a.)</p>
                        </div>
                        <div className="calc-input">
                          <div className="input-group cal-size-a">
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className="form-control"
                              placeholder="1.88"
                              value={intRate}
                              onChange={(e) => setIntRate(e.target.value)}
                              aria-label="Text input with segmented dropdown button"
                            />
                            {/* <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="sr-only">Toggle Dropdown</span>
                              </button>
                              <div className="dropdown-menu">
                                <a className="dropdown-item" href="#"></a>
                                <a className="dropdown-item" href="#"></a>
                                <a className="dropdown-item" href="#"></a>
                                <a className="dropdown-item" href="#"></a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="calc-cont mt-lg-4">
                      <div className="calc-row-one calc-common">
                        <div className="cal-name">
                          <p>Loan Start Date</p>
                        </div>
                        <div className="d-flex calc-row-loan-data">
                          <div className="calc-input loan-start-date-month">
                            <div
                              className="input-group cal-size-b"
                              onClick={() =>
                                setshowDropdown(showDropdown ? "" : "2")
                              }
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="May"
                                aria-label="Text input with segmented dropdown button"
                                value={loanStartDate}
                                readOnly
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span className="sr-only">
                                    Toggle Dropdown
                                  </span>
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#"></a>
                                  <a className="dropdown-item" href="#"></a>
                                  <a className="dropdown-item" href="#"></a>
                                  <a className="dropdown-item" href="#"></a>
                                </div>
                              </div>
                            </div>
                            {showDropdown === "2" && (
                              <Dropdown
                                setValue={(v) => {
                                  setLoanStartDate(v);
                                  setshowDropdown("");
                                }}
                                elements={[
                                  "Jan",
                                  "Feb",
                                  "Mar",
                                  "Apr",
                                  "May",
                                  "Jun",
                                  "July",
                                  "Aug",
                                  "Sep",
                                  "Oct",
                                  "Nov",
                                  "Dec",
                                ]}
                              />
                            )}
                          </div>
                          <div className="calc-input loan-start-date-year">
                            <div
                              className="input-group cal-size-c"
                              onClick={() =>
                                setshowDropdown(showDropdown ? "" : "3")
                              }
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="2021"
                                aria-label="Text input with segmented dropdown button"
                                value={loanStartYear}
                                readOnly
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span className="sr-only">
                                    Toggle Dropdown
                                  </span>
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#"></a>
                                  <a className="dropdown-item" href="#"></a>
                                  <a className="dropdown-item" href="#"></a>
                                  <a className="dropdown-item" href="#"></a>
                                </div>
                              </div>
                            </div>
                            {showDropdown === "3" && (
                              <Dropdown
                                setValue={(v) => {
                                  setLoanStartYear(v);
                                  setshowDropdown("");
                                }}
                                elements={["2020", "2021", "2022"]}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedCarTaBvalue === "COE" && (
                  <>
                    <div className="d-lg-block d-none mt-5">
                      <div className="row m-0">
                        <div className="col-3"></div>
                        <div className="col-6">
                          <div className="row m-0">
                            <div className="col-6">
                              <p className="input-labels">Loan Amount</p>
                              <InputGroup className="mb-0" size="lg">
                                <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon1">
                                    $
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="0"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={loanAmt}
                                  onChange={(e) =>
                                    setLoanAmount(e.target.value)
                                  }
                                />
                              </InputGroup>
                            </div>
                            <div className="col-6">
                              <p className="input-labels">
                                Interest Rate (% p.a.)
                              </p>
                              <InputGroup className="mb-0" size="lg">
                                <FormControl
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="2.88"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={intRate}
                                  onChange={(e) => setIntRate(e.target.value)}
                                />
                                {/* <InputGroup.Append>
                                  <InputGroup.Text id="basic-addon1">
                                    <i
                                      style={{ color: "white" }}
                                      className="fas fa-angle-down"
                                    ></i>
                                  </InputGroup.Text>
                                </InputGroup.Append> */}
                              </InputGroup>
                            </div>
                          </div>

                          <div className="row m-0 my-4">
                            <div className="col-6">
                              <p className="input-labels">COE Expiry Date</p>
                              <InputGroup className="mb-0" size="lg">
                                <FormControl
                                  // type="text"
                                  className="form-control"
                                  // value={carPrice}
                                  type="date"
                                  min={`${currentDate.getFullYear() - 7}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`}
                                  format="yyyy-MM-dd"
                                  onChange={(e) => addCOEexpiryDate(e, "coeCar")}
                                  placeholder="DD/MM/YYYY"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                            </div>
                            <div className="col-6 d-flex flex-column justify-content-center">
                              <p className="input-labels">
                                Loan Tenure (COE Car Offset 3 months)
                              </p>
                              <InputGroup
                                className="mb-0 pointer"
                                size="lg"
                              // onClick={() => setshowDropdownForYears(!showDropdownForYears)}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="7"
                                      value={years}
                                      readOnly
                                      style={{ width: "50px" }}
                                      aria-label="Text input with segmented dropdown button"
                                    />
                                    <p className="font-weight-bold mb-0 pl-3">
                                      Years
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center ml-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="7"
                                      value={months}
                                      readOnly
                                      style={{ width: "50px" }}
                                      aria-label="Text input with segmented dropdown button"
                                    />
                                    <p className="font-weight-bold mb-0 pl-3">
                                      Months
                                    </p>
                                  </div>
                                </div>
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>

                    <div className="d-lg-none d-block">
                      <div className="row m-0 d-flex align-items-center mt-5 input_field_loan_amt">
                        <div className="col-1"></div>
                        <div className="pr-4 input-labels">
                          <p className="mb-0">Loan Amount</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-9 p-0">
                          <InputGroup className="mb-0" size="lg">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                $
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="0"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={loanAmt}
                              onChange={(e) => setLoanAmount(e.target.value)}
                            />
                          </InputGroup>
                          {loanAmt
                            ? loanAmt < 10000 && (
                              <p className="error_logs">
                                Loan amount needs to be minimum $10,000
                              </p>
                            )
                            : null}
                        </div>
                      </div>
                      <div className="row m-0 d-flex align-items-center mt-4 input_field_loan_amt">
                        <div className="col-1"></div>
                        <div className="pr-5 input-labels">
                          <p>
                            COE Expiry <br /> Date
                          </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-9 p-0">
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              type="text"
                              className="form-control"
                              // value={carPrice}
                              type="date"
                              format="yyyy-MM-dd"
                              onChange={(e) => addCOEexpiryDate(e, "coeCar")}
                              placeholder="DD/MM/YYYY"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row m-0 d-flex align-items-center mt-4 input_field_loan_amt">
                        <div className="col-1"></div>
                        <div className="pr-4 input-labels">
                          <p className="mb-0 pr-2">
                            Loan Tenure <br /> (COE Car Offset 3 months)
                          </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-9 p-0">
                          <InputGroup
                            className="mb-0 pointer"
                            size="lg"
                          // onClick={() => setshowDropdownForYears(!showDropdownForYears)}
                          >
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="7"
                                  value={years}
                                  readOnly
                                  style={{ width: "50px" }}
                                  aria-label="Text input with segmented dropdown button"
                                />
                                <p className="font-weight-bold mb-0 pl-3">
                                  Years
                                </p>
                              </div>
                              <div className="d-flex align-items-center ml-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="7"
                                  value={months}
                                  readOnly
                                  style={{ width: "50px" }}
                                  aria-label="Text input with segmented dropdown button"
                                />
                                <p className="font-weight-bold mb-0 pl-3">
                                  Months
                                </p>
                              </div>
                            </div>
                          </InputGroup>
                          {showDropdownForYears && (
                            <Dropdown
                              setValue={(v) => {
                                setYears(v);
                                setshowDropdownForYears(false);
                              }}
                              elements={["1", "2", "3", "4", "5", "6", "7"]}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row m-0 d-flex align-items-center mt-4 mb-4 input_field_loan_amt">
                        <div className="col-1"></div>
                        <div className="pr-4 input-labels">
                          <p className="mb-0 pr-1">
                            Interest Rate{" "}
                            <br className="d-lg-block d-lg-block d-none" />
                            (% p.a.)
                          </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-9 p-0">
                          <InputGroup className="mb-0" size="lg">
                            <FormControl
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="2.88"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={intRate}
                              onChange={(e) => setIntRate(e.target.value)}
                            />
                            {/* <InputGroup.Append>
                              <InputGroup.Text id="basic-addon1">
                                <i
                                  style={{ color: "white" }}
                                  className="fas fa-angle-down"
                                ></i>
                              </InputGroup.Text>
                            </InputGroup.Append> */}
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* <hr style={{ backgroundColor: "#F5861F" }} /> */}

                <div className="row m-0 p-3 p-md-0 p-lg-0 d-flex align-items-center mt-4 mb-lg-4">
                  <div className="col-1"></div>
                  <div className="col-lg-10 col-md-10 col-12 p-0 additional-info p-3">
                    <div className="row m-0">
                      <div className="col-12 p-0 titles additional-info-title">
                        Additional Information for Carki Loans
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12 d-lg-block d-md-none d-none p-0 titles">
                        Maximum loan value of 90% of car price.
                      </div>
                      <div className="col-12 d-lg-none d-md-none d-block p-0 titles">
                        Cars with Applicable OMV exceeding $20,000: <br />
                        <p>
                          maximum loan value of{" "}
                          <span className="span_titles">
                            {" "}
                            60% of car price.
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12 d-lg-block d-md-none d-none p-0 titles">
                        Minimum downpayment value of 10% of car price.
                      </div>
                      <div className="col-12 d-lg-none d-md-none d-block p-0 titles">
                        Cars with Applicable OMV below $20,000: <br />
                        <p>
                          maximum loan value of
                          <span className="span_titles">
                            {" "}
                            70% of car price.
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12 p-0 titles">
                        Minimum loan amount:{" "}
                        <span className="span_titles">$10,000</span>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12 p-0 m-0 titles">
                        Maximum loan tenure:{" "}
                        <span className="span_titles">7 years</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row m-0 d-flex align-items-center mb-4">
                  <div className="col-1"></div>
                  <div className="col-lg-10 col-md-10 col-12 p-0">
                    <div className="row m-0 d-flex justify-content-center align-items-center w-100 calc_btns_div my-lg-4">
                      <div>
                        <Button
                          variant="success"
                          className="calulate-car-loan-btn calulate-car-loan-btn-1"
                          size="lg"
                          block
                          onClick={() => {
                            selectedCarTaBvalue === "PARF" ? calculateCarLoanParf() : calculateCarLoan()
                          }}
                        >
                          Calculate Car Loan
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="outline-secondary"
                          className="reset-btn reset-btn-1 ml-5"
                          size="lg"
                          block
                          onClick={() => resetFields()}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="calculation-container">
          {/* <div className="value-calculator shadow-lg value-rounded container-lg py-4">
            <h5 className="value-header text-center h2 font-weight-bolder mb-2">
              Calculate Your Used Car Loan For Free Now
            </h5>
            <h6 className="value-subheader font-weight-bolder mb-2 text-center">
              Estimate the amount of money you have to pay in instalments
            </h6>
            <h6 className="value-subheader-2 font-weight-bolder mb-3 text-center">
              Carki offers as low as <em>1.88% interest</em> for your vehicle
              loans!
            </h6>

            <div class="row m-0 mt-4">
              <div class="col-12 p-0 homePageCalSection d-flex align-items-center justify-content-center">
                  <button class="calSection1 mr-3">PARF CAR</button>
                  <button class="calSection2 ml-3">COE CAR</button>
              </div>
            </div>

            {showPaybleAmtBox ? (
              <div>
                <div className="row m-0 calculated_loan_box d-lg-none d-md-none d-flex">
                  <div className="col-12 row m-0 align-items-center p-0">
                    <div className="col-8 pl-4 estimate_payable text-center">
                      Total Interest Accrued:
                    </div>
                    <div className="col-4 estimate_payable_amt text-center">
                      ${totalInt}
                    </div>
                  </div>
                  <div className="col-12 row m-0 align-items-center p-0">
                    <div className="col-8 pl-4 estimate_payable text-center">
                      Your estimated payable quantum per month is:
                    </div>
                    <div className="col-4 estimate_payable_amt text-center">
                      ${payable_amt}
                    </div>
                  </div>
                  <div className="col-12 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable text-center">
                      For a loan period of:
                    </div>
                    <div className="col-5 pr-0 estimate_payable_amt text-center">
                      {years} years
                      <div>{months} months</div>
                      <div>{(Number(years) * 12) + Number(months)} months</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {showPaybleAmtBox ? (
              <div className="calculated_loan_box d-lg-block d-md-block d-none">
                <div className="row m-0 mt-4">
                  <div className="col-1 p-0"></div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable" style={{ textAlign: "end" }}>
                      Total Interest Accrued:
                    </div>
                    <div className="col-4 estimate_payable_amt">
                      ${totalInt}
                    </div>
                  </div>
                  <div className="col-6 row m-0 align-items-center p-0">
                    <div className="col-5 estimate_payable">
                      For a loan period of:
                    </div>
                    <div className="col-6 estimate_payable_amt mt-2">
                      {years} years {months} months <br />
                      <div className="mt-3 text-center" style={{ whiteSpace: 'nowrap' }}>{(Number(years) * 12) + Number(months)} months</div>
                    </div>
                  </div>
                  <div className="col-0 p-0"></div>
                </div>
                <div className="row m-0 mb-5 mt-4">
                  <div className="col-1 p-0"></div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-4 estimate_payable">
                      Your estimated payable quantum per month is:
                    </div>
                    <div className="col-4 estimate_payable_amt">
                      ${payable_amt}
                    </div>
                  </div>
                  <div className="col-5 row m-0 align-items-center p-0">
                    <div className="col-7 pl-5 estimate_payable">

                    </div>
                    <div className="col-4 estimate_payable_amt">

                    </div>
                  </div>
                  <div className="col-1 p-0"></div>
                </div>
              </div>
            ) : null}

            <div className="d-lg-block d-none mt-5">
              <div className="row m-0">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="row m-0">
                    <div className="col-6">
                      <p className="input-labels">Loan Amount</p>
                      <InputGroup className="mb-0" size="lg">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="0"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={loanAmt}
                          onChange={(e) => setLoanAmount(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-6">
                      <p className="input-labels">Interest Rate
                        (% p.a.)</p>
                      <InputGroup className="mb-0" size="lg">
                        <FormControl
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="2.88"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={intRate}
                          onChange={(e) => setIntRate(e.target.value)}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon1">
                            <i
                              style={{ color: "white" }}
                              className="fas fa-angle-down"
                            ></i>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row m-0 my-4">
                    <div className="col-6">
                      <p className="input-labels">COE Expiry Date</p>
                      <InputGroup className="mb-0" size="lg">
                        <FormControl
                          type="text"
                          className="form-control"
                          // value={carPrice}
                          type="date"
                          format="yyyy-MM-dd"
                          onChange={(e) => addCOEexpiryDate(e)}
                          placeholder="DD/MM/YYYY"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div className="col-6 d-flex flex-column justify-content-center">
                      <p className="input-labels">Loan Tenure (COE Car Offset 3 months)</p>
                      <InputGroup
                        className="mb-0 pointer"
                        size="lg"
                      // onClick={() => setshowDropdownForYears(!showDropdownForYears)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="7"
                              value={years}
                              readOnly
                              style={{ width: "50px" }}
                              aria-label="Text input with segmented dropdown button"
                            />
                            <p className="font-weight-bold mb-0 pl-3">Years</p>
                          </div>
                          <div className="d-flex align-items-center ml-4">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="7"
                              value={months}
                              readOnly
                              style={{ width: "50px" }}
                              aria-label="Text input with segmented dropdown button"
                            />
                            <p className="font-weight-bold mb-0 pl-3">Months</p>
                          </div>
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
            </div>

            <div className="d-lg-none d-block">
              <div className="row m-0 d-flex align-items-center mt-5 input_field_loan_amt">
                <div className="col-1"></div>
                <div className="pr-4 input-labels">
                  <p className="mb-0">Loan Amount</p>
                </div>
                <div className="col-lg-3 col-md-3 col-9 p-0">
                  <InputGroup className="mb-0" size="lg">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="0"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={loanAmt}
                      onChange={(e) => setLoanAmount(e.target.value)}
                    />
                  </InputGroup>
                  {loanAmt
                    ? loanAmt < 10000 && (
                      <p className="error_logs">
                        Loan amount needs to be minimum $10,000
                      </p>
                    )
                    : null}
                </div>
              </div>
              <div className="row m-0 d-flex align-items-center mt-4 input_field_loan_amt">
                <div className="col-1"></div>
                <div className="pr-5 input-labels">
                  <p>
                    COE Expiry <br /> Date
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-9 p-0">
                  <InputGroup className="mb-0" size="lg">
                    <FormControl
                      type="text"
                      className="form-control"
                      // value={carPrice}
                      type="date"
                      format="yyyy-MM-dd"
                      onChange={(e) => addCOEexpiryDate(e)}
                      placeholder="DD/MM/YYYY"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row m-0 d-flex align-items-center mt-4 input_field_loan_amt">
                <div className="col-1"></div>
                <div className="pr-4 input-labels">
                  <p className="mb-0 pr-2">Loan Tenure <br /> (COE Car Offset 3 months)</p>
                </div>
                <div className="col-lg-3 col-md-3 col-9 p-0">
                  <InputGroup
                    className="mb-0 pointer"
                    size="lg"
                  // onClick={() => setshowDropdownForYears(!showDropdownForYears)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="7"
                          value={years}
                          readOnly
                          style={{ width: "50px" }}
                          aria-label="Text input with segmented dropdown button"
                        />
                        <p className="font-weight-bold mb-0 pl-3">Years</p>
                      </div>
                      <div className="d-flex align-items-center ml-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="7"
                          value={months}
                          readOnly
                          style={{ width: "50px" }}
                          aria-label="Text input with segmented dropdown button"
                        />
                        <p className="font-weight-bold mb-0 pl-3">Months</p>
                      </div>
                    </div>
                  </InputGroup>
                  {showDropdownForYears && (
                    <Dropdown
                      setValue={(v) => {
                        setYears(v);
                        setshowDropdownForYears(false);
                      }}
                      elements={["1", "2", "3", "4", "5", "6", "7"]}
                    />
                  )}
                </div>
              </div>

              <div className="row m-0 d-flex align-items-center mt-4 mb-4 input_field_loan_amt">
                <div className="col-1"></div>
                <div className="pr-4 input-labels">
                  <p className="mb-0 pr-1">
                    Interest Rate <br className="d-lg-block d-lg-block d-none" />
                    (% p.a.)
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-9 p-0">
                  <InputGroup className="mb-0" size="lg">
                    <FormControl
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="2.88"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={intRate}
                      onChange={(e) => setIntRate(e.target.value)}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon1">
                        <i
                          style={{ color: "white" }}
                          className="fas fa-angle-down"
                        ></i>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </div>
            </div>

            <hr style={{ backgroundColor: "#F5861F" }} />

            <div className="row m-0 d-flex align-items-center mt-4 mb-4">
              <div className="col-1"></div>
              <div className="col-lg-10 col-md-10 col-12 p-0 additional-info p-4">
                <div className="row m-0">
                  <div className="col-12 p-0 titles additional-info-title">
                    Additional Information for Carki Loans
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 p-0 titles">
                    Maximum loan value of 90% of car price.
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 p-0 titles">
                    Minimum downpayment value of 10% of car price.
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 p-0 titles">
                    Minimum loan amount: {" "}
                    <span className="span_titles">$10,000</span>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 p-0 m-0 titles">
                    Maximum loan tenure:{" "}
                    <span className="span_titles">7 years</span>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="mt-4 row m-0 d-flex justify-content-center align-items-center w-100 calc_btns_div">
                <div>
                  <Button
                    variant="success"
                    className="calulate-car-loan-btn"
                    size="lg"
                    block
                    onClick={() => calculateCarLoan()}
                  >
                    Calculate Car Loan Now
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-secondary"
                    className="reset-btn ml-5"
                    size="lg"
                    block
                    onClick={() => resetFields()}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div> */}

          <div className={`her-icon-container value-hero-bg`}>
            <div className="value-iconized d-flex container-lg">
              <div className="col-sm-4">
                <header className="iconized text-center font-weight-bolder mb-3">
                  No Admin Fees. <br /> No Processing Fees.
                </header>
                <div className="iconized-icon">
                  <img className="iconized-icona" src={fast} alt="" />
                </div>
                <div className="iconized-footer">
                  We offer transparency to both end users and partners!
                </div>
              </div>

              <div className="col-sm-4">
                <header className="iconized mb-3 text-center font-weight-bolder">
                  Lowest Interst Rates In The Market
                </header>
                <div className="iconized-icon">
                  <img className="iconized-icona" src={intrest2} alt="" />
                </div>
                <div className="iconized-footer">
                  Our finance interst is from 1.88%!
                </div>
              </div>

              <div className="col-sm-4">
                <header className="iconized mb-3 text-center font-weight-bolder">
                  Buy With Assurance And Confidence
                </header>
                <div className="iconized-icon">
                  <img className="iconized-icona" src={quality} alt="" />
                </div>
                <div className="iconized-footer">
                  Our in-house workshop provides free evaluation and repair
                  quotations!
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="products my-5 px-5">
          <h2 className="products-header">Latest Used Cars</h2>
          <div className="px-5 slider_box">
            <Slider {...settings}>
              {[...Array(n)].map((v, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-11 col-md-11 col-12 carousel-cards border-0"
                  >
                    <img className=" card-img-top" src={car} alt="Cars image" />
                    <div className="card-body">
                      <h5 className="card-title">Audi R8 3.0 Coupe</h5>
                      <div className="card-links d-flex w-100 justify-content-between flex-nowrap">
                        <p className="card-higlight text-uppercase text-white font-weight-bold bg-default-a card-deep-round px-2 py-1 card-blue mr-2">
                          2 Seater
                        </p>
                        <p className="card-higlight text-uppercase text-white font-weight-bold bg-default-b card-deep-round px-4 py-1 card-blue mr-2">
                          Cat B
                        </p>
                        <p className="card-higlight text-uppercase text-white font-weight-bold bg-default-c card-deep-round px-4 py-1 card-blue">
                          Parf B
                        </p>
                      </div>
                      <div className="card-amounts d-flex justify-content-between">
                        <div className="time">
                          <i className="fas fa-calendar mr-2 text-carki-dand"></i>{" "}
                          <span>29/02/2021</span>
                        </div>
                        <div className="price text-success price-big-font">
                          $298,200
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="time">
                          <i className="fas fa-user mr-2 text-carki-dand"></i>{" "}
                          <span>2</span>
                        </div>
                        <div className="price ">
                          <i className="fa fa-dashboard mr-2 text-carki-dand"></i>{" "}
                          <span>$29,125</span>{" "}
                        </div>
                        <div className="price text-dark">$20,200/yr</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="view-more d-flex justify-content-center">
            <a
              href="#"
              className="btn btn-more font-weight-bolder text-carki-dand"
            >
              <span>View More</span>{" "}
              <i className="fas ml-2 fa-chevron-right text-carki-dand"></i>
            </a>
          </div>
        </section>

        {/* <section className="reviews-background px-lg-5 px-0">
          <div className="row m-0 align-items-center review-headline">
            <div className="col-lg-10 col-md-10 col-12 p-0">
              <h2 className="review-header px-lg-0 px-4">
                Here’s What Our Customers Have To Say About Our Service...
              </h2>
            </div>
            <div className="col-2 text-right p-0">
              <p className="mb-0 see-all-review">See All Reviews</p>
            </div>
          </div>
          <div className="px-3">
            <Slider {...settings}>
              {[...Array(n)].map((v, i) => {
                return (
                  <div
                    key={i}
                    className="col-12 carousel-cards review-card border-0"
                  >
                    <div className="card-body">
                      <div className="row m-0 align-items-center">
                        <div className="col-2 pl-3 review-image"></div>
                        <div className="col-9 pr-0 review-name">
                          <h5 className="mb-1 text-left">King Jack</h5>
                          <p className="mb-0">Kingsmen Tailor</p>
                        </div>
                        <div className="col-1 pl-0 review-icon">
                          <i className="fab fa-facebook-square"></i>
                        </div>
                      </div>
                      <div className="row m-0 mt-3">
                        <div className="col-12 pl-2">
                          <p>
                            From start till the end, Joseph has been nothing but
                            patient, professional, accommodating, honest and
                            transparent. Kudoz to this young team Including Mark
                            and Wan...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="row m-0 align-items-center see_all_review_mobile">
            <div className="col-12 p-0">
              <h2 className="review-header">See All Reviews</h2>
            </div>
          </div>
        </section> */}
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Home;
