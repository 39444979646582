import CallApi from "./apiCallingCommon";

// const BASE_URL = "http://18.139.140.22:3000/";
const BASE_URL = "https://carki.com.sg/";

// Partner API'S

export const LoginApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/partner/login",
    method: "POST",
    data: data,
  }),
});

export const LoginOutApi = () => ({
  payload: CallApi({
    url: BASE_URL + "api/partner/logout",
    method: "GET",
  }),
});

export const PartnerRegisterApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/partner",
    method: "POST",
    data: data,
  }),
});

export const SendResetPwdLinkPartner = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/partner/password/forgot",
    method: "POST",
    data: data,
  }),
});

export const ResetForgottenPwdPartner = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/partner/password/reset",
    method: "PUT",
    data: data,
  }),
});

export const submiReqFormData = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/request-form",
    method: "POST",
    data: data,
  }),
});

export const CreateNewListingApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/listing",
    method: "POST",
    data: data,
  }),
});

export const UpdateListingApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/listing",
    method: "PUT",
    data: data,
  }),
});

export const GetAllListingApiBuy = (limit = 100, skip = 0, status) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/buy/${limit}/${skip}${status ? `?status=${status}` : ''}`,
    method: "GET",
  }),
});

export const GetListingByIdApiBuy = (id, limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/buy/${limit}/${skip}?listingId=${id}`,
    method: "GET",
  }),
});

export const GetAllListingApiRequired = (limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/acquired/${limit}/${skip}`,
    method: "GET",
  }),
});

export const GetListingByIdApiSold = (limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/sold/${limit}/${skip}`,
    method: "GET",
  }),
});

export const GetPartnerProfileAnalysisApi = (limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/partner/analysis`,
    method: "GET",
  }),
});

export const GetAllListingApiSell = (limit = 100, skip = 0, status) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/sell/${limit}/${skip}${status ? `?status=${status}` : ''}`,
    method: "GET",
  }),
});

export const GetListingByIdApiSell = (id, limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/sell/${limit}/${skip}?listingId=${id}`,
    method: "GET",
  }),
});

export const GetListingByIdWithBidHistory = (id) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/${id}`,
    method: "GET",
  }),
});

export const GetBidDetailsByID = (id) => ({
  payload: CallApi({
    url: BASE_URL + `api/bid?listingId=${id}`,
    method: "GET",
  }),
});

export const GetPartnerProfile = () => ({
  payload: CallApi({
    url: BASE_URL + `api/partner`,
    method: "GET",
  }),
});

export const UpdatePartnerProfile = (data) => ({
  payload: CallApi({
    url: BASE_URL + `api/partner`,
    method: "PUT",
    data: data,
  }),
});

export const UpdatePartnerProfilePhotoOnly = (data) => ({
  payload: CallApi({
    url: BASE_URL + `api/partner/profile-photo`,
    method: "PUT",
    data: data,
  }),
});

export const AddNewBidApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + `api/bid`,
    method: "POST",
    data: data,
  }),
});

export const UpdateBidApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + `api/bid`,
    method: "PUT",
    data: data,
  }),
});

export const DeleteBidApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + `api/bid?bidId=${data.bidId}`,
    method: "DELETE",
    data: data,
  }),
});

export const DeleteListingApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing?listingId=${data.listingId}`,
    method: "DELETE",
    data: data,
  }),
});

export const GetListingBid = (limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/bid/${limit}/${skip}`,
    method: "GET",
  }),
});

export const GetListingBidById = (id, limit = 100, skip = 0) => ({
  payload: CallApi({
    url: BASE_URL + `api/listing/bid/${limit}/${skip}?listingId=${id}`,
    method: "GET",
  }),
});

// Member API'S

export const MemberRegisterApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/member",
    method: "POST",
    data: data,
  }),
});

export const MemberLoginApi = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/member/login",
    method: "POST",
    data: data,
  }),
});

export const MemberLogOutApi = () => ({
  payload: CallApi({
    url: BASE_URL + "api/member/logout",
    method: "GET",
  }),
});

export const SendResetPwdLinkMember = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/member/password/forgot",
    method: "POST",
    data: data,
  }),
});

export const ResetForgottenPwdMember = (data) => ({
  payload: CallApi({
    url: BASE_URL + "api/member/password/reset",
    method: "PUT",
    data: data,
  }),
});

// Dashboard API'S

export const GetPartnerDashboardDetails = (month) => ({
  payload: CallApi({
    url: BASE_URL + `api/partner/dashboard?date=${month}`,
    method: "GET",
  }),
});

export const GetCoeResults = (limit = 100, skip = 0, startDate, bidding) => ({
  payload: CallApi({
    url: BASE_URL + `api/coe-results?startDate=${startDate}&bidding=${bidding}&limit=${limit}&skip=${skip}`,
    method: "GET",
  }),
});
