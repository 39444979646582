import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import sucessKey from "../../../images/icons/trophy.png";

const ThankYouPage = () => {
  return (
    <React.Fragment>
      <Header />

      <div className="lead_squeze_background thank_you_background row m-0 d-lg-flex d-none">
        <div className="col-2 p-0"></div>
        <div className="col-4 p-0">
          <img src={sucessKey} alt="" width="320" />
        </div>
        <div className="col-3 p-0 d-flex flex-column justify-content-center">
          <h1>Thank You.</h1>
          <p className="font-weight-bold">
            We look forward to meeting you and working with you!
          </p>
          <p className="font-weight-bold">
            For more enquiries, please contact us at <span>info@carki.sg</span>{" "}
            — don’t be shy!
          </p>
        </div>
        <div className="col-3 p-0"></div>
      </div>

      <div className="lead_squeze_background thank_you_background row m-0 d-lg-none d-flex">
        <div className="row m-0">
          <h1>Thank You.</h1>
        </div>
        <div className="row m-0 mt-3">
          <div className="col-5 p-0 d-flex align-items-center">
            <img src={sucessKey} alt="" width="140" />
          </div>
          <div className="col-7 p-0 pl-3">
            <p className="font-weight-bold">
              We look forward to meeting you and working with you!
            </p>
          </div>
        </div>

        <div className="row m-0 w-100 mt-4">
          <p className="font-weight-bold">
            For more enquiries, please contact us at <span>info@carki.sg</span>{" "}
            — don’t be shy!
          </p>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default ThankYouPage;
