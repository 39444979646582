import React, { useEffect, useState } from "react";
import PartnerPageRouting from "../../../components/common/partnerPageRouting";
import Header from "../../../components/header";
import CreateListing1 from "../../../components/PartnerCreateListingComponents/CreateListing1";
import CreateListing2 from "../../../components/PartnerCreateListingComponents/CreateListing2";
import CreateListing3 from "../../../components/PartnerCreateListingComponents/CreateListing3";
import CreateListing4 from "../../../components/PartnerCreateListingComponents/CreateListing4";
import CreateListing5 from "../../../components/PartnerCreateListingComponents/CreateListing5";
import CreateListing6 from "../../../components/PartnerCreateListingComponents/CreateListing6";
import CreateListing7 from "../../../components/PartnerCreateListingComponents/CreateListing7";
import { CreateNewListingApi, UpdateListingApi } from "../../../utils/apiUrls";
import "../../../css/partnerView.css";
import { toast } from "react-toastify";

const PartnerCreateNewListing = (props) => {
  const [listingStep, setListingStep] = useState(1);
  const [profileImage, setProfileImgeSrc] = useState("");
  const setListingStepFunc = (value) => {
    setListingStep(value)
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const [inputData, setInputData] = useState({
    isVehicleExported: false,
    displayPhoto: "www.example.com",
    coeCategory: "A Category",
  });

  useEffect(() => {
    if (props.location.state) {
      setListingStepFunc(2);
      setInputData(props.location.state);
    }
  }, []);

  const onChangeHandler = (e) => {
    let value;
    if (e.target.type === "number" || e.target.type === "pincode") {
      value = Number(e.target.value);
      setInputData({
        ...inputData,
        [e.target.name]: value,
      });
    }
    if (e.target.type === "date") {
      if (e.target.name === "releaseBidDate") {
        let currentDate = new Date();
        let inputDate = new Date(e.target.value);
        if (inputDate.getDate() === currentDate.getDate()) {
          if (currentDate.getHours() < 6) {
            let findTime = `${"06"}:${"00"}:${"00"}`;
            let dateValue = new Date(`${e.target.value} ${findTime}`);
            setInputData({
              ...inputData,
              [e.target.name]: dateValue.toISOString(),
            });
          } else {
            let changeDate = new Date(e.target.value);
            changeDate.setDate(changeDate.getDate() + 1);
            let saveChangeDate = `${changeDate.getFullYear()}-${
              changeDate.getMonth() + 1
            }-${changeDate.getDate()}`;
            let findTime = `${"06"}:${"00"}:${"00"}`;
            let dateValue = new Date(`${saveChangeDate} ${findTime}`);
            setInputData({
              ...inputData,
              [e.target.name]: dateValue.toISOString(),
            });
          }
        } else {
          let findTime = `${"06"}:${"00"}:${"00"}`;
          let dateValue = new Date(`${e.target.value} ${findTime}`);
          setInputData({
            ...inputData,
            [e.target.name]: dateValue.toISOString(),
          });
        }
      } else if (e.target.name === "closeBidDate") {
        let currentDate = new Date();
        let inputDate = new Date(e.target.value);
        if (inputDate.getDate() === currentDate.getDate()) {
          if (currentDate.getHours() < 6) {
            let findTime = `${"18"}:${"00"}:${"00"}`;
            let dateValue = new Date(`${e.target.value} ${findTime}`);
            setInputData({
              ...inputData,
              [e.target.name]: dateValue.toISOString(),
            });
          } else {
            let changeDate = new Date(e.target.value);
            changeDate.setDate(changeDate.getDate() + 1);
            let saveChangeDate = `${changeDate.getFullYear()}-${
              changeDate.getMonth() + 1
            }-${changeDate.getDate()}`;
            let findTime = `${"18"}:${"00"}:${"00"}`;
            let dateValue = new Date(`${saveChangeDate} ${findTime}`);
            setInputData({
              ...inputData,
              [e.target.name]: dateValue.toISOString(),
            });
          }
        } else {
          let findTime = `${"18"}:${"00"}:${"00"}`;
          let dateValue = new Date(`${e.target.value} ${findTime}`);
          setInputData({
            ...inputData,
            [e.target.name]: dateValue.toISOString(),
          });
        }
      } else {
        let dateValue = new Date(e.target.value);
        value = dateValue.toISOString();
        setInputData({
          ...inputData,
          [e.target.name]: value,
        });
      }
    } else {
      value = e.target.value;
      setInputData({
        ...inputData,
        [e.target.name]: value,
      });
    }
  };

  const onChangeHandler2 = (propName, value) => {
    setInputData({
      ...inputData,
      [propName]: value,
    });
  };

  const submitApplication = () => {
    if (props.location.state) {
      inputData.listingId = props.location.state.id;
      delete inputData.finalSoldPrice;
      delete inputData.soldDate;
      delete inputData.startingBid;
      delete inputData.soldToPartnerId;
      let formData = new FormData();
      for (var key in inputData) {
        formData.append(key, inputData[key]);
      }
      UpdateListingApi(formData)
        .payload.then(() => {
          setListingStepFunc(7);
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.data.message);
        });
    } else {
      let formData = new FormData();
      for (var key in inputData) {
        formData.append(key, inputData[key]);
      }
      CreateNewListingApi(formData)
        .payload.then(() => {
          setListingStepFunc(7);
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.data.message);
        });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="background_white partner_dashborad_page_background">
        <div className="row m-0">
          <div className="col-3 d-lg-block d-md-block d-none pl-5">
            <PartnerPageRouting activePage="createListing" />
          </div>

          <div className="col-lg-9 col-md-9 col-12 pr-lg-5 pr-md-5 pr-0 pl-lg-2 pl-md-2 pl-0">
            {listingStep === 1 && (
              <CreateListing1 setListing={(value) => setListingStepFunc(value)} />
            )}
            {listingStep === 2 && (
              <CreateListing2
                setListing={(value) => setListingStepFunc(value)}
                onChangeFunc={(e) => onChangeHandler(e)}
                inputData={inputData}
              />
            )}
            {listingStep === 3 && (
              <CreateListing3
                setListing={(value) => setListingStepFunc(value)}
                onChangeFunc={(e) => onChangeHandler(e)}
                onChangeFunc2={(propName, value) =>
                  onChangeHandler2(propName, value)
                }
                inputData={inputData}
              />
            )}
            {listingStep === 4 && (
              <CreateListing4
                setListing={(value) => setListingStepFunc(value)}
                onChangeFunc={(e) => onChangeHandler(e)}
                onChangeFunc2={(propName, value) =>
                  onChangeHandler2(propName, value)
                }
                data={inputData}
              />
            )}
            {listingStep === 5 && (
              <CreateListing5
                setListing={(value) => setListingStepFunc(value)}
                onChangeFunc={(e) => onChangeHandler(e)}
                inputData={inputData}
                setProfileImgeSrc={(val) => setProfileImgeSrc(val)}
                profileImage={profileImage}
                setProfilePicture={(img) =>
                  setInputData({
                    ...inputData,
                    displayPhoto: img,
                  })
                }
              />
            )}
            {listingStep === 6 && (
              <CreateListing6
                setListing={(value) => setListingStepFunc(value)}
                submitApplication={() => submitApplication()}
                data={inputData}
                isEdit={props.location.state ? true : false}
              />
            )}
            {listingStep === 7 && (
              <CreateListing7 setListing={(value) => setListingStepFunc(value)} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PartnerCreateNewListing;
