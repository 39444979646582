import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import PartnerPageRouting from "../common/partnerPageRouting";
import upArrow from "../../images/icons/up_arrow.svg";
import { useParams } from "react-router-dom";
import {
  AddNewBidApi,
  GetListingByIdApiBuy,
  GetListingByIdApiSell,
  GetListingByIdWithBidHistory,
} from "../../utils/apiUrls";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FindDate,
  FindDateWithTime,
  getMonthByNameFunc,
} from "../../utils/constants";

const IndividualListingViewClosed = (props) => {
  const { id } = useParams();

  const [showBid, setShowBid] = useState(false);

  const [listingData, setListingData] = useState({});
  const [showData, setshowData] = useState(false);
  const [bidSuccessFullyRegistered, setbidSuccessFullyRegistered] =
    useState(false);

  const [bidPrice, setBidPrice] = useState(0);

  useEffect(() => {
    GetListingByIdWithBidHistory(id).payload.then((res) => {
      setListingData(res.data);
      setshowData(true);
    });
  }, []);

  const raiseBidFunction = () => {
    let obj = {
      listingId: listingData.id,
      bidPrice,
    };
    AddNewBidApi(obj)
      .payload.then(() => {
        setbidSuccessFullyRegistered(true);
      })
      .catch((e) => {
        toast.error(e.data.message);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <div className="background_white partner_dashborad_page_background">
        <div className="row m-0">
          <div className="col-3 d-lg-block d-md-block d-none pl-5">
            <PartnerPageRouting activePage="closed-listing" />
          </div>

          <div className="details_background col-lg-9 col-md-9 col-12 pr-lg-5 pr-md-5 pr-0 pl-lg-2 pl-md-2 pl-0">
            <div className="row m-0 align-items-center back_button web-view-2 px-3">
              <p
                className="col-3 p-0 mb-0 back_btn cursor_pointer"
                onClick={() => props.history.push(`/view-closed-listing`)}
              >
                <i className="fas fa-chevron-left pr-3"></i>Back
              </p>
              <p className="col-4 p-0 mb-0 bid_details_title">
                Listing Details
              </p>
            </div>

            <div className="row m-0 py-4 align-items-center mobile-view-2">
              <div className="col-12 d-flex align-items-center">
                <p className="col-4 p-0 mb-0 back_btn">
                  <i
                    onClick={() => props.history.push(`/view-closed-listing`)}
                    className="fas fa-chevron-left pt-2 pl-3"
                    style={{ color: "#F5861F" }}
                  ></i>
                </p>
                <p className="col-8 p-0 mb-0 bid_details_title text-left">
                  Listing Details
                </p>
              </div>
            </div>

            <div className="mx-3">
              {showData ? (
                <div className="row m-0 details_background_2 details_background_2_individal_open mb-4">
                  <div className="row m-0 section_1 mobile-view">
                    <div className="col-lg-12 p-0">
                      <div className="row m-0">
                        <div className="col-8 p-0">
                          <p className="mb-0 title_1">
                            {listingData.vehicleModel} {listingData.vehicleMake}
                          </p>
                        </div>
                        <div className="col-4 p-0 d-flex justify-content-end">
                          {/* <button
                            className="btn_edit_btn_1"
                            onClick={(event) => {
                              event.stopPropagation();
                              props.history.push({
                                pathname: "/partner-create-listing",
                                state: listingData,
                              });
                            }}
                          >
                            <p className="mb-0">Edit</p>
                          </button> */}
                          {/* <button className="btn_bid_for_car px-2">
                            Bid For Car
                          </button> */}
                        </div>
                      </div>

                      <div className="row m-0 pt-1">
                        <div className="col-4 p-0">
                          <div
                            className="image"
                            style={{
                              background: `url(${listingData.displayPhoto})`,
                            }}
                          ></div>
                        </div>

                        <div className="col-8 p-0">
                          <div className="row m-0 mb-4">
                            <div className="col-12 p-0 pr-lg-3 d-flex justify-content-end">
                              <button className="view_bid_history_btn_mobile" onClick={() => props.history.push(`/view-closed-bid`)}>
                                View Bid History
                              </button>
                            </div>
                          </div>

                          {/* <div className="row m-0">
                            <div className="col-6 p-0">
                              <p className="mb-0 letest-bid text-center pl-lg-0 pl-2">
                                Latest Bid:
                              </p>
                            </div>
                            <div className="col-6 p-0">
                              <p className="mb-0 letest-bid-price text-left ml-3">
                                $ {listingData.expectedFinalPrice}
                              </p>
                            </div>
                          </div> */}

                          {/* <div className="row m-0">
                            <div className="col-12 pl-3">
                              <p className="mb-0 grey_title text-left">
                                10 mins ago
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 section_1 justify-content-between web-view">
                    <div className="col-lg-12 p-0">
                      <div className="row m-0">
                        <div className="col-3 p-0">
                          <div
                            className="image"
                            style={{
                              background: `url(${listingData.displayPhoto})`,
                            }}
                          ></div>
                        </div>

                        <div className="col-9 p-0 d-flex flex-column align-items-center">
                          <div className="row m-0 w-100 h-100">
                            <div className="col-lg-6 p-0 ">
                              <p className="mb-0 title_1">
                                {listingData.vehicleModel}{" "}
                                {listingData.vehicleMake}
                              </p>
                            </div>
                            <div className="col-lg-6 p-0 d-flex flex-column align-items-end justify-content-between">
                              <div>
                                <div>
                                  <p
                                    className="mb-0 title_1"
                                    style={{ color: "#56BD66" }}
                                  >
                                    Sold for: {listingData.finalSoldPrice}
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <p className="mb-0 title_1_2">
                                    Date Sold:{" "}
                                    {FindDate(listingData.closeBidDate)}
                                  </p>
                                </div>
                              </div>

                              <div className="mt-3">
                                <button className="ml-3 view_bid_history_btn" onClick={() => props.history.push(`/view-closed-bid`)}>
                                  View Bid History
                                </button>
                              </div>
                            </div>
                          </div>

                          <Modal
                            show={showBid}
                            className="raise_bid_modal"
                            onHide={() => setShowBid(false)}
                            aria-labelledby="contained-modal-title-vcenter raise_bid_modal"
                            centered
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Body>
                              <div className="row m-0 p-4">
                                <div className="col-3 pl-0 pr-5">
                                  <div className="user_profile"></div>
                                </div>
                                <div className="col-8 pl-4">
                                  <div className="car_name">
                                    Audi R8 3.0 Coupe
                                  </div>
                                  <div className="category_name">
                                    CAT B | PARF Car
                                  </div>
                                  <div className="car_details d-flex align-items-center ">
                                    <i class="fas fa-calendar-day"></i>{" "}
                                    <p className="mb-0 pl-2">
                                      Registration Date:
                                    </p>{" "}
                                    <p className="pl-2 mb-0 font-weight-bold">
                                      29/02/2011
                                    </p>
                                  </div>

                                  <div className="car_details d-flex align-items-center ">
                                    <i class="fas fa-user-alt"></i>{" "}
                                    <p className="mb-0 pl-2">Seats:</p>{" "}
                                    <p className="pl-2 mb-0 font-weight-bold">
                                      2
                                    </p>
                                  </div>

                                  <div className="car_details d-flex align-items-center ">
                                    <i class="fas fa-tachometer-alt"></i>{" "}
                                    <p className="mb-0 pl-2">Mileage (km):</p>{" "}
                                    <p className="pl-2 mb-0 font-weight-bold">
                                      29,125
                                    </p>
                                  </div>
                                </div>
                                <div className="col-1 p-0 d-flex justify-content-end cross_icon">
                                  <i
                                    class="fas fa-times cursor_pointer"
                                    onClick={() => setShowBid(false)}
                                  ></i>
                                </div>
                              </div>

                              <hr className="mt-0" />

                              {!bidSuccessFullyRegistered ? (
                                <>
                                  <div className="row m-0">
                                    <div className="col-2 p-0"></div>
                                    <div className="col-8 p-0 d-flex flex-column align-items-center">
                                      {/* <div className="current_bid_price">
                                        Current Bid: &nbsp; $ 200,000
                                      </div> */}
                                      <div className="opening_bid_price">
                                        Opening Bid: &nbsp; $ 150,000
                                      </div>
                                      <div className="new_price_div p-3 d-flex align-items-center justify-content-between">
                                        <p className="mb-0 new_price_text">
                                          New Price:
                                        </p>
                                        <div className=" d-flex align-items-center">
                                          <span>$</span>
                                          <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            placeholder="220,000"
                                            value={bidPrice}
                                            onChange={(e) =>
                                              setBidPrice(
                                                Number(e.target.value)
                                              )
                                            }
                                          />
                                          <div className="arrow_div_up_down d-flex flex-column pl-3">
                                            <i
                                              class="fas fa-chevron-up"
                                              onClick={() =>
                                                setBidPrice(bidPrice + 1)
                                              }
                                            ></i>
                                            <i
                                              class="fas fa-chevron-down"
                                              onClick={() =>
                                                setBidPrice(bidPrice - 1)
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2 p-0"></div>
                                  </div>

                                  <div className="cancel_raise_btn_div row m-0">
                                    <div className="col-2 p-0"></div>
                                    <div className="col-8 p-0 d-flex justify-content-between align-items-center">
                                      <button
                                        className="cancel_btn"
                                        onClick={() => setShowBid(false)}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="raise_bid"
                                        onClick={() => {
                                          raiseBidFunction();
                                        }}
                                      >
                                        Raise Bid
                                      </button>
                                    </div>
                                    <div className="col-2 p-0"></div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="success_bid_raise_div row m-0">
                                    <div className="col-1 p-0"></div>
                                    <div className="col-10 p-0">
                                      <p className="heading text-center mb-3">
                                        Bid Successfully Edited.
                                      </p>

                                      <div className="sucess_bid_msg_box text-center py-2">
                                        <p className="font-weight-bold">
                                          You are currently winning the bid for
                                          the
                                        </p>
                                        <p
                                          className="mb-0 font-weight-bolder"
                                          style={{ fontSize: "20px" }}
                                        >
                                          Audi R8 3.0 Coupe
                                        </p>
                                        <p className="mb-0 mt-2">at</p>
                                        <p className="font-weight-bold mt-2 mb-0 green_price_text">
                                          $ 240,000
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-1 p-0"></div>
                                  </div>
                                </>
                              )}
                            </Modal.Body>
                          </Modal>

                          {/* <div className="row m-0 pvib-line">
                            <div className="col-lg-12 p-0 pl-4">
                              <hr />
                            </div>
                          </div> */}

                          {/* <div className="row m-0">
                            <div className="col-lg-12 p-0 pl-4">
                              <div className="row m-0 letest_price">
                                <div className="col-3 p-0">
                                  <p className="letest-bid mb-0">Latest Bid:</p>
                                </div>
                                <div className="col-3 p-0">
                                  <p className="letest-bid-price mb-0">
                                    $ {listingData.expectedFinalPrice}
                                  </p>
                                </div>
                                <div className="col-6 p-0">
                                  <p className="est_time mb-0">10 mins ago</p>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex section_1_1">
            <div className="image"></div>
            <div className="pl-5 r001">
                <div className="r002">
                    <p className="mb-0 title_1">Audi R8 3.0 Coupe</p>
                    <div className="btn_edit">
                        <button className="btn_edit_btn_1">Edit</button>
                        <button className="btn_edit_btn_2">Delete</button>
                    </div>
                </div>
                <div className="r002">
                    <hr />
                </div>
                <div className="r002">
                    <div className="letest_price">
                        <p className="r003 mb-0">Latest Bid:</p>
                        <p className="r004 mb-0">$ 100,000</p>
                    </div>
                    <div className="est_time">
                        <p className="r005 mb-0">10 mins ago</p>
                    </div>
                </div>
            </div>
          </div>  */}
                  </div>

                  <div className="row m-0 section_2 flex-column mt-4 w-100">
                    <p className="mb-0 title_1 resp-heading-1">
                      Vehicle Details
                    </p>
                    <hr />

                    <div className="d-flex justify-content-between bid-page-one-mid-section">
                      <div className="d-flex bid-inner-1">
                        <div className="vehicle_details_1 col-sm-8 inner-1">
                          <p className="mb-0">Vehicle No.</p>
                          <p className="mb-0">Vehicle Make</p>
                          <p className="mb-0">Vehicle Model</p>
                          <p className="mb-0">Primary Color</p>
                        </div>
                        <div className="pl-4 vehicle_details_2 col-sm-8 inner-1">
                          <p className="mb-0">{listingData.vehicleNumber}</p>
                          <p className="mb-0">{listingData.vehicleMake}</p>
                          <p className="mb-0">{listingData.vehicleModel}</p>
                          <p className="mb-0">{listingData.primaryColor}</p>
                        </div>
                      </div>

                      <div className="d-flex mr-lg-5">
                        <div className="vehicle_details_1 col-sm-8 inner-1">
                          <p className="mb-0">Manufacturing Year</p>
                          <p className="mb-0">Engine No.</p>
                          <p className="mb-0">Chassis No.</p>
                          <p className="mb-0">Maximum Power Output</p>
                        </div>
                        <div className="pl-4 vehicle_details_2 col-sm-8 inner-1">
                          <p className="mb-0">
                            {listingData.manufacturingYear}
                          </p>
                          <p className="mb-0">{listingData.engineNumber}</p>
                          <p className="mb-0">{listingData.chassisNumber}</p>
                          <p className="mb-0">{listingData.maximumPower}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between w-100 bid-page-one-mid-section-2">
                    <div className="row m-0 section_2 flex-column mt-4 w-100">
                      <p className="mb-0 title_1 resp-heading-1">Ownership</p>
                      <hr />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="vehicle_details_1 col-sm-9 p-lg-0 px-3 inner-1">
                            <p className="mb-0">First Registration Date</p>
                            <p className="mb-0">Original Registration Date</p>
                            <p className="mb-0">Intended Deregistration Date</p>
                            <p className="mb-0">Open Market Value</p>
                            <p className="mb-0">Actual ARF Paid</p>
                            <p className="mb-0">Vehicle to be Exported?</p>
                            <p className="mb-0">Transfer Count</p>
                          </div>
                          <div className="pl-4 vehicle_details_2 col-sm-8 inner-1">
                            <p className="mb-0">
                              {getMonthByNameFunc(
                                listingData.firstRegistrationDate
                              )}
                            </p>
                            <p className="mb-0">
                              {getMonthByNameFunc(
                                listingData.originalRegistrationDate
                              )}
                            </p>
                            <p className="mb-0">
                              {getMonthByNameFunc(
                                listingData.intentedDeregistrationDate
                              )}
                            </p>
                            <p className="mb-0">
                              {listingData.openMarketValue}
                            </p>
                            <p className="mb-0">
                              {listingData.actualArfPaidAmount}
                            </p>
                            <p className="mb-0">
                              {listingData.isVehicleExported === true
                                ? "Yes"
                                : "No"}
                            </p>
                            <p className="mb-0">{listingData.transferCount}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-0 section_2 flex-column mt-4 ml-4 w-100 Rebate-section">
                      <p className="mb-0 title_1 resp-heading-1">Rebate</p>
                      <hr />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="vehicle_details_1 col-sm-9 p-lg-0 px-3 inner-1">
                            <p className="mb-0">PARF Eligibility</p>
                            <p className="mb-0">PARF Eligibility Expiry Date</p>
                            <p className="mb-0">PARF Rebate Amount</p>
                            <p className="mb-0">COE Expiry Date</p>
                            <p className="mb-0">COE Category</p>
                            <p className="mb-0">COE Period (Years)</p>
                            <p className="mb-0">PQP Paid</p>
                            <p className="mb-0">COE Rebate Amount</p>
                            <p className="mb-0">Total Rebate Amount</p>
                          </div>
                          <div className="pl-4 vehicle_details_2 col-sm-8 inner-1">
                            <p className="mb-0">
                              {listingData.parfEligibility}
                            </p>
                            <p className="mb-0">
                              {getMonthByNameFunc(
                                listingData.parfEligibilityExpiryDate
                              )}
                            </p>
                            <p className="mb-0">
                              {listingData.parfRebateAmount}
                            </p>
                            <p className="mb-0">
                              {getMonthByNameFunc(listingData.coeExpiryDate)}
                            </p>
                            <p className="mb-0">{listingData.coeCategory}</p>
                            <p className="mb-0">
                              {listingData.coePeriodInYears}
                            </p>
                            <p className="mb-0">{listingData.pqpPaidAmount}</p>
                            <p className="mb-0">
                              {listingData.coeRebateAmount}
                            </p>
                            <p className="mb-0">
                              {listingData.totalRebateAmount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="col-12 d-flex mt-5 justify-content-center"
                  style={{ height: "100vh" }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IndividualListingViewClosed;
