import React from "react";
import { Link } from "react-router-dom";
import "../../css/common_css/common.css";

const PartnerPageRouting = (props) => {
  return (
    <div className="tabs_background">
      <p
        className={`my-2 ${props.activePage === "dashboard" && "active bold"}`}
        style={{ fontSize: "18px" }}
      >
        <Link to="/partner-dashboard">Dashboard</Link>
      </p>
      <hr />
      <p className={`my-2 bold`}>
        <Link>Open Bids</Link>
      </p>
      <hr />
      <p className={`my-2 bold`}>
        <Link>My Listings (Sell Used Cars)</Link>
      </p>
      <p
        className={`my-2 ${
          props.activePage === "createListing" && "active bold"
        }`}
      >
        <Link to="/partner-create-listing">Create New Listing</Link>
      </p>
      <p
        className={`my-2 ${
          props.activePage === "open-listing" && "active bold"
        }`}
      >
        <Link to="/manage-open-listing">Manage Open Listings</Link>
      </p>
      <p
        className={`my-2 ${
          props.activePage === "closed-listing" && "active bold"
        }`}
      >
        <Link to="/view-closed-listing">View Closed Listings</Link>
      </p>
      <p className={`my-2 bold`}>
        <Link>My Bids (Buy Used Cars)</Link>
      </p>
      <p
        className={`my-2 ${props.activePage === "open-bids" && "active bold"}`}
      >
        <Link to="/manage-open-bids">Manage Open Bids</Link>
      </p>
      <p className={`my-2 ${props.activePage === "close-bids" && "active bold"}`}>
        <Link to="/view-closed-bid">View Bid History</Link>
      </p>
      <p
        className={`my-2 bold ${
          props.activePage === "my-profile" && "active bold"
        }`}
      >
        <Link to="/my-profile/view">My Profile</Link>
      </p>
    </div>
  );
};

export default PartnerPageRouting;
